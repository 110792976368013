import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import StyledMainGrid from '../../components/StyledMainGrid';

const useStyles = makeStyles((theme) => ({
  paper: {
    opacity: '0.8!important'
  }
}));

export default function InventorySearch(props) {
  const classes = useStyles();

  const handleSearchChange = (event) => {
    const new_value = event.target.value.toLowerCase();
    props.setSearchTerm(new_value);
  };

  const handleFilterChange = (event) => {
    props.setSearchFilter(event.target.value);
  };

  return (
    <StyledMainGrid item container columnSpacing>
      <Grid item xs="12" sm="6">
        <TextField
          fullWidth
          size="small"
          id="search-input"
          type="text"
          label="Search"
          placeholder="Search Serial Number, MAC Address, Product Code"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          onChange={handleSearchChange}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
      </Grid>
      <Grid item xs="12" sm="6">
        <TextField
          fullWidth
          select
          size="small"
          id="search-select"
          label="Search Filter"
          InputLabelProps={{ shrink: true }}
          value={props.searchFilter}
          onChange={handleFilterChange}
          SelectProps={{
            MenuProps: {
              classes: { paper: classes.paper }
            }
          }}
          startAdornment={
            <InputAdornment position="start">
              <FilterAltOutlinedIcon />
            </InputAdornment>
          }
        >
          {props.searchFilters.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </StyledMainGrid>
  );
}
