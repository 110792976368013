import React, {useState} from 'react';
import moment from 'moment';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import CardContent from '@mui/material/CardContent';
import ArchiveIcon from '@mui/icons-material/Archive';
import {ajaxGetAdminDistributorInvoice} from '../../services/AdminService';
import useAlert from '../../hooks/useAlert'
import StyledCard from '../../components/StyledCard'

export default function Invoice({ companyId, companyName }) {
  const endOfCurrentMonth = moment().endOf('month');
  const errorAlert = useAlert(false, 'error');
  const [isProcessing, setIsProcessing] = useState(false);
  const [datetime, setDatetime] = useState(endOfCurrentMonth.unix());
  const onDownload = () => {
    errorAlert.resetAlert();
    setIsProcessing(true);

    let customerRef = `00000${companyId}`.substr(-5);
    let filename = (
      companyId === 0 ?
      `${companyName}-${moment.unix(datetime).format('MMM-YYYY')}.zip`
      :
      `${companyName}-INV${moment.unix(datetime).format('MM')}${moment.unix(datetime).daysInMonth()}${moment.unix(datetime).format('YYYY')}${customerRef}.pdf`
    );
    // validate filename
    filename = filename.replace(/[\s/\\?%*:|"<>]/g, '');
    ajaxGetAdminDistributorInvoice(
      {
        companyId: companyId,
        datetime: datetime
      },
      filename
    )
    .then(() => {
      setIsProcessing(false);
    })
    .catch((e) => {
      errorAlert.setAlert(e.message);
      setIsProcessing(false);
    });
  }
  return (
    <StyledCard
      title="Invoice"
      titleIcon={ArchiveIcon}
      buttons={[
        {
          label: 'Download',
          buttonProps: {
            onClick: onDownload,
            disabled: isProcessing,
            variant: 'contained',
            size: 'small',
            startIcon: (
              isProcessing ?
              <CircularProgress color="info" size={20} thickness={3}/>
              :
              undefined
            )
          }
        }
      ]}
    >
      <CardContent sx={{ pb: '8px' }}>
        <FormControl fullWidth variant="standard">
          <Select
            value={datetime}
            onChange={(e) => setDatetime(e.target.value)}
            disabled={isProcessing}
          >
            {
              (new Array(12)).fill(0).map((e, idx) => {
                let d = moment(endOfCurrentMonth).subtract(idx, 'months').endOf('month');
                return <MenuItem value={d.unix()}>{d.format('MMMM YYYY')}</MenuItem>;
              })
            }
          </Select>
        </FormControl>
      </CardContent>
    </StyledCard>
  );
}
