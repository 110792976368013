import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import StyledMainGrid from '../components/StyledMainGrid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { ajaxGetAdminOptions, ajaxSaveAdminOption } from '../services/adminService';
import validator from 'validator';
import { Link } from 'react-router-dom';
import Page from '../components/Page';
import StyledCard from '../components/StyledCard'
import StyledCardFormContent from '../components/StyledCardFormContent';

export default function Option(props) {
  const incomingParams = useParams();

  const buttonStyle = {
    height: '40px',
    width: '150px',
    fontSize: '12px'
  };

  const allFields = [
    {
      key: 'code',
      type: 'text',
      label: 'API code'
    },
    {
      key: 'label',
      type: 'text',
      label: 'Label'
    }
  ];

  var requiredFields = ['code', 'label'];

  const [option, setOption] = useState({});
  const [errors, setErrors] = useState({});
  const paramOptionId = (incomingParams && incomingParams.OptionId ? incomingParams.OptionId : -1);

  const navigate = useNavigate();

  useEffect(() => {
    if (!props.add) {
      const params = {};

      if (paramOptionId !== -1) {
        params.OptionId = paramOptionId;
      }

      ajaxGetAdminOptions(params)
        .then((res) => {
          const { data } = res;
          setOption(data);
        })
        .catch(() => {
          setOption({});
        });
    }
  }, [paramOptionId, props.add]);

  const handleSave = () => {
    if (!validateFields()) return false;

    const params = { ...option };
    let url = '/dashboard/options';

    ajaxSaveAdminOption(params)
      .then(() => {
        navigate(url);
      })
      .catch((e) => {
      });
  };

  const validateFields = () => {
    var newErros = {};
    const fields = { ...option };
    var result = true;

    for (let index in allFields) {
      let key = allFields[index].key;
      let fieldValue = fields[key] ? fields[key] + '' : '';

      if (validator.isEmpty(fieldValue, { ignore_whitespace: true })) {
        if (requiredFields.includes(key)) {
          newErros[key] = 'required field';
          result = false;
        }
      }
    }

    if (Object.keys(newErros).length !== 0) {
      setErrors(newErros);
    }

    return result;
  };

  const handleFieldChange = (event) => {
    let value = event.target.value,
      key = event.target.name;

    setOption({ ...option, [key]: value });
  };

  const getFieldValue = (key) => {
    return option && option[key] ? option[key] : '';
  };

  const getFieldErrorText = (key) => {
    if (errors && errors[key]) return errors[key];
    else return '';
  };

  const checkFieldError = (key) => {
    if (errors && errors[key]) return true;
    else return false;
  };

  return (
    <Page title="Add / Edit Option">
      <form>
        <StyledCard>
          <StyledCardFormContent>
            <StyledMainGrid>
              {allFields.map((entry, index) => (
                <Grid key={entry['key']} item xs={12}>
                  <TextField
                    label={entry['label']}
                    type={entry['type']}
                    name={entry['key']}
                    value={getFieldValue(entry['key'])}
                    onChange={handleFieldChange}
                    error={checkFieldError(entry['key'])}
                    helperText={getFieldErrorText(entry['key'])}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
              ))}
            <Grid item xs={12} />
              <Grid item container xs={12}>
                <Button variant="contained" size="normal" style={buttonStyle} sx={{ backgroundColor: 'green', mr: '20px' }} onClick={handleSave}>
                  Save Option
                </Button>
                <Button variant="outlined" size="normal" style={buttonStyle} component={Link} to="/dashboard/options">
                  Cancel
                </Button>
              </Grid>
            </StyledMainGrid>
          </StyledCardFormContent>
        </StyledCard>
      </form>
    </Page>
  );
}
