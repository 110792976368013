import { ajaxCall, ajaxCallBlob, formRequestData } from './ajaxService';

const constants = window.getConfig;
const paymentsAdminEndpoint = `${constants.ajax.baseUrl}/admin/payments`;
const getPaymentUrl = `${constants.ajax.baseUrl}/payment`;
const invoicePaymentsAdminEndpoint = `${constants.ajax.baseUrl}/admin/invoicePayments`;
const getInvoicePaymentUrl = `${constants.ajax.baseUrl}/invoicePayments`;
const getInvoicePaymentFileUrl = `${constants.ajax.baseUrl}/invoicePaymentsFile`;

const ajaxGetPayment = (params) =>
  new Promise((res, rej) => {
    if (params.companyId) {
      ajaxCall(paymentsAdminEndpoint + `/${params.companyId}`, formRequestData(constants.ajax.methods.post, true, false, params))
        .then(res)
        .catch(rej);
    } else {
      ajaxCall(getPaymentUrl, formRequestData(constants.ajax.methods.get, true)).then(res).catch(rej);
    }
  });

const ajaxReceivePayment = (params) =>
  new Promise((res, rej) => {
    ajaxCall(paymentsAdminEndpoint + '/receive', formRequestData(constants.ajax.methods.post, true, false, params))
      .then(res)
      .catch(rej);
  });

const ajaxGetInvoicePayment = (params) =>
  new Promise((res, rej) => {
    if (params.companyId) {
      ajaxCall(invoicePaymentsAdminEndpoint + `/${params.companyId}`, formRequestData(constants.ajax.methods.post, true, false, params))
        .then(res)
        .catch(rej);
    } else {
      ajaxCall(getInvoicePaymentUrl, formRequestData(constants.ajax.methods.get, true)).then(res).catch(rej);
    }
  });

const ajaxReceiveInvoicePayment = (params) =>
  new Promise((res, rej) => {
    ajaxCall(invoicePaymentsAdminEndpoint + '/receiveInvoicePayment', formRequestData(constants.ajax.methods.post, true, false, params))
      .then(res)
      .catch(rej);
  });

  const ajaxGetInvoicePaymentFile = (params, filename) =>
  new Promise((res, rej) => {
    ajaxCallBlob(getInvoicePaymentFileUrl + `/${params.datetime}`, formRequestData(constants.ajax.methods.get, true))
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.target = '_blank';
        a.download = filename;
        a.click();
        res();
      })
      .catch(rej);
  });

export {
  ajaxGetPayment, ajaxReceivePayment,
  ajaxGetInvoicePayment, ajaxReceiveInvoicePayment,
  ajaxGetInvoicePaymentFile
};
