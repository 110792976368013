import React, {useMemo} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import StyledMainGrid from '../../components/StyledMainGrid';
import { MoneyFormat } from '../../components/MoneyFormat';

export default function PaymentHeader({balance, items, currency, exchangeRate}) {
  const showDistributorPaymentLocalCurrency = !!window.SHOW_DIST_PAY_LOCAL_CUR;
  const stats = useMemo(() => {
    let total = 0;
    let total_local = 0;
    let paid_amount = 0;
    items.forEach((item) => {
      total += parseFloat(item.total);
      total_local += parseFloat(item.total_local);
      paid_amount += parseFloat(item.paid_amount);
    });

    let avgRate = total > 0 ? total_local / total  : exchangeRate;
    return {
      totalInvoices: items.length,
      totalAmount: total,
      totalAmountLocal: total_local,
      totalPaidAmount: paid_amount,
      totalPaidAmountLocal: paid_amount * avgRate
    };
  }, [items, exchangeRate]);

  const typographySx = {
    textAlign: 'center',
    paddingTop: '10px'
  };
  const iconContainerSx = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '5px'
  };
  return (
    <StyledMainGrid item container columnSpacing>
      <Grid item xs="4">
        <Typography variant="h6" sx={typographySx}>
          <Box>
            Balance
          </Box>
          <Box sx={iconContainerSx}>
            {
              showDistributorPaymentLocalCurrency ?
              <MoneyFormat currency={currency} value={balance * exchangeRate} />
              :
              <MoneyFormat value={balance} />
            }
          </Box>
        </Typography>
      </Grid>
      <Grid item xs="4">
        <Typography variant="h6" sx={typographySx}>
          <Box>
            Total Invoices
          </Box>
          <Box sx={iconContainerSx}>
            {stats.totalInvoices}
          </Box>
        </Typography>
      </Grid>
      <Grid item xs="4">
        <Typography variant="h6" sx={typographySx}>
          <Box>
            Amount Due
          </Box>
          <Box sx={iconContainerSx}>
            {
              showDistributorPaymentLocalCurrency ?
              <MoneyFormat currency={currency} value={stats.totalAmountLocal - stats.totalPaidAmountLocal} />
              :
              <MoneyFormat value={stats.totalAmount - stats.totalPaidAmount} />
            }
          </Box>
        </Typography>
      </Grid>
    </StyledMainGrid>
  );
}
