import React, { useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { MoneyFormat } from '../components/MoneyFormat';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { ajaxGetAdminProducts } from '../services/adminService';
import Page from '../components/Page';
import StyledCard from '../components/StyledCard'
import StyledCardSearch from '../components/StyledCardSearch';
import StyledList from '../components/StyledList';
import StyledCardFormContent from '../components/StyledCardFormContent';

export default function ProductList() {
  const [items, setItems] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [searchResults, setSearchResults] = React.useState([]);
  const navigate = useNavigate();
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));

  const renderItem = (data, idx, secondarySx) => {
    return (
      <ListItem
        key={idx}
        disablePadding
        secondaryAction={
          <React.Fragment>
            <MoneyFormat prefix="US$ " value={data.msrp} />
            <IconButton
              component={RouterLink}
              to={'/dashboard/products/edit/' + data.id}
            >
              <EditOutlinedIcon />
            </IconButton>
          </React.Fragment>
        }
      >
        <ListItemButton component={RouterLink} to={'/dashboard/products/edit/' + data.id}>
          <ListItemText
            primary={
              data.active ?
              data.code
              :
              <Box sx={{ mb: '3px' }}>
                {data.code}
                <Chip label="Disabled" size="small" color="error" sx={{ ml: '10px', mt: '-3px' }} />
              </Box>
            }
            secondary={
              <Tooltip title={data.description || data.type}>
                <Box sx={{ maxWidth: '50%' }}>
                  {
                    smUp ?
                    <Box>
                      {data.description || data.type}
                    </Box>
                    :
                    <Box>
                      {data.type}
                    </Box>
                  }
                </Box>
              </Tooltip>
            }
          />
        </ListItemButton>
      </ListItem>
    );
  };

  const searchChangeHandle = (event) => {
    const new_value = event.target.value.toLowerCase();
    setSearchTerm(new_value);
  };

  useEffect(() => {
    const params = {};

    ajaxGetAdminProducts(params)
      .then((res) => {
        const { data } = res;
        setItems(data);
      })
      .catch(() => {
        setItems([]);
      });
  }, []);

  useEffect(() => {
    const results = items.filter((item) => `${(item.description || '')}${(item.code || '')}`.toLowerCase().includes(searchTerm));

    setSearchResults(results);
  }, [searchTerm, /*searchFilter,*/ items]);

  return (
    <Page title="Add / Edit Product">
      <StyledCard>
        <StyledCardSearch onSearch={searchChangeHandle} />
        <StyledCardFormContent>
          <StyledList
            addRow
            onAdd={() => navigate('/dashboard/products/add')}
            dataSource={searchResults}
            renderItem={renderItem}
            sort={{
              sortKey: 'type',
              sortOptions: [
                {
                  key: 'type',
                  label: 'Product Type'
                },
                {
                  key: 'code',
                  label: 'Product Code'
                },
                {
                  key: 'msrp',
                  label: 'MSRP'
                },
                {
                  key: 'active',
                  label: 'Disabled'
                }
              ]
            }}/>
        </StyledCardFormContent>
      </StyledCard>
    </Page>
  );
}
