import React, {useRef} from 'react';

import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import useAlert from '../../hooks/useAlert';

import ProductCodeAutocomplete from '../../components/ProductCodeAutocomplete';
import FileUploader from '../../components/FileUploader';
import {isMacAddress, formatMacAddress} from '../../helpers';

export default function ShipmentItemDialog(props) {
  const [open, setOpen] = React.useState(false);

  const [fields, setFields] = React.useState({ quantity: 1, keys: '' });

  const [isValidateUpload, setValidateUpload] = React.useState(true);

  const [currentProductType, setCurrentProductType] = React.useState('sensor');

  const fileRef = useRef(null);

  const errorAlert = useAlert(false, 'error');

  const handleClose = (value) => {
    setOpen(false);
    setFields({ quantity: 1, keys: '' });
    setValidateUpload(true);
    errorAlert.resetAlert();
  };

  const handleChangeField = (event) => {
    let value = event.target.value;
    let key = event.target.name;

    if (key === 'quantity') {
      value = value.replace(/\D/g, '');
    }

    let copyFields = { ...fields };

    copyFields[key] = value;

    setFields(copyFields);
  };

  const handleChangeProductCode = (productCode) => {
    setFields({ ...fields, code: productCode['code'], id: productCode['id'] });
    setCurrentProductType(productCode.type);
  };

  const handleUploadFileCSV = (e) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], 'UTF-8');
    fileReader.onload = (e) => {
      let stringData = e.target.result;
      setFields({ ...fields, keys: stringData });
    };
  };

  const validateFields = () => {
    if (!fields['code']) {
      errorAlert.setAlert('You have to choose Product Code');
      return false;
    }

    // validate keys
    let keyArray = fields['keys']
        .replace(/\r/g, '')
        .split(/[,\n]/)
        .filter( k => k !== '')
        .map( k => k.trim() );

    if (keyArray.length === 0) {
      errorAlert.setAlert('You have to enter Serial Number, MAC Address');
      setValidateUpload(false);
      return false;
    }

    // check unique keys
    const uniqueArray = [...new Set(keyArray)];
    if ( keyArray.length !== uniqueArray.length ) {
      errorAlert.setAlert('You have to enter Unique Serial Number, MAC Address');
      setValidateUpload(false);
      return false;
    }

    // check type first
    let keyName = 'Keys';
    if ( currentProductType === 'base unit' ) {
      keyName = 'MAC Address';
      if ( keyArray.length > 0 ) {
        let validateMac = true;
        let errorMac = '';
        keyArray.every( mac => {
          if ( !isMacAddress(mac) ) {
            validateMac = false;
            errorMac = mac;
            fileRef.current.value = '';
            return false;
          }
          return true;
        });

        if ( !validateMac ) {
          errorAlert.setAlert(errorMac + ' is not MAC Address');
          setValidateUpload(false);
          return false;
        }
      }
    }

    // validate count
    if ( keyArray.length !== Number(fields['quantity']) ) {
      errorAlert.setAlert(`Input has ${keyArray.length} ${keyName}. There should be ${fields['quantity']} ${keyName}.`);
      setValidateUpload(false);
      return false;
    }

    setValidateUpload(true);
    errorAlert.setAlert('');
    return true;
  };

  const handleSave = () => {
    if ( validateFields() ) {
      // format mac address
      let keyArray = fields['keys']
      .replace(/\r/g, '')
      .split(/[,\n]/)
      .filter( m => m !== '' )
      .map( k => k.trim() );

      if ( currentProductType === 'base unit' ) {
        keyArray = keyArray.map( m => formatMacAddress(m).toUpperCase() );
      }

      props.handleDialogClickYes({...fields, keys: keyArray});
      handleClose();
    }
  };

  const dialogButtonStyle = {
    padding: '4px 30px'
  };

  return (
    <React.Fragment>
      <Button
        size="small"
        variant="contained"
        color="info"
        sx={{
          margin: '10px',
          height: '40px',
          width: '130px',
          fontSize: '12px'
        }}
        startIcon={<AddIcon />}
        onClick={() => setOpen(true)}
      >
        Add Product
      </Button>
      <Dialog fullWidth PaperProps={{ style: { backgroundColor: 'white', color: 'black' } }} onClose={handleClose} open={open}>
        <DialogContent sx={{ maxHeight: '500px' }}>
          <DialogContentText color="inherit" id="alert-dialog-description" sx={{ mb: '10px', fontSize: '1.5rem', fontWeight: 400 }}>
            {props.dialogText}
          </DialogContentText>

          <Grid container direction="row" rowSpacing="10px" sx={{ overflow: 'hidden' }}>
            <Grid item xs={12}>
              <ProductCodeAutocomplete setSearchResult={handleChangeProductCode} getOptionDisabled={(item) => props.excludedProductCodes.filter((value) => value === item['code']).length > 0} />
            </Grid>

            <Grid item xs={12}>
              <TextField label="Quantity" name="quantity" variant="outlined" value={fields['quantity']} onChange={handleChangeField} fullWidth />
            </Grid>

            <Grid item xs={12}>
              <TextField multiline fullWidth type="text" minRows={5} name={'keys'} value={fields['keys']} onChange={handleChangeField} label={'Serial Number, MAC Address. Multiple values separate by comma or newline'} variant="outlined" />
            </Grid>

            <Grid item xs={12}>
              <FileUploader inputRef={fileRef} accept=".csv" name="file_keys" label="Upload CSV File with Serial Number or MAC Address" icon={<AttachFileIcon />} handleChange={handleUploadFileCSV} error={!isValidateUpload} />
            </Grid>
            <Grid item xs={12}>
              {errorAlert.showAlert()}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ flex: 'space-around' }}>
          <Button style={dialogButtonStyle} variant="contained" onClick={handleSave}>
            {props.dialogYesText}
          </Button>
          <Button style={dialogButtonStyle} variant="outlined" onClick={handleClose}>
            {props.dialogNoText}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
