import { ajaxCall, formRequestData } from './ajaxService';

const constants = window.getConfig;
const ajaxGetCompanyInfoUrl = `${constants.ajax.baseUrl}/company`;
const ajaxGetCompanyDailySalesUrl = `${constants.ajax.baseUrl}/company/daily/sales`;
const ajaxGetCompanyMonthlySalesUrl = `${constants.ajax.baseUrl}/company/monthly/sales`;
const ajaxGetCompanyDailySalesTotalUrl = `${constants.ajax.baseUrl}/company/daily/salesTotal`;
const ajaxGetCompanyMonthlySalesTotalUrl = `${constants.ajax.baseUrl}/company/monthly/salesTotal`;
const ajaxMakeSaleUrl = `${constants.ajax.baseUrl}/company/inventory/sale`;
const ajaxMakeSaleOptionsUrl = `${constants.ajax.baseUrl}/company/inventory/saleOptions`;
const ajaxMakeReplacementUrl = `${constants.ajax.baseUrl}/company/inventory/replacement`;
const ajaxMakeCancelReplacementUrl = `${constants.ajax.baseUrl}/company/inventory/cancelReplacement`;
const ajaxMakeReservationUrl = `${constants.ajax.baseUrl}/company/inventory/reserve`;
const ajaxAdminGetCompanyInfoUrl = `${constants.ajax.baseUrl}/admin/company`;
const ajaxSaveProductRefUrl = `${constants.ajax.baseUrl}/company/inventory/productRef`;
const ajaxCompanyManagersUrl = `${constants.ajax.baseUrl}/company/managers`;

const ajaxGetCompanyInfo = (params) =>
  new Promise((res, rej) => {
    if (params && params['companyId']) {
      ajaxCall(ajaxAdminGetCompanyInfoUrl + `/${params.companyId}`, formRequestData(constants.ajax.methods.get, true))
        .then(res)
        .catch(rej);
    } else {
      ajaxCall(ajaxGetCompanyInfoUrl, formRequestData(constants.ajax.methods.get, true)).then(res).catch(rej);
    }
  });

const ajaxGetCompanyMonthlySales = (params) =>
  new Promise((res, rej) => {
    if (params.companyId) {
      ajaxCall(ajaxGetCompanyMonthlySalesUrl + `/${params.companyId}`, formRequestData(constants.ajax.methods.get, true))
        .then(res)
        .catch(rej);
    } else {
      ajaxCall(ajaxGetCompanyMonthlySalesUrl, formRequestData(constants.ajax.methods.get, true)).then(res).catch(rej);
    }
  });

const ajaxGetCompanyDailySales = (params) =>
  new Promise((res, rej) => {
    if (params.companyId) {
      ajaxCall(ajaxGetCompanyDailySalesUrl + `/${params.companyId}`, formRequestData(constants.ajax.methods.get, true))
        .then(res)
        .catch(rej);
    } else {
      ajaxCall(ajaxGetCompanyDailySalesUrl, formRequestData(constants.ajax.methods.get, true)).then(res).catch(rej);
    }
  });

const ajaxGetCompanyMonthlySalesTotal = (params) =>
  new Promise((res, rej) => {
    if (params.companyId) {
      ajaxCall(ajaxGetCompanyMonthlySalesTotalUrl + `/${params.companyId}`, formRequestData(constants.ajax.methods.get, true))
        .then(res)
        .catch(rej);
    } else {
      ajaxCall(ajaxGetCompanyMonthlySalesTotalUrl, formRequestData(constants.ajax.methods.get, true)).then(res).catch(rej);
    }
  });

const ajaxGetCompanyDailySalesTotal = (params) =>
  new Promise((res, rej) => {
    if (params.companyId) {
      ajaxCall(ajaxGetCompanyDailySalesTotalUrl + `/${params.companyId}`, formRequestData(constants.ajax.methods.get, true))
        .then(res)
        .catch(rej);
    } else {
      ajaxCall(ajaxGetCompanyDailySalesTotalUrl, formRequestData(constants.ajax.methods.get, true)).then(res).catch(rej);
    }
  });

const ajaxMakeSales = (params) =>
  new Promise((res, rej) => {
    ajaxCall(ajaxMakeSaleUrl, formRequestData(constants.ajax.methods.post, true, false, params)).then(res).catch(rej);
  });

const ajaxMakeSaleOptions = (params) =>
  new Promise((res, rej) => {
    ajaxCall(ajaxMakeSaleOptionsUrl, formRequestData(constants.ajax.methods.post, true, false, params)).then(res).catch(rej);
  });


const ajaxMakeReplacement = (params) =>
  new Promise((res, rej) => {
    ajaxCall(ajaxMakeReplacementUrl, formRequestData(constants.ajax.methods.post, true, false, params)).then(res).catch(rej);
  });

const ajaxMakeCancelReplacement = (params) =>
  new Promise((res, rej) => {
    ajaxCall(ajaxMakeCancelReplacementUrl, formRequestData(constants.ajax.methods.post, true, false, params)).then(res).catch(rej);
  });

const ajaxMakeReservation = (params) =>
  new Promise((res, rej) => {
    ajaxCall(ajaxMakeReservationUrl, formRequestData(constants.ajax.methods.post, true, false, params)).then(res).catch(rej);
  });

const ajaxSaveProductRef = (params) =>
  new Promise((res, rej) => {
    ajaxCall(ajaxSaveProductRefUrl, formRequestData(constants.ajax.methods.post, true, false, params)).then(res).catch(rej);
  });

const ajaxGetCompanyManagers = (params) =>
  new Promise((res, rej) => {
    if (params.UserId) {
      ajaxCall(ajaxCompanyManagersUrl + `/${params.UserId}`, formRequestData(constants.ajax.methods.get, true))
        .then(res)
        .catch(rej);
    } else {
      ajaxCall(ajaxCompanyManagersUrl, formRequestData(constants.ajax.methods.get, true)).then(res).catch(rej);
    }
  });

const ajaxSaveCompanyManager = (params) =>
  new Promise((res, rej) => {
    ajaxCall(ajaxCompanyManagersUrl + '/save', formRequestData(constants.ajax.methods.post, true, false, params, true))
      .then(res)
      .catch(rej);
  });

const ajaxDisableCompanyManager = (params) =>
  new Promise((res, rej) => {
    if (params.id) {
      ajaxCall(ajaxCompanyManagersUrl + `/disable/${params.id}`, formRequestData(constants.ajax.methods.put, true))
        .then(res)
        .catch(rej);
    }
  });

const ajaxEnableCompanyManager = (params) =>
  new Promise((res, rej) => {
    if (params.id) {
      ajaxCall(ajaxCompanyManagersUrl + `/enable/${params.id}`, formRequestData(constants.ajax.methods.put, true))
        .then(res)
        .catch(rej);
    }
  });

const ajaxDeleteCompanyManager = (params) =>
  new Promise((res, rej) => {
    if (params.id) {
      ajaxCall(ajaxCompanyManagersUrl + `/delete/${params.id}`, formRequestData(constants.ajax.methods.put, true))
        .then(res)
        .catch(rej);
    }
  });

export {
  ajaxGetCompanyInfo,
  ajaxGetCompanyMonthlySales,
  ajaxGetCompanyDailySales,
  ajaxGetCompanyMonthlySalesTotal,
  ajaxGetCompanyDailySalesTotal,
  ajaxMakeSales,
  ajaxMakeSaleOptions,
  ajaxMakeReplacement,
  ajaxMakeCancelReplacement,
  ajaxMakeReservation,
  ajaxSaveProductRef,
  ajaxGetCompanyManagers,
  ajaxSaveCompanyManager,
  ajaxDisableCompanyManager,
  ajaxEnableCompanyManager,
  ajaxDeleteCompanyManager
};
