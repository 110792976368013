import React, { useState, useEffect } from 'react';
import { ajaxGetCompanyDailySalesTotal } from '../../services/companyService';
import {
  ResponsiveContainer, LineChart,
  Line, XAxis, YAxis,
  Tooltip as ChartTootip
} from 'recharts';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import CircularProgress from '@mui/material/CircularProgress';
import StyledCard from '../../components/StyledCard'
import { MoneyFormat } from '../../components/MoneyFormat';
import _ from 'underscore'
import moment from 'moment';

const CustomTooltip = ({ active, payload, label }) => {
  const style = {
    container: {
      border: '1px solid grey',
      borderRadius: '4px',
      backgroundColor: 'rgba(255,255,255,0.5)',
      fontSize: '12px',
      padding: '8px 16px'
    },
    month: {
      fontWeight: 'bold'
    },
    sale: {
      color: '#0071bd',
      fontSize: '14px',
      fontWeight: 'bold'
    }
  };
  if (active && payload && payload.length) {
    return (
      <div style={style.container}>
        <div style={style.month}>{label}</div>
        <div>
          <span style={style.month}>{'Sales: '}</span>
          <MoneyFormat style={style.sale} currency={window.IS_ADMIN ? null : payload[0].payload.currency} value={payload[0].value} />
        </div>
      </div>
    );
  }

  return null;
};

export default function SummarySales(props) {
  const [monthlySales, setMonthlySales] = useState(false);
  const [localCurrency, setLocalCurrency] = useState('USD');
  const [ytdSale, setYtdSale] = useState({usd: 0, local: 0});
  const [latest3MonthSale, setLatest3MonthSale] = useState({usd: 0, local: 0});
  const [latestMonthSale, setLatestMonthSale] = useState({usd: 0, local: 0});
  const [latest30DaysSale, setLatest30DaysSale] = useState({usd: 0, local: 0});
  const [latest7DaysSale, setLatest7DaysSale] = useState({usd: 0, local: 0});
  const [last30DaysSale, setLast30DaysSale] = useState({usd: 0, local: 0});
  const [last7DaysSale, setLast7DaysSale] = useState({usd: 0, local: 0});

  const timeFromToFormat = (from, to) => {
    if ( from.year() !== to.year() ) {
      if ( from.date() !== to.date() || from.date() !== 1 ) {
        return `${from.format('D MMM YYYY')} - ${to.format('D MMM YYYY')}`;
      }
      else if ( from.month() !== to.month() ) {
        return `${from.format('MMM YYYY')} - ${to.format('MMM YYYY')}`;
      }
      return `${from.format('YYYY')} - ${to.format('YYYY')}`;
    }
    else if ( from.month() !== to.month() ) {
      if ( from.date() !== to.date() || from.date() !== 1 ) {
        return `${from.format('D MMM YYYY')} - ${to.format('D MMM YYYY')}`;
      }
      return `${from.format('MMM YYYY')} - ${to.format('MMM YYYY')}`;
    }
    return `${from.format('D MMM YYYY')} - ${to.format('D MMM YYYY')}`;
  };

  let now = moment();
  let startLatestYear = moment().utc().startOf('year');
  let startLatest3Month = moment().utc().subtract(2, 'months').startOf('month');
  let startLatestMonth = moment().utc().startOf('month');
  let startLatest30Day = moment().utc().subtract(30, 'days').startOf('day');
  let startLatest7Day = moment().utc().subtract(7, 'days').startOf('day');
  let startLast30Day = moment(startLatest30Day).utc().subtract(30, 'days').startOf('day');
  let startLast7Day = moment(startLatest7Day).utc().subtract(7, 'days').startOf('day');
  let change30Day = (
    window.IS_ADMIN ?
    (((latest30DaysSale.usd - last30DaysSale.usd) / last30DaysSale.usd) * 100)
    :
    (((latest30DaysSale.local - last30DaysSale.local) / last30DaysSale.local) * 100)
  );
  let change7Day = (
    window.IS_ADMIN ?
    (((latest7DaysSale.usd - last7DaysSale.usd) / last7DaysSale.usd) * 100)
    :
    (((latest7DaysSale.local - last7DaysSale.local) / last7DaysSale.local) * 100)
  );

  useEffect(() => {
    let data = [];

    if (props.companyId) {
      data.companyId = props.companyId;
    }

    ajaxGetCompanyDailySalesTotal(data).then((res) => {
        const { data } = res;

        if ( !Array.isArray(data) ) {
          setMonthlySales(false);
          return;
        }

        let currency = 'USD';
        if ( data.length >= 1 ) {
          currency = data[0].currency;
          setLocalCurrency(data[0].currency);
        }

        let saleItems = data.reduce((sum, cur) => [...sum, ...cur.saleItems], [])
        let monthlyFormat = 'YYYY-MM';
        let groupedByMonthData = _.groupBy(saleItems, d => {
          return moment.unix(d.date).utc().format(monthlyFormat);
        });

        // last 12 months
        let preparedMonthlyGraphData = [];
        let consideringMoment = moment();
        for ( let i = 0 ; i < 12; i++ ) {
          let monthlyKey = consideringMoment.format(monthlyFormat);
          // create empty data
          let monthlyGraphData = {
            name: consideringMoment.format('MMM YY'),
            sale: 0,
            saleLocal: 0,
            currency: currency
          };

          if ( groupedByMonthData.hasOwnProperty(monthlyKey) ) {
            let rawdata = groupedByMonthData[monthlyKey];
            monthlyGraphData.sale = rawdata.reduce((sum, cur) => sum + cur.total, 0);
            monthlyGraphData.saleLocal = rawdata.reduce((sum, cur) => sum + cur.total_local, 0);
          }

          preparedMonthlyGraphData = [
            monthlyGraphData,
            ...preparedMonthlyGraphData
          ];

          consideringMoment = consideringMoment.subtract(1, 'months');
        }

        setMonthlySales(preparedMonthlyGraphData);

        let vLatestYear = {usd: 0, local: 0};
        let vLatest3Month = {usd: 0, local: 0};
        let vLatestMonth = {usd: 0, local: 0};
        let vLatest30Day = {usd: 0, local: 0};
        let vLatest7Day = {usd: 0, local: 0};
        let vLast30Day = {usd: 0, local: 0};
        let vLast7Day = {usd: 0, local: 0};
        saleItems.forEach(rd => {
          let rdTs = rd.date;
          let rdTotal = rd.total;
          let rdTotalLocal = rd.total_local;
          if ( rdTs >= startLatestYear.unix()    ) { vLatestYear.usd += rdTotal;    vLatestYear.local += rdTotalLocal; }
          if ( rdTs >= startLatest3Month.unix()  ) { vLatest3Month.usd += rdTotal;  vLatest3Month.local += rdTotalLocal; }
          if ( rdTs >= startLatestMonth.unix()   ) { vLatestMonth.usd  += rdTotal;  vLatestMonth.local  += rdTotalLocal; }
          if ( rdTs >= startLatest30Day.unix()   ) { vLatest30Day.usd  += rdTotal;  vLatest30Day.local  += rdTotalLocal; }
          if ( rdTs >= startLatest7Day.unix()    ) { vLatest7Day.usd  += rdTotal;   vLatest7Day.local  += rdTotalLocal; }
          if ( rdTs >= startLast30Day.unix() && rdTs < startLatest30Day.unix() ) { vLast30Day.usd  += rdTotal; vLast30Day.local  += rdTotalLocal; }
          if ( rdTs >= startLast7Day.unix()  && rdTs < startLatest7Day.unix()  ) { vLast7Day.usd  +=  rdTotal; vLast7Day.local  += rdTotalLocal; }
        });

        setYtdSale(vLatestYear);
        setLatest3MonthSale(vLatest3Month);
        setLatestMonthSale(vLatestMonth);
        setLatest30DaysSale(vLatest30Day);
        setLatest7DaysSale(vLatest7Day);
        setLast30DaysSale(vLast30Day);
        setLast7DaysSale(vLast7Day);
      })
      .catch(() => {
        setMonthlySales(false);
      });
  }, []);

  const secondarySx = { color: 'rgba(0, 0, 0, 0.6)', fontWeight: 'bold' };
  return (
    <StyledCard
      title="Sales Summary"
      titleIcon={TrendingUpIcon}
    >
      <CardContent>
        <Grid
          container
          rowSpacing="15px"
          columnSpacing="15px"
        >
          <Grid container item xs={12} sm={6}>
            <Grid item xs={12}>
              <Tooltip title={timeFromToFormat(startLatestYear, now)}>
                <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <Box>
                    <Typography variant="h6" sx={secondarySx}>
                      TOTAL REVENUE
                    </Typography>
                  </Box>
                  <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', alignItems: 'baseline'}}>
                      <Typography variant="h3">
                        {
                          window.IS_ADMIN ?
                          <MoneyFormat decimalScale={0} value={ytdSale.usd} />
                          :
                          <MoneyFormat currency={localCurrency} decimalScale={0} value={ytdSale.local} />
                        }
                      </Typography>
                      &nbsp;
                      <Typography variant="caption" sx={secondarySx}>
                        YTD
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Tooltip>
            </Grid>
            <Grid container item xs={12} alignItems="flex-end">
              <Grid item xs={6}>
                <Tooltip title={timeFromToFormat(startLatest3Month, now)}>
                  <Box>
                    <Box>
                      <Typography variant="h5">
                        {
                          window.IS_ADMIN ?
                          <MoneyFormat decimalScale={0} value={latest3MonthSale.usd} />
                          :
                          <MoneyFormat currency={localCurrency} decimalScale={0} value={latest3MonthSale.local} />
                        }
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" sx={secondarySx}>
                        Latest 3 Months
                      </Typography>
                    </Box>
                  </Box>
                </Tooltip>
              </Grid>
              <Grid item xs={6}>
                <Tooltip title={`${startLatestMonth.format('MMM YYYY')}`}>
                  <Box>
                    <Box>
                      <Typography variant="h5">
                        {
                          window.IS_ADMIN ?
                          <MoneyFormat decimalScale={0} value={latestMonthSale.usd} />
                          :
                          <MoneyFormat currency={localCurrency} decimalScale={0} value={latestMonthSale.local} />
                        }
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" sx={secondarySx}>
                        Latest Month
                      </Typography>
                    </Box>
                  </Box>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={6}>
            <Grid container item xs={12} alignItems="flex-end">
              <Grid item xs={6}>
                <Tooltip
                  title={
                    <Box>
                      <Box>
                        {timeFromToFormat(startLast30Day, moment(startLatest30Day).subtract(1, 'seconds'))} = 
                        {
                          window.IS_ADMIN ?
                          <MoneyFormat decimalScale={0} value={last30DaysSale.usd} />
                          :
                          <MoneyFormat currency={localCurrency} decimalScale={0} value={last30DaysSale.local} />
                        }
                      </Box>
                      <Box>
                        {timeFromToFormat(startLatest30Day, now)} = 
                        {
                          window.IS_ADMIN ?
                          <MoneyFormat decimalScale={0} value={latest30DaysSale.usd} />
                          :
                          <MoneyFormat currency={localCurrency} decimalScale={0} value={latest30DaysSale.local} />
                        }
                      </Box>
                    </Box>
                  }
                >
                  <Box>
                    <Box>
                      <Typography variant="h5" sx={{ color: change30Day >= 0 ? 'green' : '#d32f2f', display: 'flex', alignItems: 'center' }}>
                        {
                          change30Day > 0 &&
                          <TrendingUpIcon sx={{ mr: '5px' }}/>
                        }
                        {
                          change30Day < 0 &&
                          <TrendingDownIcon sx={{ mr: '5px' }}/>
                        }
                        {isNaN(change30Day) ? 'N/A' : change30Day === Infinity ? 100 : Math.round(change30Day)}%
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" sx={secondarySx}>
                        30 Days Changed
                      </Typography>
                    </Box>
                  </Box>
                </Tooltip>
              </Grid>
              <Grid item xs={6}>
                <Tooltip
                  title={
                    <Box>
                      <Box>
                        {timeFromToFormat(startLast7Day, moment(startLatest7Day).subtract(1, 'seconds'))} = 
                        {
                          window.IS_ADMIN ?
                          <MoneyFormat decimalScale={0} value={last7DaysSale.usd} />
                          :
                          <MoneyFormat currency={localCurrency} decimalScale={0} value={last7DaysSale.local} />
                        }
                      </Box>
                      <Box>
                        {timeFromToFormat(startLatest7Day, now)} = 
                        {
                          window.IS_ADMIN ?
                          <MoneyFormat decimalScale={0} value={latest7DaysSale.usd} />
                          :
                          <MoneyFormat currency={localCurrency} decimalScale={0} value={latest7DaysSale.local} />
                        }
                      </Box>
                    </Box>
                  }
                >
                  <Box>
                    <Box>
                      <Typography variant="h5" sx={{ color: change7Day >= 0 ? 'green' : '#d32f2f', display: 'flex', alignItems: 'center' }}>
                        {
                          change7Day > 0 &&
                          <TrendingUpIcon sx={{ mr: '5px' }}/>
                        }
                        {
                          change7Day < 0 &&
                          <TrendingDownIcon sx={{ mr: '5px' }}/>
                        }
                        {isNaN(change7Day) ? 'N/A' : change7Day === Infinity ? 100 : Math.round(change7Day)}%
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" sx={secondarySx}>
                        7 Days Changed
                      </Typography>
                    </Box>
                  </Box>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={12}>
              <Box sx={{ ml: '-5px', height: '100px' }}>
                {
                  monthlySales ?
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                      layout="horizontal"
                      data={monthlySales}
                      margin={{
                        top: 10,
                        left: 5,
                        bottom: 10,
                        right: 20
                      }}
                    >
                      <XAxis hide dataKey="name" type="category" />
                      <YAxis hide type="number" />
                      <ChartTootip content={<CustomTooltip />} />
                      <Line type="monotone" dataKey={window.IS_ADMIN ? 'sale' : 'saleLocal'} strokeWidth={3} stroke="#0071bd" dot={false} activeDot={{ r: 4 }} />
                    </LineChart>
                  </ResponsiveContainer>
                  :
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
                    <CircularProgress color="info" />
                  </Box>
                }
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Tooltip title={timeFromToFormat(moment().subtract(12, 'months'), now)}>
                <Box>
                  <Typography variant="caption" sx={secondarySx}>
                    Latest 12 Months
                  </Typography>
                </Box>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </StyledCard>
  );
}
