import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import { ajaxReceiveInvoicePayment } from '../../services/paymentService';
import { MoneyFormat } from '../../components/MoneyFormat';
import validator from 'validator';
import moment from 'moment';
import useAlert from '../../hooks/useAlert'

export default function InvoicePayDialog({ onHide, companyId, show, data }) {
  const prepareData = useMemo( () => ({
    date: moment().unix(),
    balance: 0,
    invoice_no: '',
    total: 0,
    paid_amount: 0,
    ...data
  }), [data]);
  const remainingAmount = (prepareData.total - prepareData.paid_amount).toFixed(2);
  const initialFieldsValues = { amount: remainingAmount, method: 'balance' };
  const [fields, setFields] = useState({ ...initialFieldsValues });
  const [errors, setErrors] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const errorAlert = useAlert(false, 'error');
  

  const onHideHandler = (params) => {
    errorAlert.resetAlert();
    setErrors(false);
    setFields({ ...initialFieldsValues });
    setIsChanged(false);
    onHide(params);
  };

  const getError = (fieldId) => {
    return errors && errors[fieldId];
  };

  const checkError = (fieldId) => {
    return errors && errors[fieldId] ? true : false;
  };

  const validateFields = useCallback(() => {
    let hasError = false;
    let newErrors = {};

    let amountValue = fields['amount'] ? fields['amount'] + '' : '';
    if (validator.isEmpty(amountValue, { ignore_whitespace: true })) {
      newErrors['amount'] = 'required field';
      hasError = true;
    }

    if ( fields['method'] === 'balance' ) {
      if ( fields['amount'] > prepareData.balance ) {
        newErrors['amount'] = `Not enough money in the balance. ($${prepareData.balance})`;
        hasError = true;
      }
    }

    if ( fields['amount'] > remainingAmount ) {
      newErrors['amount'] = `Pay amount must be less than the remaining amount. ($${remainingAmount})`;
      hasError = true;
    }

    setErrors(newErrors);
    return !hasError;
  }, [fields, prepareData, remainingAmount]);

  const handleFieldChange = (event) => {
    const value = event.target.value;
    const valueNum = value !== '' ? Number(value) : NaN;

    switch (event.target.id) {
      case 'amount':
        setFields({ ...fields, amount: isNaN(valueNum) ? value : valueNum });
        break;
      case 'balance':
      case 'new':
        setFields({ ...fields, method: value });
        break;
      default:
        break;
    }

    setIsChanged(true);
  };

  const handleSave = () => {
    if (validateFields()) {
      let params = {
        companyId: companyId,
        invDate: prepareData.date,
        amount: fields['amount'],
        payMethod: fields['method']
      };

      ajaxReceiveInvoicePayment(params)
        .then((res) => {
          onHideHandler(true);
        })
        .catch((e) => {
          onHideHandler(true);
          errorAlert.setAlert(e.message);
        });
    }
  };

  useEffect(() => {
    if ( isChanged ) {
      validateFields();
    }
  }, [validateFields, isChanged]);

  const sx = {
    dialogBtn: {
      padding: '4px 30px'
    },
    dialogTitleAlt: {
      fontSize: '13px'
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    secondary: {
      fontSize: '13px',
      color: 'rgba(0, 0, 0, 0.6)'
    },
    hideHr: {
      borderWidth: 0
    },
    radioGroup: {
      mt: '15px'
    },
    radioLabel: {
      mt: '20px'
    },
    textField: {
      mt: '30px'
    }
  };
  return (
    <Dialog fullWidth PaperProps={{ style: { backgroundColor: 'white', color: 'black' } }} onClose={() => onHideHandler({ button: 'cancel' })} open={show}>
      <DialogTitle>{`Invoice for ${moment.unix(prepareData.date).utc().format('MMMM YYYY')}`} <span style={sx.dialogTitleAlt}>({prepareData.invoice_no})</span></DialogTitle>
      <DialogContent>
        <DialogContentText color="inherit">
            <Box sx={sx.row}>
              <Box>Total Amount</Box>
              <MoneyFormat value={prepareData.total} />
            </Box>
            <hr style={sx.hideHr}/>
            <Box sx={sx.row}>
              <Box>Paid Amount</Box>
              <MoneyFormat value={-prepareData.paid_amount} />
            </Box>
            <hr />
            <Box sx={sx.row}>
              <Box>Remaining Due Amount</Box>
              <MoneyFormat value={remainingAmount} />
            </Box>
            <hr />
        </DialogContentText>
        <FormControl sx={sx.radioGroup}>
          <FormLabel>Pay Method</FormLabel>
          <RadioGroup
            defaultValue="balance"
            name="method"
            onChange={handleFieldChange}
          >
            <FormControlLabel
              value="balance"
              control={<Radio id="balance"/>}
              label={
                <Box sx={sx.radioLabel}>
                  <Box>Current Balance</Box>
                  <Box sx={sx.secondary}>
                    This will deduct the money from the balance ($ {prepareData.balance})
                  </Box>
                </Box>
              }
            />
            <FormControlLabel
              value="new"
              control={<Radio id="new"/>}
              label={
                <Box sx={sx.radioLabel}>
                  <Box>New Payment</Box>
                  <Box sx={sx.secondary}>
                    This will add the money to the balance and then deduct from it
                  </Box>
                </Box>
              }
            />
          </RadioGroup>
        </FormControl>
        <TextField
          sx={sx.textField}
          error={checkError('amount')}
          helperText={getError('amount')}
          id="amount"
          label="Amount"
          type="number"
          onChange={handleFieldChange}
          value={fields['amount']}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
      </DialogContent>
      <DialogActions style={{ flex: 'space-around' }}>
        <Button onClick={handleSave} sx={sx.dialogBtn} variant="contained">
          Pay
        </Button>
        <Button onClick={() => onHideHandler(false)} sx={sx.dialogBtn} variant="outlined">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
