import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import { strToNum } from '../../helpers/index.js';
import { ajaxSaveAdminProductOption } from '../../services/adminService';
import ProductOptionCodeAutocomplete from '../../components/ProductOptionCodeAutocomplete';
import StyledMainGrid from '../../components/StyledMainGrid';

export default function ProductOptionDialog({ onHide, productId, show, dialogTitle, dialogText, dialogNoText, data, editMode }) {
  const dialogButtonStyle = {
    padding: '4px 30px'
  };

  const initialValues = { type: 'boolean', title: '', price: 0, option_code_id: '', max_quantity: 0, fixed_value: 0, free_bundle: false };

  const [fields, setFields] = useState(() => {
    return data ? { type: data['type'], title: data['title'], price: data['price'], option_code_id: data['option_code_id'], max_quantity: data['max_quantity'], fixed_value: data['fixed_value'], free_bundle: data['free_bundle'] ? true : false } : { ...initialValues };
  });

  const [errors, setErrors] = useState(false);

  const handleOptionTypeChange = (event, value) => {
    if (value) {
      setFields({ ...fields, type: value.value });
    }
  };

  const handleFieldChange = (event) => {
    const value = event.target.value;

    switch (event.target.id) {
      case 'title':
        setFields({ ...fields, title: value });
        break;
      case 'price':
        setFields({ ...fields, price: strToNum(value) });
        break;
      case 'maxqty':
        setFields({ ...fields, max_quantity: strToNum(value) });
        break;
      case 'fixed_value':
        setFields({ ...fields, fixed_value: strToNum(value) });
        break;
      case 'free_bundle':
        setFields({ ...fields, free_bundle: event.target.checked });
        break;
      default:
        break;
    }
  };

  const handleOnChangeOptionCode = (event, value) => {
    let optionCode = value ? value.code : '';
    let optionCodeId = value ? value.id : '';
    let optionTitle = value ? value.label : fields['title'];

    const predefineFieldsList = [
      { code: 'snmpv3', type: 'boolean' },
      { code: 'vpn',    type: 'boolean' },
      { code: 'ipv6',   type: 'boolean' },
      { code: 'radius', type: 'boolean' },
      { code: 'tacacs', type: 'boolean' },
      { code: 'noti',   type: 'boolean' },
      { code: 'hbeats', type: 'boolean' },
      { code: 'map',    type: 'boolean' },
      { code: 'graph',  type: 'boolean' },
      { code: 'spxn_dry10', type: 'boolean' },
      { code: 'spxn_relays',type: 'boolean' },
      { code: 'spxn_vdc',   type: 'boolean' },
      { code: 'sp1plus_temp', type: 'boolean' },
      { code: 'sp1plus_dcmr', type: 'boolean' },
      { code: 'pms',   type: 'integer', max_quantity: 4 },
      { code: '5dc',   type: 'integer', max_quantity: 4 },
      { code: 'access', type: 'fixed', fixed_value: 4 },
      { code: 'sensors',type: 'fixed', fixed_value: 1 },
      { code: 'vsens', type: 'integer_fixed', fixed_value: 5, max_quantity: 14 }
    ];

    let newFields = {...fields};
    let filterPredefineFieldsList = predefineFieldsList.filter(p => p.code === optionCode);
    if ( filterPredefineFieldsList.length > 0 ) {
      let predefineFields = filterPredefineFieldsList[0];
      delete predefineFields.code;
      newFields = {...newFields, ...predefineFields};
    }
    setFields({ ...newFields, option_code_id: optionCodeId, title: optionTitle });
  };

  const getError = (fieldId) => {
    return errors && errors[fieldId];
  };

  const isError = (fieldId) => {
    if (errors && errors[fieldId]) {
      return true;
    } else return false;
  };

  const handleSave = () => {
    let hasError = false,
      newErrors = {};

    if (!fields['title']) {
      newErrors['title'] = 'Required';
      hasError = true;
    }

    if (typeof fields['price'] !== 'number') {
      newErrors['price'] = 'Required';
      hasError = true;
    }

    if (!fields['option_code_id']) {
      newErrors['option_code_id'] = 'Required';
      hasError = true;
    }

    if (( fields['type'] === 'integer' || fields['type'] === 'integer_fixed' ) && fields['max_quantity'] <= 0) {
      newErrors['max_quantity'] = 'Only positive numbers';
      hasError = true;
    }

    if (fields['type'] === 'fixed') {
      if (!fields['fixed_value']) {
        newErrors['fixed_value'] = 'You have to input the fixed value';
        hasError = true;
      } else if (fields['fixed_value'] <= 0) {
        newErrors['fixed_value'] = 'Only positive numbers';
        hasError = true;
      }
    }

    if (fields['type'] === 'integer_fixed') {
      if (!fields['fixed_value']) {
        newErrors['fixed_value'] = 'You have to input the pack value';
        hasError = true;
      } else if (fields['fixed_value'] <= 0) {
        newErrors['fixed_value'] = 'Only positive numbers';
        hasError = true;
      }
    }

    if (hasError) {
      setErrors(newErrors);
      return;
    }

    let params = {
      product_code_id: productId,
      title: fields['title'],
      max_quantity: fields['max_quantity'],
      price: fields['price'],
      type: fields['type'],
      option_code_id: fields['option_code_id'],
      fixed_value: fields['fixed_value'],
      free_bundle: fields['free_bundle'] ? 1 : 0
    };

    if (data && data.id) {
      params.id = data.id;
    }

    ajaxSaveAdminProductOption(params)
      .then((res) => {
        // const { data, } = res;
        onHideHandler({ button: 'save' });
      })
      .catch(() => {});
  };

  const onHideHandler = (params) => {
    setErrors(false);
    onHide(params);
  };

  const optionTypes = [
    { label: 'Checkbox', value: 'boolean' },
    { label: 'Checkbox with constant value', value: 'fixed' },
    { label: 'Number Input', value: 'integer' },
    { label: 'Number Input x Number of Packs', value: 'integer_fixed' }
  ];

  return (
    <Dialog fullWidth PaperProps={{ style: { backgroundColor: 'white', color: 'black' } }} onClose={() => onHideHandler({ button: 'cancel' })} open={show}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <StyledMainGrid>
          <Grid item xs={12} />
          <Grid item xs={12}>
            <ProductOptionCodeAutocomplete disabled={!!editMode} error={isError('option_code_id')} helperText={getError('option_code_id')} id="option_code_id" onChange={handleOnChangeOptionCode} value={fields['option_code_id']} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={isError('title')}
              helperText={getError('title')}
              autoFocus
              id="title"
              label="Title"
              type="text"
              value={fields['title']}
              onChange={handleFieldChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={isError('price')}
              helperText={getError('price')}
              label="MSRP ($ USD)"
              type="number"
              id="price"
              value={fields['price'].toString()}
              onChange={handleFieldChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              disablePortal
              disabled={!!editMode}
              options={optionTypes}
              value={optionTypes.find((item) => item.value === fields['type'])}
              onChange={handleOptionTypeChange}
              renderInput={(params) =>
                <TextField
                  {...params}
                  label="Option type"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              }
            />
          </Grid>
          {
            (fields['type'] === 'integer' || fields['type'] === 'integer_fixed') &&
            <Grid item xs={12}>
              <TextField
                error={isError('max_quantity')}
                helperText={getError('max_quantity')}
                label="Max Qty"
                type="number"
                onChange={handleFieldChange}
                id="maxqty"
                value={fields['max_quantity']}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          }
          {
            fields['type'] === 'fixed' &&
            <Grid item xs={12}>
              <TextField
                disabled={!!editMode}
                error={isError('fixed_value')}
                helperText={getError('fixed_value')}
                label="Constant Value"
                type="number"
                onChange={handleFieldChange}
                id="fixed_value"
                value={fields['fixed_value']}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          }
          {
            fields['type'] === 'integer_fixed' &&
            <Grid item xs={12}>
              <TextField
                disabled={!!editMode}
                error={isError('fixed_value')}
                helperText={getError('fixed_value')}
                label="Number per Packs"
                type="number"
                onChange={handleFieldChange}
                id="fixed_value"
                value={fields['fixed_value']}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          }
          {
            (fields['type'] === 'boolean' || fields['type'] === 'fixed') &&
            <Grid item xs={12}>
              <FormControlLabel
                disabled={!!editMode}
                control={<Checkbox id="free_bundle"/>}
                onChange={handleFieldChange}
                checked={fields['free_bundle']}
                label="Free Bundle"
              />
            </Grid>
          }
        </StyledMainGrid>
      </DialogContent>
      <DialogActions style={{ flex: 'space-around' }}>
        <Button onClick={handleSave} style={dialogButtonStyle} variant="contained">
          Save
        </Button>
        <Button onClick={() => onHideHandler({ button: 'cancel' })} style={dialogButtonStyle} variant="outlined">
          {dialogNoText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
