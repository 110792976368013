import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import PaymentIcon from '@mui/icons-material/Payment';
import AdminButtons from './AdminButtons';
import StyledCard from '../../components/StyledCard'
import { toDateText } from '../../helpers';
import { MoneyFormat } from '../../components/MoneyFormat';

export default function Payments({ companyId, companyInfo, handleCompanyUpdate }) {
  const showDistributorPaymentLocalCurrency = !!window.SHOW_DIST_PAY_LOCAL_CUR;
  return (
    <StyledCard
      title="Payments"
      titleIcon={PaymentIcon}
    >
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Box>
            <Typography>Due date of the next payment</Typography>
            <Typography>{toDateText(companyInfo['due_date'], true)}</Typography>
          </Box>
          <Box sx={{ mt: '15px' }}>
            <Typography>Total Due</Typography>
            <Typography>
              {
                showDistributorPaymentLocalCurrency ?
                <MoneyFormat currency={companyInfo.currency} value={companyInfo.credit_used_local} />
                :
                <MoneyFormat value={companyInfo.credit_used} />
              }
            </Typography>
          </Box>
        </Box>
        {window.IS_ADMIN && companyId && <AdminButtons companyId={companyId} handleCompanyUpdate={handleCompanyUpdate} />}
      </CardContent>
    </StyledCard>
  );
}
