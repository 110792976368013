import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { ajaxReceivePayment } from '../../services/paymentService';
import validator from 'validator';

export default function ReceivePaymentDialog({ onHide, companyId, show, dialogTitle, dialogText, dialogNoText }) {
  const dialogButtonStyle = {
    padding: '4px 30px'
  };

  const initialFieldsValues = { amount: 0 };
  const [fields, setFields] = useState({ ...initialFieldsValues });
  const [errors, setErrors] = useState(false);

  const onHideHandler = (params) => {
    setErrors(false);
    setFields({ ...initialFieldsValues });
    onHide(params);
  };

  const handleFieldChange = (event) => {
    const value = event.target.value;
    const valueNum = value !== '' ? Number(value) : NaN;

    switch (event.target.id) {
      case 'amount':
        setFields({ ...fields, amount: isNaN(valueNum) ? value : valueNum });
        break;
      default:
        break;
    }
  };

  const getError = (fieldId) => {
    return errors && errors[fieldId];
  };

  const checkError = (fieldId) => {
    return errors && errors[fieldId] ? true : false;
  };

  const validateFields = () => {
    let hasError = false,
      newErrors = {};

    let key = 'amount';
    let fieldValue = fields[key] ? fields[key] + '' : '';

    if (validator.isEmpty(fieldValue, { ignore_whitespace: true })) {
      newErrors[key] = 'required field';
      hasError = true;
    }

    if (hasError) {
      setErrors(newErrors);
    }

    return !hasError;
  };

  const handleSave = () => {
    if (validateFields()) {
      let params = {
        companyId: companyId,
        amount: fields['amount']
      };

      ajaxReceivePayment(params)
        .then((res) => {
          onHideHandler({ button: 'save' });
        })
        .catch((e) => {
        });
    }
  };

  return (
    <Dialog fullWidth PaperProps={{ style: { backgroundColor: 'white', color: 'black' } }} onClose={() => onHideHandler({ button: 'cancel' })} open={show}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText color="inherit">{dialogText}</DialogContentText>
        <TextField
          sx={{ mt: '15px' }}
          error={checkError('amount')}
          helperText={getError('amount')}
          id="amount"
          label="Amount"
          type="number"
          onChange={handleFieldChange}
          value={fields['amount']}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
      </DialogContent>
      <DialogActions style={{ flex: 'space-around' }}>
        <Button onClick={handleSave} style={dialogButtonStyle} variant="contained">
          Save
        </Button>
        <Button onClick={() => onHideHandler({ button: 'cancel' })} style={dialogButtonStyle} variant="outlined">
          {dialogNoText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
