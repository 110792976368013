import React, { useState, useEffect, useContext } from 'react';
import IconButton from '@mui/material/IconButton';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import CardContent from '@mui/material/CardContent';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import { ajaxGetShipments, ajaxMarkShipmentReceived } from '../../services/shipmentService';
import StyledList from '../../components/StyledList';
import StyledCard from '../../components/StyledCard'
import ConfirmationButton from '../../components/ConfirmationButton';
import { AuthContext } from '../../context';
import { downloadFile, toDateText } from '../../helpers';

export default function IncomingShipments(props) {
  const [shipments, setShipments] = useState([]);
  const { inMemoryUser } = useContext(AuthContext);
  useEffect(() => {
    let id = props.companyId ? props.companyId : '';

    ajaxGetShipments(id)
      .then((res) => {
        const { data } = res;
        setShipments(data);
      })
      .catch(() => {
        setShipments([]);
      });
  }, [props.companyId]);

  const handleReceivedShipment = (id) => {
    ajaxMarkShipmentReceived(id)
      .then((res) => {
        const { data } = res;
        setShipments(data);
      })
      .catch(() => {
        setShipments([]);
      });
  };

  const popupButtonStyle = {
    height: '30px',
    width: '60px',
    fontSize: '12px',
    marginLeft: '10px'
  };

  const renderItem = (data, idx) => {
    return (
      <ListItem
        key={idx}
        disablePadding
        secondaryAction={
          <React.Fragment>
            <Tooltip title={'Packing List of ' + data.awb}>
              <IconButton
                onClick={() =>
                  downloadFile({
                    params: { id: data.id, fileType: 'packing_list', role: inMemoryUser.getUserRole() },
                    fileName: data.awb + '_packing_list',
                    fileExt: 'pdf'
                  })
                }
              >
                <LocalShippingIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={'Shipping Invoice of ' + data.awb}>
              <IconButton
                onClick={() =>
                  downloadFile({
                    params: { id: data.id, fileType: 'invoice', role: inMemoryUser.getUserRole() },
                    fileName: data.awb + '_invoice',
                    fileExt: 'pdf'
                  })
                }
              >
                <RequestQuoteIcon />
              </IconButton>
            </Tooltip>
            {
              !inMemoryUser.isAdmin()
              &&
              <ConfirmationButton
                buttonText="Accept"
                buttonProps={{
                  variant: 'contained',
                  style: popupButtonStyle
                }}
                handleDialogClickYes={() => handleReceivedShipment(data.id)}
                dialogText="Are you sure you wish to confirm receipt of this shipment and transfer goods to your inventory?"
                dialogYesText="Yes"
                dialogNoText="No"
              />
            }
          </React.Fragment>
        }
      >
        <ListItemButton>
          <ListItemText
            primary={`AWB# ${data.awb}`}
            secondary={
              <Tooltip title={`Dispatched to warehouse ${toDateText(data.dispatched_date)}`}>
                <Box component="span" sx={{ position: 'relative' }}>
                  {toDateText(data.dispatched_date)}
                </Box>
              </Tooltip>
            }
          />
        </ListItemButton>
      </ListItem>
    );
  };

  return (
    <StyledCard
      title="Incoming Shipments"
      titleIcon={LocalShippingIcon}
    >
      <CardContent>
        <StyledList
          dataSource={shipments}
          renderItem={renderItem}
        />
      </CardContent>
    </StyledCard>
  );
}
