import React, { useEffect, useState, useCallback } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import ConfirmationButton from '../components/ConfirmationButton';
import {
  ajaxGetCompanyProductOptionAutocompleteDiscount,
  ajaxGetCompanyProductOptionDiscounts,
  ajaxSaveCompanyProductOptionDiscount,
  ajaxDeleteCompanyProductOptionDiscount
} from '../services/adminService';
import StyledCard from '../components/StyledCard'
import StyledList from '../components/StyledList';
import StyledSectionHeader from '../components/StyledSectionHeader';
import StyledCardHeaderContent from '../components/StyledCardHeaderContent';
import StyledCardFormContent from '../components/StyledCardFormContent';

export default function DistributorProductOptionDiscounts(props) {
  const [discountItems, setDiscountItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [addData, setAddData] = useState({});
  const [autocompleteItems, setAutocompleteItems] = useState([]);

  const onLoadData = useCallback(() => {
    ajaxGetCompanyProductOptionDiscounts({ companyId: props.companyId })
      .then((res) => {
        const { data } = res;
        setDiscountItems(data.map(d => ({...d, optionLabel: d.product_code ? `${d.product_code} ${d.label}` : d.label})));
        setIsLoading(false);
        setIsProcessing(false);
        setShowAdd(false);
        setAddData({});
      })
      .catch(() => {
        setDiscountItems([]);
        setIsLoading(false);
        setIsProcessing(false);
        setShowAdd(false);
        setAddData({});
      });
  }, [props.companyId]);

  const onAdd = useCallback(() => {
    if ( addData.discountError || !addData.option_code_id ) {
      return;
    }

    setIsProcessing(true);
    ajaxSaveCompanyProductOptionDiscount({
      company_id: props.companyId,
      product_code_id: addData.product_code_id,
      option_code_id: addData.option_code_id,
      discount: parseFloat(addData.discount)
    })
      .then((res) => {
        onLoadData();
      })
      .catch(() => {
        onLoadData();
      });
  }, [props.companyId, addData, onLoadData]);

  const onDelete = (deleteId) => {
    setIsProcessing(true);
    ajaxDeleteCompanyProductOptionDiscount({ id: deleteId })
      .then((res) => {
        onLoadData();
      })
      .catch(() => {
        onLoadData();
      });
  };

  const onAddRow = () => {
    setShowAdd(true);
    setAddData({});

    ajaxGetCompanyProductOptionAutocompleteDiscount()
      .then((res) => {
        const { data } = res;
        setAutocompleteItems(data);
      })
      .catch(() => {
        setAutocompleteItems([]);
      });
  };

  const onAddClose = () => {
    setShowAdd(false);
    setAddData({});
  };

  const renderItem = (data, idx) => {
    return (
      <ListItem
        key={idx}
        disablePadding
        secondaryAction={
          <ConfirmationButton
            id={idx}
            buttonProps={{
              variant: 'contained',
              style: {
                height: '30px',
                width: '60px',
                fontSize: '12px'
              },
              sx:{ backgroundColor: '#c62828' },
              disabled: isProcessing
            }}
            handleDialogClickYes={() => onDelete(data.id)}
            buttonText="Delete"
            dialogText="Are you sure you want to delete this item?"
            dialogYesText="Yes"
            dialogNoText="No"
          />
        }
      >
        <ListItemButton>
          <ListItemText
            primary={
              <Box sx={{ display: { xs: 'block', sm: 'flex' } }}>
                <Box sx={{ mr: '10px' }}>{data.optionLabel}</Box>
                <Chip label={`Discount ${data.discount}%`} size="small" variant="outlined" />
              </Box>
            }
          />
        </ListItemButton>
      </ListItem>
    );
  };

  const onProductOptionChange = (event, value) => {
    let optionId = null;
    let prodId = null;
    if ( value ) {
      optionId = value['id'];
      prodId = value['product_code_id'];
    }
    setAddData({
      ...addData,
      product_code_id: prodId,
      option_code_id: optionId
    })
  };

  const onDiscount = (e) => {
    let value = e.target.value.replace(/\D/g, '');
    let discountError = null;
    let discountNum = parseFloat(value);
    if ( isNaN(discountNum) || discountNum < 0 || discountNum > 100 ) {
      discountNum = e.target.value;
      discountError = 'Please enter a value between 0 and 100.';
    }
    setAddData({
      ...addData,
      discount: value,
      discountError: discountError
    });
  };

  useEffect(() => {
    setIsLoading(true);
    onLoadData();
  }, [props.companyId, onLoadData]);

  return (
    isLoading ?
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <CircularProgress color="info" size={30} thickness={5} />
    </div>
    :
    <React.Fragment>
      <StyledCard>
        <StyledCardHeaderContent sx={{ mb: { sm: '-72px' } }}>
          <StyledSectionHeader>
            Product Option Discount
          </StyledSectionHeader>
        </StyledCardHeaderContent>
        <StyledCardFormContent>
          <StyledList
            addRow
            onAdd={onAddRow}
            dataSource={discountItems}
            renderItem={renderItem}
            sort={{
              sortKey: 'optionLabel',
              sortOptions: [
                {
                  key: 'optionLabel',
                  label: 'Product Option'
                },
                {
                  key: 'discount',
                  label: 'Discount'
                }
              ]
            }}/>
        </StyledCardFormContent>
      </StyledCard>
      {
        showAdd &&
        <Dialog
          PaperProps={{ sx: { backgroundColor: 'white', color: 'black', minHeight: { sm: '350px' } } }}
          onClose={onAddClose}
          maxWidth="sm"
          fullWidth
          open
        >
          <DialogContent>
            <DialogContentText color="inherit" sx={{ mb: '10px', fontSize: '1.5rem', fontWeight: 'bold' }}>
              Add Product Discount
            </DialogContentText>
            <Grid container direction="row" rowSpacing="10px">
                <Grid item xs={12}>
                  <Autocomplete
                    disablePortal
                    getOptionDisabled={(p) => discountItems.filter(d => d.product_code_id === p.product_code_id && d.option_code_id === p.id).length > 0}
                    options={autocompleteItems}
                    getOptionLabel={(option) => (option.product_code_id ? `${option.product_code} ${option.label}` : option.label)}
                    onChange={onProductOptionChange}
                    fullWidth
                    ListboxProps={{ style: { maxHeight: '150px' } }}
                    renderInput={(params) => <TextField {...params} label="Product Option" />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label={addData.discountError ? addData.discountError : 'Discount %'}
                    name="discount"
                    variant="outlined"
                    value={addData.discount}
                    onChange={onDiscount}
                    error={addData.discountError}
                  />
                </Grid>
              </Grid>
          </DialogContent>
          <DialogActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div>
              <Button
                style={{padding: '4px 30px'}}
                variant="contained"
                onClick={onAdd}
                disabled={isProcessing}
                startIcon={
                  isProcessing ?
                  <CircularProgress size={30} thickness={5}/>
                  :
                  undefined
                }
              >
                Add
              </Button>
            </div>
            <div>
              <Button
                style={{padding: '4px 30px'}}
                variant="outlined"
                onClick={onAddClose}
                disabled={isProcessing}
              >
                Close
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      }
    </React.Fragment>
  );
}
