import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import FeedbackIcon from '@mui/icons-material/Feedback';
import CardContent from '@mui/material/CardContent';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Chip from '@mui/material/Chip';
import { ajaxGetReplacementCompany } from '../../services/adminService';
import StyledList from '../../components/StyledList';
import StyledCard from '../../components/StyledCard'

export default function AdminReplacements(props) {
  const [companyList, setCompanyList] = useState([]);

  const loadData = () => {
    ajaxGetReplacementCompany()
      .then((res) => {
        const { data } = res;
        setCompanyList(data);
      })
      .catch(() => {
        setCompanyList([]);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const renderItem = (data, idx) => {
    return (
      <ListItem
        key={idx}
        disablePadding
        secondaryAction={
          <Chip
            label={data.replacement}
            color="error"
            component={RouterLink}
            to={'/dashboard/distributor/' + data.id}
          />
        }
      >
        <ListItemButton
          component={RouterLink}
          to={'/dashboard/distributor/' + data.id}
        >
          <ListItemText primary={data.company_name} />
        </ListItemButton>
      </ListItem>
    );
  };

  return (
    <StyledCard
      title="Awaiting Approval"
      titleIcon={FeedbackIcon}
    >
      <CardContent>
        <StyledList
          dataSource={companyList}
          renderItem={renderItem}
        />
      </CardContent>
    </StyledCard>
  );
}
