import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import {ajaxGetPayment} from '../../services/paymentService';
import StyledSearchDate from '../../components/StyledSearchDate';
import PaymentHeader from './PaymentHeader';
import StyledMainGrid from '../../components/StyledMainGrid';
import { MoneyFormat } from '../../components/MoneyFormat';
import { toDateTimeText } from '../../helpers';
import { ajaxGetAdminDistributors } from '../../services/adminService';
import { ajaxGetCompanyInfo } from '../../services/companyService';
import Page from '../../components/Page';
import StyledCard from '../../components/StyledCard'
import StyledCardFormContent from '../../components/StyledCardFormContent';
import StyledList from '../../components/StyledList';

export default function Payment(props) {
  const showDistributorPaymentLocalCurrency = !!window.SHOW_DIST_PAY_LOCAL_CUR;
  const [items, setItems] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [distributor, setDistributor] = useState(null);
  const [companyInfo, setCompanyInfo] = useState(false);
  const navigate = useNavigate();
  const incomingParams = useParams();
  const isAdmin = window.IS_ADMIN;
  const paramCompanyId = (incomingParams && incomingParams.CompanyId ? incomingParams.CompanyId : -1);

  const renderItem = (data, idx, secondarySx) => {
    return (
      <ListItem
        key={idx}
        disablePadding
      >
        <ListItemButton>
          <ListItemText
            primary={
              showDistributorPaymentLocalCurrency ?
              <MoneyFormat currency={data.currency} value={data.amount_local} />
              :
              <MoneyFormat value={data.amount} />
            }
            secondary={toDateTimeText(data.date)}
          />
        </ListItemButton>
      </ListItem>
    );
  };

  useEffect(() => {
    let params = {};

    if (paramCompanyId !== -1) {
      params.companyId = paramCompanyId;
    }
    ajaxGetPayment(params)
      .then((res) => {
        const { data } = res;
        setItems(data);
      })
      .catch(() => {
        setItems([]);
      });

    if ( isAdmin && params.companyId ) {
      ajaxGetAdminDistributors({ CompanyId: params.companyId })
        .then((res) => {
          const { data } = res;
          setDistributor(data);
        })
        .catch(() => {
          setDistributor(null);
        });
    }
    else {
      ajaxGetCompanyInfo()
      .then((res) => {
        const { data } = res;
        setCompanyInfo(data);
      })
      .catch(() => {
        setCompanyInfo(false);
      });
    }
  }, [paramCompanyId, isAdmin]);

  useEffect(() => {
    const results = items.filter((item) => {
      let testStartDate = true;
      let testEndDate = true;

      let dateKey = 'date';
      if (startDate) {
        testStartDate = item[dateKey] >= Math.floor(new Date(startDate).getTime() / 1000);
      }
      if (endDate) {
        testEndDate = item[dateKey] <= Math.floor(new Date(endDate).getTime() / 1000);
      }
      return testStartDate && testEndDate;
    });

    setSearchResults(results);
  }, [items, startDate, endDate]);

  return (
    <Page
      title={[
        ...(
          distributor ?
          [distributor.company_name]
          :
          []
        ),
        'View Payment'
      ]}
      backButton={
        distributor ?
        <IconButton color="primary" onClick={() => navigate(-1)}>
          <ArrowBackIcon fontSize="large" sx={{ mt: '3px' }}/>
        </IconButton>
        :
        undefined
      }
    >
      <StyledCard>
        <StyledCardFormContent sx={{ mb: { sm: '-72px' } }}>
          <StyledMainGrid>
            <Grid xs={12} item>
              <StyledSearchDate startDate={startDate} endDate={endDate} onStartDate={setStartDate} onEndDate={setEndDate} />
            </Grid>
            <Grid xs={12} item>
              <PaymentHeader items={searchResults} sold={props.sold} currency={companyInfo ? companyInfo.currency : null}/>
            </Grid>
          </StyledMainGrid>
        </StyledCardFormContent>
        <StyledCardFormContent>
          <StyledList
            noEmptyRow
            dataSource={searchResults}
            renderItem={renderItem}
            sort={{
              sort: 'desc',
              sortKey: 'date',
              sortOptions: [
                {
                  key: 'date',
                  label: 'Received payment'
                },
                {
                  key: 'amount',
                  label: 'Amount'
                }
              ]
            }}/>
        </StyledCardFormContent>
      </StyledCard>
    </Page>
  );
}
