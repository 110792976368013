import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { downloadFile } from '../../helpers';

export default function SidebarFooter() {
  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        justifyContent: 'flex-end',
        flexDirection: 'column'
      }}
    >
      <Button
        onClick={() =>
          downloadFile({
            params: { id: 1, fileType: 'agreement', role: 'distributor' },
            fileName: 'agreement',
            fileExt: 'pdf'
          })
        }
        color="inherit"
      >
        Download Distributor Agreement
      </Button>
    </Box>
  );
}
