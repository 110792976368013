import React, { useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FileIcon from '@mui/icons-material/Download';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import {ajaxGetAdminFirmwares, ajaxDeleteAdminFirmware} from '../services/adminService';
import {ajaxDownloadFirmwareFile} from '../services/fileService';
import ConfirmationButton from '../components/ConfirmationButton';
import Page from '../components/Page';
import StyledCard from '../components/StyledCard'
import StyledCardSearch from '../components/StyledCardSearch';
import StyledList from '../components/StyledList';
import StyledCardFormContent from '../components/StyledCardFormContent';

export default function FirmwareList() {
  const [items, setItems] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [searchResults, setSearchResults] = React.useState([]);
  const navigate = useNavigate();
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));

  const renderItem = (data, idx, secondarySx) => {
    return (
      <ListItem
        key={idx}
        disablePadding
        secondaryAction={
          <React.Fragment>
            <Tooltip title="Download">
              <IconButton
                onClick={() =>
                  ajaxDownloadFirmwareFile(data.id, `fw-${data.version}.bin`)
                }
              >
                <FileIcon />
              </IconButton>
            </Tooltip>
            <IconButton
              component={RouterLink}
              to={'/dashboard/firmware/edit/' + data.id}
            >
              <EditOutlinedIcon />
            </IconButton>
            <ConfirmationButton
              buttonProps={{
                variant: 'contained',
                style: {
                  height: '30px',
                  width: '60px',
                  fontSize: '12px'
                },
                sx:{
                  backgroundColor: '#c62828',
                  ml: '10px'
                }
              }}
              handleDialogClickYes={() => handleDeleteFirmware(data.id)}
              buttonText="Delete"
              dialogText="Are you sure you want to delete this firmware?"
              dialogYesText="Confirm"
              dialogNoText="Cancel"
            />
          </React.Fragment>
        }
      >
        <ListItemButton component={RouterLink} to={'/dashboard/firmware/edit/' + data.id}>
          <ListItemText
            primary={
              <Box sx={{ display: 'flex' }}>
                {`HW ID ${data.hwid}`}
                {
                  smUp &&
                  <Chip label={`Version ${data.version}`} size="small" variant="outlined" sx={{ ml: '10px' }} />
                }
              </Box>
            }
            secondary={
              <Box sx={{ maxWidth: '50%' }}>
                {
                  smUp ?
                  data.description
                  :
                  `Version ${data.version}`
                }
              </Box>
            }
          />
        </ListItemButton>
      </ListItem>
    );
  };

  const searchChangeHandle = (event) => {
    const new_value = event.target.value.toLowerCase();
    setSearchTerm(new_value);
  };

  const getData = () => {
    const params = {};

    ajaxGetAdminFirmwares(params)
      .then((res) => {
        const { data } = res;
        setItems(data);
      })
      .catch(() => {
        setItems([]);
      });
  };

  const handleDeleteFirmware = (id) => {
    ajaxDeleteAdminFirmware({id: id}).then((res) => {
      getData();
    }).catch(() => {
      
    });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const results = items.filter((item) => `${(item.hwid || '')}${(item.version || '')}${(item.description || '')}`.toLowerCase().includes(searchTerm));

    setSearchResults(results);
  }, [searchTerm, /*searchFilter,*/ items]);

  return (
    <Page title="Add Firmware">
      <StyledCard>
        <StyledCardSearch onSearch={searchChangeHandle} />
        <StyledCardFormContent>
          <StyledList
            addRow
            onAdd={() => navigate('/dashboard/firmware/add')}
            dataSource={searchResults}
            renderItem={renderItem}
            sort={{
              sortKey: 'hwid',
              sortOptions: [
                {
                  key: 'hwid',
                  label: 'HW ID'
                },
                {
                  key: 'version',
                  label: 'Version'
                },
                {
                  key: 'description',
                  label: 'Description'
                }
              ]
            }}/>
        </StyledCardFormContent>
      </StyledCard>
    </Page>
  );
}
