import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import AvailbleCredit from './AvailbleCredit';
import SummarySales from './SummarySales';
import Payments from './Payments';
import IncomingShipments from './IncomingShipments';
import ReplacementProducts from './ReplacementProducts';
import Invoice from './Invoice';
import AdminDistributorAutocomplete from '../../components/AdminDistributorAutocomplete';
import StyledMainGrid from '../../components/StyledMainGrid';
import { ajaxGetCompanyInfo } from '../../services/companyService';
import Page from '../../components/Page';

export default function Dashboard() {
  const navigate = useNavigate();
  const incomingParams = useParams();
  const [companyId, setCompanyId] = React.useState(false);
  const [companyInfo, setCompanyInfo] = useState(false);
  const [needCompanyInfoReload, setNeedCompanyInfoReload] = useState(false);

  useEffect(() => {
    let data = [];

    if (companyId) {
      data.companyId = companyId;
    }

    if (!window.IS_ADMIN || (window.IS_ADMIN && companyId)) {
      ajaxGetCompanyInfo(data)
        .then((res) => {
          const { data } = res;
          setCompanyInfo(data);
        })
        .catch(() => {
          setCompanyInfo(false);
        });
    }
  }, [companyId]);

  useEffect(() => {
    if (needCompanyInfoReload) {
      let data = [];

      if (companyId) {
        data.companyId = companyId;
      }

      if (!window.IS_ADMIN || (window.IS_ADMIN && companyId)) {
        ajaxGetCompanyInfo(data)
          .then((res) => {
            const { data } = res;
            setCompanyInfo(data);
            setNeedCompanyInfoReload(false);
          })
          .catch(() => {
            setCompanyInfo(false);
            setNeedCompanyInfoReload(false);
          });
      }
    }
  }, [needCompanyInfoReload, companyId]);

  const handleCompanySearch = (companyId) => {
    setCompanyInfo(false);
    setCompanyId(companyId);
    navigate(`/dashboard/distributor/${companyId}`);
  };

  const handleCompanyUpdate = () => {
    setNeedCompanyInfoReload(true);
  };

  return (
    <Page
      title={[
        window.IS_ADMIN ? 'Distributor' : 'Overview',
        ...(
          companyInfo ?
          [companyInfo.company_name]
          :
          []
        ),
      ]}
    >
      <StyledMainGrid columnSpacing alignItems="flex-start" sx={{ pb: '40px' }}>
        {
          window.IS_ADMIN &&
          <Grid item xs={12}>
            <AdminDistributorAutocomplete defaultCompanyId={incomingParams.CompanyId} setSearchResult={handleCompanySearch} sx={{ width: '100%' }} />
          </Grid>
        }

        {companyInfo && !needCompanyInfoReload && (
          <React.Fragment>

            <Grid container item sm={12} md={6} rowSpacing="22px">
              <Grid item xs={12}>
                <SummarySales companyId={companyId} />
              </Grid>
              <Grid item xs={12}>
                <AvailbleCredit companyInfo={companyInfo} />
              </Grid>
              <Grid item xs={12}>
                <Payments companyId={companyId} companyInfo={companyInfo} handleCompanyUpdate={handleCompanyUpdate}/>
              </Grid>
            </Grid>
            <Grid container item sm={12} md={6} rowSpacing="22px">
              {
                window.IS_ADMIN && companyId &&
                <Grid item xs={12}>
                  <Invoice companyId={companyId} companyName={companyInfo['company_name']} />
                </Grid>
              }
              <Grid item xs={12}>
                <IncomingShipments companyId={companyId} />
              </Grid>
              <Grid item xs={12}>
                <ReplacementProducts companyId={companyId} />
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </StyledMainGrid>
    </Page>
  );
}
