import React from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import Box from '@mui/material/Box';
import GlobalStyles from '@mui/material/GlobalStyles';
import CardContent from '@mui/material/CardContent';
import StyledCard from '../../components/StyledCard'
import { MoneyFormat } from '../../components/MoneyFormat';

export default function AvailbleCredit({ companyInfo }) {
  const creditUsed = (companyInfo ? (window.IS_ADMIN ? companyInfo.credit_used : companyInfo.credit_used_local) : 0);
  const creditLimit = (companyInfo ? (window.IS_ADMIN ? companyInfo.credit_limit : companyInfo.credit_limit_local) : 0);
  const d = Number(creditLimit) > 0 ? Number(creditUsed) / Number(creditLimit) : 0;
  const f = 360;
  const data = [
    { name: 'Limit', value: f - f * d },
    { name: 'Used', value: f * d }
  ];
  const COLORS = ['#F2F2F2', '#29ABE2'];
  return (
    <StyledCard
      title="Credit Available"
      titleIcon="$"
    >
      {
        companyInfo &&
        <React.Fragment>
          <Box sx={{ display: 'flex', justifyContent: 'center', minHeight: '250px' }}>
            <GlobalStyles styles={{ '.recharts-wrapper.available-credit svg': { overflow: 'inherit!important' } }} />
            <ResponsiveContainer width="100%" height={250}>
              <PieChart className="available-credit">
                <Pie
                  data={data}
                  dataKey="value"
                  startAngle={-270}
                  endAngle={270}
                  fill="#29ABE2"
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </Box>
          <CardContent sx={{ pt: 0, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <MoneyFormat currency={window.IS_ADMIN ? null : companyInfo.currency} suffix=" Used" value={creditUsed} />
            <MoneyFormat currency={window.IS_ADMIN ? null : companyInfo.currency} suffix=" Credit" value={creditLimit} />
          </CardContent>
        </React.Fragment>
      }
    </StyledCard>
  );
}
