import React, { useContext, useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CopyToClipboard } from "react-copy-to-clipboard";
import KeyIcon from '@mui/icons-material/Key';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LockIcon from '@mui/icons-material/Lock';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { ajaxGetCompanyInfo } from '../../services/companyService';
import { ajaxGetInventory,ajaxResetInventoryProduct, ajaxGetInventoryReport, ajaxGetInventorySoldReport } from '../../services/inventoryService';
import InventorySearch from './InventorySearch';
import InventoryHeader from './InventoryHeader';
import StyledMainGrid from '../../components/StyledMainGrid';
import StyledList from '../../components/StyledList';
import Page from '../../components/Page';
import StyledSearchDate from '../../components/StyledSearchDate';
import { MoneyFormat } from '../../components/MoneyFormat';
import { AuthContext } from '../../context';
import { toDateTimeText, toDateText, downloadFile } from '../../helpers';
import { ajaxGetAdminDistributors } from '../../services/adminService';
import { ajaxMakeReservation } from '../../services/companyService';
import StyledCard from '../../components/StyledCard'
import StyledCardFormContent from '../../components/StyledCardFormContent';
import ConfirmationButton from '../../components/ConfirmationButton';
import useAlert from '../../hooks/useAlert'
import moment from 'moment-timezone';

const searchFilters = [
  {
    value: 'serial_key',
    label: 'Serial Number / MAC Address'
  },

  {
    value: 'product_code',
    label: 'Product Code'
  }
];

const makeButtonStyle = {
  height: '30px',
  fontSize: '12px',
  marginLeft: '10px',
  minWidth: { xs: '50px', sm: '64px' }
};

export default function Inventory(props) {
  const { inMemoryUser } = useContext(AuthContext);
  const isTest = inMemoryUser.isTest();
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchFilter, setSearchFilter] = useState('product_code');
  const [searchResults, setSearchResults] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [distributor, setDistributor] = useState(null);
  const [companyInfo, setCompanyInfo] = useState(false);
  const [product, setProduct] = useState(null);
  const incomingParams = useParams();
  const isAdmin = window.IS_ADMIN;
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const paramCompanyId = (incomingParams && incomingParams.CompanyId ? incomingParams.CompanyId : -1);
  // dropdown button
  const [open, setOpen] = React.useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const errorAlert = useAlert(false, 'error');

  const handleOpenSaleMenu = (id) => {
    let newOpen = {
      ...open
    }
    newOpen[id] = !newOpen[id];
    setOpen(newOpen);
  };

  const handleClose = (id) => {
    let newOpen = {
      ...open
    }
    newOpen[id] = false;
    setOpen(newOpen);
  };
  // end dropdown button

  const handleResetAllProducts = () => {
    let companyId;
    if (paramCompanyId !== -1) {
      companyId = paramCompanyId;
    }

    ajaxResetInventoryProduct({ CompanyId: isAdmin ? companyId : undefined })
      .then((res) => {
        window.location.reload();
      })
      .catch(() => {
      });
  };

  const handleMakeSale = (id_or_serial) => {
    let url = `/dashboard/make-sale/${encodeURIComponent(id_or_serial)}`;
    navigate(url);
  };

  const handleMakeReservation = (id, isCancel) => {
    if ( isAdmin ) {
      return;
    }

    let data = {
      id: id,
      isCancel: isCancel
    };

    ajaxMakeReservation(data)
      .then((res) => {
        getInventory(props.sold, paramCompanyId);
      })
      .catch(() => {
      });

    handleClose(id);
  };

  const getInventory = (isSoldMode, paramCompanyId) => {
    let params = {
      isSoldMode: isSoldMode
    };

    if (paramCompanyId !== -1) {
      params.companyId = paramCompanyId;
    }

    ajaxGetInventory(params)
      .then((res) => {
        const { data } = res;
        setItems(data);
      })
      .catch(() => {
        setItems([]);
      });
  }

  const handleViewSale = (id_or_serial) => {
    if ( !distributor ) {
      return;
    }

    let url = `/dashboard/inventory/view/${distributor.company_id}/${encodeURIComponent(id_or_serial)}`;
    navigate(url);
  };

  useEffect(() => {

    getInventory(props.sold, paramCompanyId);

    let companyId;
    if (paramCompanyId !== -1) {
      companyId = paramCompanyId;
    }

    if ( isAdmin && companyId ) {
      ajaxGetAdminDistributors({ CompanyId: companyId })
        .then((res) => {
          const { data } = res;
          setDistributor(data);
        })
        .catch(() => {
          setDistributor(null);
        });
    }
    else {
      ajaxGetCompanyInfo()
      .then((res) => {
        const { data } = res;
        setCompanyInfo(data);
      })
      .catch(() => {
        setCompanyInfo(false);
      });
    }
  }, [props.sold, paramCompanyId, isAdmin]);

  useEffect(() => {
    const results = items.filter((item) => {
      let testText = true;
      let testStartDate = true;
      let testEndDate = true;
      if (item[searchFilter]) {
        testText = item[searchFilter].toLowerCase().includes(searchTerm);
      }

      let dateKey = props.sold ? 'sold_date' : 'received_date';
      if (startDate) {
        testStartDate = item[dateKey] >= Math.floor(new Date(startDate).getTime() / 1000);
      }
      if (endDate) {
        testEndDate = item[dateKey] <= Math.floor(new Date(endDate).getTime() / 1000);
      }
      return testText && testStartDate && testEndDate;
    });

    setSearchResults(results);
  }, [searchTerm, searchFilter, items, startDate, endDate, props.sold]);

  const refsById = useMemo(() => {
      const refs = {}
      items.forEach((item) => {
          refs[item.id] = React.createRef(null)
      })
      return refs
  }, [items])

  const handleDownload = (format) => () => {
    errorAlert.resetAlert();
    
    setIsProcessing(true);

    let filename = `inventory${props.sold ? '-sold' : ''}${distributor ? '_' + distributor.company_name : ''}${startDate ? '_'+moment(startDate).format('YYYY-MM-DD') : ''}${endDate ? '_'+moment(endDate).format('YYYY-MM-DD') : ''}.${format}`;

    // validate filename
    filename = filename.replace(/[\s/\\?%*:|"<>]/g, '');

    const requestParams = {
      format: format,
      companyId: paramCompanyId !== -1 && paramCompanyId,
      startDate: startDate ? moment(startDate).unix() : 0,
      endDate: endDate ? moment(endDate).unix() : 0,
      filter: searchFilter,
      term: searchTerm || "empty",
      tz: moment.tz.guess()
    }
    const requestFunction = props.sold ? ajaxGetInventorySoldReport : ajaxGetInventoryReport;
    
    requestFunction(requestParams, filename)
    .then(() => {
      setIsProcessing(false);
    })
    .catch((e) => {
      errorAlert.setAlert(e.message);
      setIsProcessing(false);
    });
  };

  const renderItem = (data, idx, secondarySx) => {
    let altSecondarySx = {...secondarySx, fontSize: '13px', lineHeight: '15px'};
    const byUser = (u) => u.first_name || u.last_name ? ' by ' + ((u.first_name ? u.first_name : '') + (u.last_name ? ' ' + u.last_name : '')).trim() : '';
    let dateComp = (
      <Tooltip title={`${props.sold ? 'Date Sold:' : 'Received in warehouse:'} ${toDateTimeText(props.sold ? data.sold_date : data.received_date)}`}>
        {
          smUp ?
          <Box component="span" sx={altSecondarySx}>{toDateTimeText(props.sold ? data.sold_date : data.received_date)}{byUser(data)}</Box>
          :
          <Box component="span" sx={altSecondarySx}>{toDateText(props.sold ? data.sold_date : data.received_date)}{byUser(data)}</Box>
        }
      </Tooltip>
    );
    let priceComp = (
      isAdmin ?
      <MoneyFormat value={data.msrp} />
      :
      <MoneyFormat currency={data.currency} value={data.msrp_local} />
    );
    let invComp = (
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column-reverse', sm: 'column' }, textAlign: { xs: 'left', sm: 'right' }, mr: { xs: '0px', sm: '10px' } }}>
        <Box sx={altSecondarySx}>
          <Box sx={{ fontWeight: 'bold' }}>
            {priceComp}
          </Box>
        </Box>
        <Box sx={altSecondarySx}>
          {
            props.sold &&
            <Box sx={{ whiteSpace: { xs: 'pre-line', sm: 'auto' } }}>
              {smUp ? data.sold_invoices.join(', ') : data.sold_invoices.join('\n')}
            </Box>
          }
          <Box sx={{ whiteSpace: { xs: 'pre-line', sm: 'auto' } }}>
            {smUp ? data.ref.replace(/\n/g, ', ') : data.ref}
          </Box>
        </Box>
      </Box>
    );
    return (
      <ListItem
        key={idx}
        disablePadding
        secondaryAction={
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column-reverse', sm: 'row' }, alignItems: { xs: 'flex-end', sm: 'center' }}}>
            {
              smUp ?
              invComp
              :
              dateComp
            }
            <Box>
              <Tooltip
                title={
                  <Box>
                    <Box>
                    Packing List of {data.awb}
                    </Box>
                    <Box>
                    {props.sold ? `Received in warehouse ${toDateTimeText(data.received_date)}` : `Dispatched to warehouse ${toDateTimeText(data.dispatched_date)}`}
                    </Box>
                  </Box>
                }
              >
                <IconButton
                  onClick={() =>
                    downloadFile({
                      params: { id: data.shipment_id, fileType: 'packing_list', role: inMemoryUser.getUserRole() },
                      fileName: data.awb + '_packing_list',
                      fileExt: 'pdf'
                    })
                  }
                >
                  <LocalShippingIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={`Shipping Invoice of ${data.awb}`}>
                <IconButton
                  onClick={() =>
                    downloadFile({
                      params: { id: data.shipment_id, fileType: 'invoice', role: inMemoryUser.getUserRole() },
                      fileName: data.awb + '_invoice',
                      fileExt: 'pdf'
                    })
                  }
                >
                  <RequestQuoteIcon />
                </IconButton>
              </Tooltip>
              {
                props.sold && // for sold inventory page
                <Tooltip title={`View Activation/License Code`}>
                  <IconButton onClick={() => setProduct(data)}>
                    <KeyIcon />
                  </IconButton>
                </Tooltip>
              }
              {
                !isAdmin && props.sold && // for sold inventory page
                <Button
                  variant="contained"
                  size="normal"
                  sx={makeButtonStyle}
                  onClick={() => handleMakeSale(data.serial_key)}
                >
                  Sell
                </Button>
              }
              { !isAdmin && !props.sold && // for inventory page
                <React.Fragment>
                  <ButtonGroup variant="contained" size="small" ref={refsById[data.id]}>
                    { data.status !== 'reserve' &&
                      <Button sx={{width: 80}} onClick={() => handleMakeSale(data.serial_key)}>{'Sell'}</Button>
                    }
                    { data.status === 'reserve' &&
                      <Button sx={{width: 80}} color="warning" onClick={() => {}}>{'Reserved'}</Button>
                    }
                    <Button
                      sx={{
                        minWidth: '20px',
                        width: 20
                      }}
                      color={data.status === 'reserve' ? 'warning' : 'primary'}
                      onClick={() => handleOpenSaleMenu(data.id)}
                    >
                      <ArrowDropDownIcon />
                    </Button>
                  </ButtonGroup>
                  <Popover
                    id={data.id}
                    open={open[data.id]}
                    anchorEl={refsById[data.id].current}
                    onClose={() => handleClose(data.id)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    PaperProps={{ style: {
                      width: 200
                    }}}
                  >
                    <MenuList dense>
                      { data.status !== 'reserve' &&
                        <MenuItem
                          onClick={() => handleMakeReservation(data.id, false)}
                        >
                          {'Reserve this product'}
                        </MenuItem>
                      }
                      { data.status === 'reserve' &&
                        <MenuItem
                          onClick={() => handleMakeReservation(data.id, true)}
                        >
                          {'Ready to sell'}
                        </MenuItem>
                      }
                    </MenuList>
                  </Popover>
                </React.Fragment>
              }
              {
                isAdmin &&
                <Button
                  variant="contained"
                  size="normal"
                  sx={makeButtonStyle}
                  onClick={() => handleViewSale(data.serial_key)}
                >
                  View
                </Button>
              }
            </Box>
          </Box>
        }
      >
        <ListItemButton>
          <ListItemText
            primary={
              <Box sx={{ display: { xs: 'block', sm: 'flex' } }}>
                { data.status === 'reserve' &&
                  <LockIcon fontSize="small" color="warning" />
                }
                {data.product_code}
                {
                  smUp &&
                  <Chip label={data.serial_key} size="small" variant="outlined" sx={{ ml: '10px' }} />
                }
              </Box>
            }
            secondary={
              smUp ?
              dateComp
              :
              <React.Fragment>
                <Box sx={altSecondarySx}>{data.serial_key}</Box>
                {invComp}
              </React.Fragment>
            }
          />
        </ListItemButton>
      </ListItem>
    );
  };

  const onCodeDialogClose = () => setProduct(null);

  return (
    <Page
      title={[
        ...(
          distributor ?
          [distributor.company_name]
          :
          []
        ),
        props.sold ? 'Sold Inventory' : 'Inventory'
      ]}
      backButton={
        distributor ?
        <IconButton color="primary" component={RouterLink} to={`/dashboard/distributor/${distributor.company_id}`}>
          <ArrowBackIcon fontSize="large" sx={{ mt: '3px' }}/>
        </IconButton>
        :
        undefined
      }
    >
      <StyledCard>
        <StyledCardFormContent sx={{ mb: { sm: '-72px' } }}>
          <StyledMainGrid>
            <Grid xs={12} item>
              <InventorySearch setSearchTerm={setSearchTerm} setSearchFilter={setSearchFilter} searchFilters={searchFilters} searchFilter={searchFilter} />
            </Grid>
            <Grid xs={12} item>
              <StyledSearchDate startDate={startDate} endDate={endDate} onStartDate={setStartDate} onEndDate={setEndDate} />
            </Grid>
            <Grid xs={12} item>
              <InventoryHeader items={searchResults} sold={props.sold} currency={companyInfo ? companyInfo.currency : null} />
            </Grid>
            <Grid xs={12} item sx={{ mb: '-60px' }}>
              {
                isTest &&
                <ConfirmationButton
                  buttonProps={{
                    variant: 'contained',
                    style: {
                      height: '30px',
                      fontSize: '12px'
                    },
                    sx:{
                      backgroundColor: '#c62828',
                      ml: '10px'
                    }
                  }}
                  handleDialogClickYes={() => handleResetAllProducts()}
                  buttonText="Reset Demo Data"
                  dialogText="After reset, all your demo test data will be lost and return back to starting data. Are you sure to continue?"
                  dialogYesText="Confirm"
                  dialogNoText="Cancel"
                />
              }
              <Button
                variant="contained"
                size="normal"
                sx={makeButtonStyle}
                onClick={handleDownload('csv')}
                disabled={isProcessing}
              >
                Export
              </Button>
            </Grid>
          </StyledMainGrid>
        </StyledCardFormContent>
        <StyledCardFormContent>
          <StyledList
            noEmptyRow
            dataSource={searchResults}
            pagination={25}
            renderItem={renderItem}
            sort={{
              sort: 'desc',
              sortKey: props.sold === true ? 'sold_date' : 'received_date',
              sortOptions: [
                {
                  key: 'product_code',
                  label: 'Product Code'
                },
                {
                  key: 'serial_key',
                  label: 'Serial Number / MAC'
                },
                {
                  key: 'received_date',
                  label: 'Received in warehouse'
                },
                (
                  props.sold === true ?
                  {
                    key: 'sold_date',
                    label: 'Date Sold'
                  }
                  :
                  {
                    key: 'dispatched_date',
                    label: 'Dispatched to warehouse'
                  }
                ),
                {
                  key: 'msrp',
                  label: props.sold ? 'Discounted Price' : 'MSRP'
                }
              ]
            }}/>
        </StyledCardFormContent>
      </StyledCard>
      {
        !!product &&
        <Dialog
          PaperProps={{ sx: { backgroundColor: 'white', color: 'black' } }}
          onClose={onCodeDialogClose}
          open
        >
          <DialogContent>
            <DialogContentText color="inherit" sx={{ mb: '10px', fontSize: '1.5rem', fontWeight: 'bold' }}>
            {product.product_code} {product.serial_key}
            </DialogContentText>
            <Grid container direction="row" rowSpacing="10px">
                <Grid item xs={12}>
                  <strong>Activation Code:</strong><br/>
                  {product.activation_code}
                  <CopyToClipboard text={product.activation_code}>
                      <IconButton><ContentCopyIcon/></IconButton>
                  </CopyToClipboard>
                </Grid>
                <Grid item xs={12}>
                  <strong>License Code:</strong><br/>
                  {
                    product.license && product.license.length > 0
                      ? product.license.map(license =>
                        <div>
                          {license}
                          <CopyToClipboard text={license}>
                            <IconButton><ContentCopyIcon/></IconButton>
                          </CopyToClipboard>
                        </div>
                      )
                      : 'N/A'
                  }
                </Grid>
              </Grid>
          </DialogContent>
          <DialogActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div>
              <Button
                style={{padding: '4px 30px'}}
                variant="outlined"
                onClick={onCodeDialogClose}
              >
                Close
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      }
    </Page>
  );
}

Inventory.defaultProps = {
  sold: false
}
