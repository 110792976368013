import Inventory from '../pages/Inventory/Inventory';
import MakeSale from '../pages/MakeSale/MakeSale';
import AdminDashboard from '../pages/Dashboard/AdminDashboard';
import Dashboard from '../pages/Dashboard/Dashboard';
import Distributor from '../pages/Distributor';
import Product from '../pages/Product/Product';
import ProductList from '../pages/ProductList';
import Option from '../pages/Option';
import OptionList from '../pages/OptionList';
import DistributorList from '../pages/DistributorList';
import AddShipment from '../pages/AddShipment/AddShipment';
import AdminSettings from '../pages/Dashboard/AdminSettings';
import Payment from '../pages/Payment/Payment';
import InvoicePayment from '../pages/InvoicePayment/InvoicePayment';
import Firmware from '../pages/Firmware';
import FirmwareList from '../pages/FirmwareList';
import CustomFirmware from '../pages/CustomFirmware';
import CustomFirmwareList from '../pages/CustomFirmwareList';
import Discount from '../pages/Discount/Discount';
import ManagerList from '../pages/ManagerList';
import Manager from '../pages/Manager';

const adminRoutes = [
  { path: '/dashboard/options/edit/:OptionId', element: Option, exact: true },
  { path: '/dashboard/options/add', element: Option, elementProps: { add: true }, exact: true },
  { path: '/dashboard/options', element: OptionList, exact: true },
  { path: '/dashboard/add-shipment', element: AddShipment, exact: true },
  { path: '/dashboard/products/edit/:ProductId', element: Product, exact: true },
  { path: '/dashboard/products/add', element: Product, elementProps: { add: true }, exact: true },
  { path: '/dashboard/products', element: ProductList, exact: true },
  { path: '/dashboard/distributors/edit/:CompanyId', element: Distributor, exact: true },
  { path: '/dashboard/distributors/add', element: Distributor, elementProps: { add: true }, exact: true },
  { path: '/dashboard/distributors', element: DistributorList, exact: true },
  { path: '/dashboard/inventory/view/:CompanyId/*', element: MakeSale, exact: true },
  { path: '/dashboard/inventory/sold/:CompanyId', element: Inventory, elementProps: { sold: true }, exact: true },
  { path: '/dashboard/inventory/:CompanyId', element: Inventory, exact: true },
  { path: '/dashboard/distributor/:CompanyId', element: Dashboard, exact: true },
  { path: '/dashboard/distributor', element: Dashboard, exact: true },
  { path: '/dashboard/settings', element: AdminSettings, exact: true },
  { path: '/dashboard/payment/:CompanyId', element: Payment, exact: true },
  { path: '/dashboard/invoicePayment/:CompanyId', element: InvoicePayment, exact: true },
  { path: '/dashboard/firmware/edit/:FirmwareId', element: Firmware, exact: true },
  { path: '/dashboard/firmware/add', element: Firmware, elementProps: { add: true }, exact: true },
  { path: '/dashboard/firmware', element: FirmwareList, exact: true },
  { path: '/dashboard/cfirmware/edit/:FirmwareId', element: CustomFirmware, exact: true },
  { path: '/dashboard/cfirmware/add', element: CustomFirmware, elementProps: { add: true }, exact: true },
  { path: '/dashboard/cfirmware', element: CustomFirmwareList, exact: true },
  { path: '/dashboard/discount', element: Discount, exact: true },
  { path: '/dashboard', element: AdminDashboard, exact: true }
];

const distributorRoutes = [
  { path: '/dashboard/make-sale/*', element: MakeSale, exact: true },
  { path: '/dashboard/make-sale/', element: MakeSale, exact: true },
  { path: '/dashboard/inventory/sold', element: Inventory, elementProps: { sold: true }, exact: true },
  { path: '/dashboard/inventory', element: Inventory, exact: true },
  { path: '/dashboard/payment', element: Payment, exact: true },
  { path: '/dashboard/invoicePayment', element: InvoicePayment, exact: true },
  { path: '/dashboard', element: Dashboard, exact: true },
  { path: '/dashboard/managers/edit/:UserId', element: Manager, exact: true },
  { path: '/dashboard/managers/add', element: Manager, elementProps: { add: true }, exact: true },
  { path: '/dashboard/managers', element: ManagerList, exact: true },
];

const managerRoutes = [
  { path: '/dashboard/make-sale/*', element: MakeSale, exact: true },
  { path: '/dashboard/make-sale/', element: MakeSale, exact: true },
  { path: '/dashboard/inventory/sold', element: Inventory, elementProps: { sold: true }, exact: true },
  { path: '/dashboard/inventory', element: Inventory, exact: true },
  { path: '/dashboard/payment', element: Payment, exact: true },
  { path: '/dashboard', element: Dashboard, exact: true }
];

export { adminRoutes, distributorRoutes, managerRoutes };
