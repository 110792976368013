import React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import StyledMainGrid from './StyledMainGrid';

export default function StyledSearchDate(props) {
  return (
    <StyledMainGrid item container columnSpacing>
      <Grid item xs="12" sm="6">
        <TextField
          fullWidth
          size="small"
          label="Start Date"
          id="search-date-start"
          type="datetime-local"
          InputLabelProps={{ shrink: true }}
          value={props.startDate}
          onChange={(e) => props.onStartDate(e.target.value)}
        />
      </Grid>
      <Grid item xs="12" sm="6">
        <TextField
          fullWidth
          size="small"
          label="End Date"
          id="search-date-end"
          type="datetime-local"
          InputLabelProps={{ shrink: true }}
          value={props.endDate}
          onChange={(e) => props.onEndDate(e.target.value)}
        />
      </Grid>
    </StyledMainGrid>
  );
}
