import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

export default function AdminButtons({ companyId, handleCompanyUpdate }) {
  const style = {
    button: {
      width: '100%'
    }
  };
  const viewInventoryHref = '/dashboard/inventory/' + encodeURIComponent(companyId);
  const viewSalesHref = '/dashboard/inventory/sold/' + encodeURIComponent(companyId);
  const viewPaymentHref = '/dashboard/payment/' + encodeURIComponent(companyId);
  const viewInvoicePaymentHref = '/dashboard/invoicePayment/' + encodeURIComponent(companyId);

  return (
    <React.Fragment>
      <Grid
        container
        rowSpacing="10px"
        columnSpacing="10px"
        sx={{ mt: '10px' }}
      >
        <Grid item xs={12} sm={6}>
          <Button variant="contained" size="small" style={style.button} component={RouterLink} to={viewInvoicePaymentHref}>
            View Invoices
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button variant="contained" size="small" style={style.button} component={RouterLink} to={viewPaymentHref}>
            View Payment
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button variant="contained" size="small" style={style.button} component={RouterLink} to={viewInventoryHref}>
            View Inventory
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button variant="contained" size="small" style={style.button} component={RouterLink} to={viewSalesHref}>
            View Sales Report
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
