import React, { useState, useEffect, useContext, useCallback } from 'react';
import IconButton from '@mui/material/IconButton';
import FeedbackIcon from '@mui/icons-material/Feedback';
import CancelIcon from '@mui/icons-material/Cancel';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CircularProgress from '@mui/material/CircularProgress';
import CardContent from '@mui/material/CardContent';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import { ajaxGetInventory } from '../../services/inventoryService';
import { ajaxMakeCancelReplacement } from '../../services/companyService';
import { ajaxGetInventoryItem } from '../../services/inventoryService';
import {
  ajaxConfirmCompanyReplacement,
  ajaxCancelCompanyReplacement
} from '../../services/adminService';
import StyledList from '../../components/StyledList';
import StyledCard from '../../components/StyledCard'
import { AuthContext } from '../../context';
import { useNavigate } from 'react-router-dom';
import {noty} from '../../helpers';

export default function ReplacementProducts(props) {
  const { inMemoryUser } = useContext(AuthContext);
  const [replacementProducts, setReplacementProducts] = useState([]);
  const [approveId, setApproveId] = useState(false);
  const [denyId, setDenyId] = useState(false);
  const [detailData, setDetailData] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const navigate = useNavigate();

  const loadData = useCallback((companyId) => {
    let params = {
      isReplacePendingMode: true,
      companyId: props.companyId ? props.companyId : ''
    };

    ajaxGetInventory(params)
      .then((res) => {
        const { data } = res;
        setReplacementProducts(
          inMemoryUser.isAdmin() ?
          data.filter(d => d.replacement === 'pending')
          :
          data
        );
      })
      .catch(() => {
        setReplacementProducts([]);
      });
  }, [props.companyId, inMemoryUser]);

  const handleViewProduct = (serialKey) => {
    navigate(`/dashboard/make-sale/${serialKey}`);
  };

  const handleShowDetail = useCallback((serialKey) => {
    setShowDetail(true);
    setDetailData(false);
    ajaxGetInventoryItem({
      value: serialKey,
      companyId: props.companyId ? props.companyId : ''
    })
      .then((res) => {
        const { data } = res;
        if ( data.replacement !== 'pending' ) {
          setShowDetail(false);
          loadData();
        }
        else {
          setDetailData(data);
        }
      })
      .catch(() => {
        setShowDetail(false);
      });
  }, [loadData, setShowDetail, setDetailData, props.companyId]);

  const handleDeny = useCallback((id) => {
    setDenyId(id);
    (
      inMemoryUser.isAdmin() ?
      ajaxCancelCompanyReplacement({ cancelIds: [id], companyId: props.companyId })
      :
      ajaxMakeCancelReplacement({ cancelIds: [id], reset: true })
    )
      .then((res) => {
        setDenyId(false);
        loadData(props.companyId);
        setShowDetail(false);
        setDetailData(false);
        if ( res.status !== 'ok' ) {
          noty(res.message, 'error');
        }
      })
      .catch((e) => {
        setDenyId(false);
        loadData(props.companyId);
        setShowDetail(false);
        setDetailData(false);
      });
  }, [inMemoryUser, loadData, setDenyId, setShowDetail, setDetailData, props.companyId]);

  const handleConfirm = useCallback((id) => {
    if (!inMemoryUser.isAdmin() ) {
      return;
    }

    setApproveId(id);
    ajaxConfirmCompanyReplacement({ productIds: [id], companyId: props.companyId })
      .then((res) => {
        setApproveId(false);
        loadData();
        setShowDetail(false);
        setDetailData(false);
        if ( res.status !== 'ok' ) {
          noty(res.message, 'error');
        }
      })
      .catch((e) => {
        setApproveId(false);
        loadData();
        setShowDetail(false);
        setDetailData(false);
      });
  }, [inMemoryUser, loadData, setApproveId, setShowDetail, setDetailData, props.companyId]);

  const handleClose = () => {
    setDetailData(false);
    setShowDetail(false);
  };

  useEffect(() => {
    loadData();
  }, [loadData]);

  const renderItem = (data, idx) => {
    return (
      <ListItem
        key={idx}
        disablePadding
        secondaryAction={
          <React.Fragment>
            {
              data.replacement === 'approve' ?
              <span style={{ color: 'green' }}>Approved</span>
              :
              data.replacement === 'deny'?
              <span style={{ color: '#c62828' }}>Denied</span>
              :
              <span>Pending</span>
            }
            {
              (data.replacement === 'approve' || data.replacement === 'deny') ?
              <IconButton
                title="Clear"
                onClick={() => handleDeny(data.id)}
                disabled={approveId || denyId}
              >
                <CancelIcon />
              </IconButton>
              :
              <IconButton
                onClick={() => handleShowDetail(data.serial_key)}
                disabled={approveId || denyId}
              >
                <FeedbackIcon color={ inMemoryUser.isAdmin() ? 'error' : 'info'}/>
              </IconButton>
            }
          </React.Fragment>
        }
      >
        <ListItemButton
          onClick={
            (data.replacement !== 'approve' && data.replacement !== 'deny') ?
            () => handleShowDetail(data.serial_key)
            :
            () => handleViewProduct(data.serial_key)
          }
        >
          <ListItemText
            primary={data.product_code}
            secondary={data.serial_key}
          />
        </ListItemButton>
      </ListItem>
    );
  };

  return (
    <StyledCard
      title="Awaiting Replacement Approval"
      titleIcon={FeedbackIcon}
    >
      <CardContent>
        <StyledList
          dataSource={replacementProducts}
          renderItem={renderItem}
        />
      </CardContent>
      {
        showDetail &&
        <Dialog
          maxWidth="xl"
          PaperProps={{ style: { backgroundColor: 'white', color: 'black' } }}
          onClose={handleClose}
          open
        >
          <DialogContent>
            <DialogContentText color="inherit" style={{ fontSize: '14px' }}>
              {
                detailData ?
                <table style={{ width: '100%', padding: '0px 5px' }}>
                  <tbody>
                    <tr>
                      <td style={{ padding: '5px', textAlign: 'right' }}>Serial Number / MAC Address: </td>
                      <td style={{ padding: '5px' }}><strong>{detailData.serial_key}</strong></td>
                    </tr>
                    <tr>
                      <td style={{ padding: '5px', textAlign: 'right' }}>Product Code: </td>
                      <td style={{ padding: '5px' }}><strong>{detailData.product_code}</strong></td>
                    </tr>
                    {
                      detailData.sold_options && detailData.options && Object.keys(detailData.sold_options).length > 0 &&
                      <tr>
                        <td style={{ padding: '15px 5px 5px 5px', textAlign: 'right' }}><strong>Product Options</strong></td>
                        <td style={{ padding: '5px' }}></td>
                      </tr>
                    }
                    {
                      detailData.sold_options && detailData.options && Object.keys(detailData.sold_options).map(key => {
                        let opt = detailData.sold_options[key];
                        let filterOptions = detailData.options.filter(i => i.id === opt.id);
                        return (
                          <tr>
                            <td style={{ padding: '5px', textAlign: 'right' }}>
                              {
                                filterOptions.length > 0 ?
                                `${filterOptions[0].title}: `
                                :
                                'Unknown Option: '
                              }
                            </td>
                            <td style={{ padding: '5px' }}>
                              <strong>
                                {opt.value === true ? 'YES' : opt.value}
                              </strong>
                            </td>
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
                :
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress color="info" size={30} thickness={5} />
                </div>
              }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: { xs: 'column', sm: 'row' } }}>
              <Box sx={{ display: 'flex', width: { xs: '100%', sm: 'auto' }, mb: { xs: '10px' }}}>
                {
                  inMemoryUser.isAdmin() &&
                  <Button
                    sx={{
                      backgroundColor: 'green',
                      padding: '4px 30px',
                      marginRight: '10px',
                      width: { xs: '100%', sm: 'auto' }
                    }}
                    variant="contained"
                    onClick={() => handleConfirm(detailData.id)}
                    disabled={
                      approveId === detailData.id ||
                      denyId === detailData.id
                    }
                    startIcon={
                      approveId === detailData.id ?
                      <CircularProgress size={30} thickness={5}/>
                      :
                      undefined
                    }
                  >
                    Approve
                  </Button>
                }
                <Button
                  sx={{
                    backgroundColor: '#c62828',
                    padding: '4px 30px',
                    width: { xs: '100%', sm: 'auto' }
                  }}
                  variant="contained"
                  onClick={() => handleDeny(detailData.id)}
                  disabled={
                    approveId === detailData.id ||
                    denyId === detailData.id
                  }
                  startIcon={
                    denyId === detailData.id ?
                    <CircularProgress size={30} thickness={5}/>
                    :
                    undefined
                  }
                >
                  {
                    inMemoryUser.isAdmin() ?
                    'Deny'
                    :
                    'Cancel Replacement'
                  }
                </Button>
              </Box>
              <Box sx={{ ml: { xs: 0, sm: '10px' }, width: { xs: '100%', sm: 'auto' }, mb: { xs: '10px' }}}>
                <Button
                  sx={{
                    padding: '4px 30px',
                    width: { xs: '100%', sm: 'auto' }
                  }}
                  variant="outlined"
                  onClick={handleClose}
                  disabled={
                    approveId === detailData.id ||
                    denyId === detailData.id
                  }
                >
                  Close
                </Button>
              </Box>
            </Box>
          </DialogActions>
        </Dialog>
      }
    </StyledCard>
  );
}
