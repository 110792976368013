import { ajaxCall, ajaxCallBlob, formRequestData } from './ajaxService';

const constants = window.getConfig;
const getInventoryAdminUrl = `${constants.ajax.baseUrl}/admin/inventories`;
const getInventoryUrl = `${constants.ajax.baseUrl}/inventory`;
const getInventoryItemUrl = `${constants.ajax.baseUrl}/inventory/item`;
const getInventoryItemAdminUrl = `${constants.ajax.baseUrl}/admin/inventories/item`;
const getInventoryResetProductsUrl = `${constants.ajax.baseUrl}/company/inventory/resetProducts`;
const getInventoryResetProductsAdminUrl = `${constants.ajax.baseUrl}/admin/inventories/resetProducts`;
const getAdminInventoryReportUrl = `${constants.ajax.baseUrl}/admin/inventories/report/inventory`;
const getAdminInventorySoldReportUrl = `${constants.ajax.baseUrl}/admin/inventories/report/inventory_sold`;
const getInventoryReportUrl = `${constants.ajax.baseUrl}/inventory/report/inventory`;
const getInventorySoldReportUrl = `${constants.ajax.baseUrl}/inventory/report/inventory_sold`;

const ajaxGetInventory = (params) =>
  new Promise((res, rej) => {
    if (params.companyId) {
      ajaxCall(getInventoryAdminUrl + `/${params.companyId}`, formRequestData(constants.ajax.methods.post, true, false, params))
        .then(res)
        .catch(rej);
    } else {
      ajaxCall(getInventoryUrl, formRequestData(constants.ajax.methods.post, true, false, params)).then(res).catch(rej);
    }
  });

const ajaxGetInventoryItem = (params) =>
  new Promise((res, rej) => {
    if (params.companyId) {
      ajaxCall(getInventoryItemAdminUrl + `/${params.companyId}`, formRequestData(constants.ajax.methods.post, true, false, params)).then(res).catch(rej);
    }
    else {
      ajaxCall(getInventoryItemUrl, formRequestData(constants.ajax.methods.post, true, false, params), params.ignoreError).then(res).catch(rej);
    }
  });

const ajaxGetProductOptionCodes = () =>
  new Promise((res, rej) => {
    ajaxCall(getInventoryUrl + '/product_codes', formRequestData(constants.ajax.methods.get, true, false))
      .then(res)
      .catch(rej);
  });

const ajaxResetInventoryProduct = (params) =>
  new Promise((res, rej) => {
    if (params.CompanyId) {
      ajaxCall(getInventoryResetProductsAdminUrl + `/${params.CompanyId}`, formRequestData(constants.ajax.methods.post, true, false, params)).then(res).catch(rej);
    }
    else {
      ajaxCall(getInventoryResetProductsUrl, formRequestData(constants.ajax.methods.post, true, false, params), params.ignoreError).then(res).catch(rej);
    }
  });

const ajaxGetInventoryReport = (params, filename) =>
  new Promise((res, rej) => {
    if (params.companyId) {
      ajaxCallBlob(
        `${getAdminInventoryReportUrl}/${params.format}/${params.companyId}/${params.startDate}/${params.endDate}/${params.filter}/${window.btoa(params.term)}/${window.btoa(params.tz)}`,
        formRequestData(constants.ajax.methods.get, true)
      ).then(res).catch(rej);
    } else {
      ajaxCallBlob(
        `${getInventoryReportUrl}/${params.format}/${params.startDate}/${params.endDate}/${params.filter}/${window.btoa(params.term)}/${window.btoa(params.tz)}`,
        formRequestData(constants.ajax.methods.get, true)
      ).then(res).catch(rej);
    }
  }).then((blob) => {
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.style.visibility = 'hidden'; // required in FF, optional for Chrome
    a.target = '_blank'; // required in FF, optional for Chrome
    a.href = url;
    a.download = filename;
    a.click();
  })
  .catch((error) => {
    console.log(error.message);
  });

const ajaxGetInventorySoldReport = (params, filename) =>
  new Promise((res, rej) => {
    if (params.companyId) {
      ajaxCallBlob(
        `${getAdminInventorySoldReportUrl}/${params.format}/${params.companyId}/${params.startDate }/${params.endDate}/${params.filter}/${window.btoa(params.term)}/${window.btoa(params.tz)}`,
        formRequestData(constants.ajax.methods.get, true)
      ).then(res).catch(rej);
    } else {
      ajaxCallBlob(
        `${getInventorySoldReportUrl}/${params.format}/${params.startDate }/${params.endDate}/${params.filter}/${window.btoa(params.term)}/${window.btoa(params.tz)}`,
        formRequestData(constants.ajax.methods.get, true)
      ).then(res).catch(rej);
    }
  }).then((blob) => {
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.style.visibility = 'hidden'; // required in FF, optional for Chrome
    a.target = '_blank'; // required in FF, optional for Chrome
    a.href = url;
    a.download = filename;
    a.click();
  })
  .catch((error) => {
    console.log(error.message);
  });

export { ajaxGetInventory, ajaxGetInventoryItem, ajaxGetProductOptionCodes, ajaxResetInventoryProduct, ajaxGetInventoryReport, ajaxGetInventorySoldReport };
