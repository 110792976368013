import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import EditIcon from '@mui/icons-material/Edit';
import Switch from '@mui/material/Switch';
import ProductOptionDialog from './ProductOptionDialog';
import Button from '@mui/material/Button';

export default function ProductOptions({ product, productOptions, handleToggleActive, handleOptionSave }) {
  const [isOpenedModalData, setIsOpenedModalData] = useState(false);

  let getOptionLabel = (item, index) => {
    return `Option ${index + 1}: ${item.title}`;
  };

  const handleHideModal = (e) => {
    setIsOpenedModalData(false);

    if (e && e.button === 'save') {
      handleOptionSave();
    }
  };

  const style = {
    button: {
      height: '40px',
      width: '150px',
      fontSize: '12px',
      marginRight: '20px',
      marginTop: '20px'
    }
  };

  return (
    <div>
      <List sx={{ width: '100%', maxWidth: 560 }}>
        {productOptions.map((item, index) => (
          <ListItem
            key={item.id}
            disablePadding
            secondaryAction={
              <React.Fragment>
                <Switch edge="start" color={'custom'} checked={item.active > 0} onChange={() => handleToggleActive(item, index)} />
              </React.Fragment>
            }
          >
            <ListItemButton dense>
              <ListItemIcon onClick={() => setIsOpenedModalData(item)}>
                <EditIcon />
              </ListItemIcon>
              <ListItemText style={{ textDecoration: item.active > 0 ? 'none' : 'line-through' }} primary={getOptionLabel(item, index)} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Button variant="contained" size="normal" style={style.button} onClick={() => setIsOpenedModalData({ product_code_id: product.id, isNew: true })}>
        Add Option
      </Button>
      {
        isOpenedModalData &&
        <ProductOptionDialog
          editMode={!isOpenedModalData.isNew}
          data={isOpenedModalData.isNew ? undefined : isOpenedModalData}
          handleAfterSave={() => {}}
          productId={isOpenedModalData.product_code_id}
          show={isOpenedModalData}
          onHide={handleHideModal}
          dialogTitle={isOpenedModalData.isNew ? 'Add new product option' : 'Edit product option'}
          dialogNoText="Cancel"
        />
      }
    </div>
  );
}
