import React from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import useAlert from '../../hooks/useAlert';
import { ajaxAddAdminShipment } from '../../services/adminService';
import ConfirmationButton from '../../components/ConfirmationButton';
import StyledMainGrid from '../../components/StyledMainGrid';
import AddShipmentFields from './AddShipmentFields';
import ShipmentItemDialog from './ShipmentItemDialog';
import Page from '../../components/Page';
import StyledCardFormContent from '../../components/StyledCardFormContent';
import StyledList from '../../components/StyledList';
import StyledCard from '../../components/StyledCard'

export default function AddShipment(props) {
  const gridSpacing = '22px';
  const [uniqueUiKey, setUniqueUiKey] = React.useState(0);
  const [shipmentProducts, setShipmentProducts] = React.useState([]);
  const [fields, setFields] = React.useState({});
  const navigate = useNavigate();
  const errorAlert = useAlert(false, 'error');

  const handleAddShippingProduct = (e) => {
    setShipmentProducts([...shipmentProducts, e]);
  };

  const handleDeleteShippingProduct = (e) => {
    setShipmentProducts([...shipmentProducts].filter((product) => product.code !== e.code));
  };

  const getExludedProductCodes = () => {
    let excludedProductCodes = shipmentProducts.length ? [...shipmentProducts].map((product) => product.code) : [];

    return excludedProductCodes;
  };

  const handleChangeShippingFields = (key, value) => {
    setFields({ ...fields, [key]: value });
  };

  const handleAddShipment = () => {
    if (!fields['company_id']) {
      errorAlert.setAlert('You have to choose a Company');
      return false;
    }

    if (!fields['awb']) {
      errorAlert.setAlert('You have to set AWB');
      return false;
    }

    if (!fields['tracking_hyperlink']) {
      errorAlert.setAlert('You have to set the Tracking hyperlink');
      return false;
    }

    if (!fields['file_invoice']) {
      errorAlert.setAlert('You have to upload Shipping Invoice');
      return false;
    }

    if (!fields['file_packing_list']) {
      errorAlert.setAlert('Upload Packing List');
      return false;
    }

    if (shipmentProducts.length < 1) {
      errorAlert.setAlert('You have to add at least one product to the shipment');
      return false;
    }

    const params = { ...fields, products: shipmentProducts };

    ajaxAddAdminShipment(params)
      .then((res) => {
        if (res.error) {
          if ( Array.isArray(res.errorList) ) {
            errorAlert.setAlert(`${res.error}\r\n${res.errorList.join('\r\n')}`);
          }
          else {
            errorAlert.setAlert(res.error);
          }
        } else {
          setShipmentProducts([]);
          setFields({});
          navigate('/dashboard');
        }
      })
      .catch((e) => {
      });
  };

  const onReset = () => {
    setShipmentProducts([]);
    setFields({});
    setUniqueUiKey(uniqueUiKey + 1);
    errorAlert.resetAlert();
  };

  const renderItem = (data, idx) => {
    return (
      <ListItem
        key={idx}
        disablePadding
        secondaryAction={
          <React.Fragment>
            <Chip label={data.quantity} color="info" sx={{ marginRight: '10px' }} />
            <IconButton onClick={() => handleDeleteShippingProduct(data)}>
              <DeleteIcon color="error"/>
            </IconButton>
          </React.Fragment>
        }
      >
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: 'transparent' }}>
            <CheckCircleIcon color="success" />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={data.code}
        />
      </ListItem>
    );
  };

  return (
    <Page title="Add Shipment">
      <StyledCard>
        <StyledCardFormContent>
          <StyledMainGrid key={uniqueUiKey}>
            <Grid
              item
              container
              xs={12}
              rowSpacing={gridSpacing}
              columnSpacing={gridSpacing}
            >
              <AddShipmentFields handleChange={handleChangeShippingFields} fields={fields} />
            </Grid>
            <Grid item xs={12}>
              <Box>{errorAlert.showAlert()}</Box>
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={12}>
              <Typography variant="h5">
                What products to include to the shipment?
              </Typography>
            </Grid>

            <Grid item container xs={12}>
              <Grid item xs={12} sm={6}>
                <StyledList
                  addRow
                  addRowButton={
                    <ShipmentItemDialog
                      dialogText="Add a new product to the shipment"
                      dialogYesText="Add"
                      excludedProductCodes={getExludedProductCodes()}
                      dialogNoText="Cancel"
                      handleDialogClickYes={handleAddShippingProduct}
                    />
                  }
                  dataSource={shipmentProducts}
                  renderItem={renderItem}
                  sx={{
                    border: '1px solid #dddddd',
                    borderRadius: '5px',
                    paddingLeft: '10px',
                    paddingRight: '10px'
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={12}>
              <ConfirmationButton
                buttonProps={{
                  variant: 'contained',
                  size: 'medium',
                  style: {
                    marginRight: '10px',
                    height: '40px',
                    width: '130px',
                    fontSize: '12px'
                  }
                }}
                handleDialogClickYes={handleAddShipment}
                buttonText="Add Shipment"
                dialogText="Once the shipment is added it will appear in the distributor account."
                dialogYesText="Confirm"
                dialogNoText="Cancel"
                additionalButtons={[
                  {
                    buttonText: 'Cancel',
                    handleButton: onReset,
                    buttonProps: {
                      variant: 'outlined',
                      size: 'medium',
                      style: {
                        marginRight: '10px',
                        height: '40px',
                        width: '130px',
                        fontSize: '12px'
                      }
                    }
                  }
                ]}
              />
            </Grid>
          </StyledMainGrid>
        </StyledCardFormContent>
      </StyledCard>
    </Page>
  );
}
