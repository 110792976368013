import React, {useMemo} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import BarChartIcon from '@mui/icons-material/BarChart';
import StyledMainGrid from '../../components/StyledMainGrid';
import { MoneyFormat } from '../../components/MoneyFormat';

export default function InventoryHeader({currency, items, sold}) {
  const stats = useMemo(() => {
    let msrp_sum = 0;
    let msrp_local_sum = 0;
    items.forEach((item) => {
      msrp_sum += parseFloat(item.msrp);
      msrp_local_sum += parseFloat(item.msrp_local);
    });

    return {
      totalProducts: items.length,
      totalPrice: msrp_sum,
      totalPriceLocal: msrp_local_sum
    };
  }, [items]);

  const typographySx = {
    textAlign: 'center',
    paddingTop: '10px'
  };
  const iconContainerSx = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '5px'
  };
  return (
    <StyledMainGrid item container columnSpacing>
      <Grid item xs="4">
        <Typography variant="h6" sx={typographySx}>
          <Box>
            {sold ? 'Sold Inventory Stats' : 'Inventory Stats'}
          </Box>
          <Box sx={iconContainerSx}>
            <BarChartIcon  fontSize="large"/>
          </Box>
        </Typography>
      </Grid>
      <Grid item xs="4">
        <Typography variant="h6" sx={typographySx}>
          <Box>
            Total Products
          </Box>
          <Box sx={iconContainerSx}>
            {stats.totalProducts}
          </Box>
        </Typography>
      </Grid>
      <Grid item xs="4">
        <Typography variant="h6" sx={typographySx}>
          <Box>
            {sold ? 'Total Discounted Price' : 'Total MSRP'}
          </Box>
          <Box sx={iconContainerSx}>
            {
              window.IS_ADMIN ?
              <MoneyFormat value={stats.totalPrice} />
              :
              <MoneyFormat currency={currency} value={stats.totalPriceLocal} />
            }
          </Box>
        </Typography>
      </Grid>
    </StyledMainGrid>
  );
}
