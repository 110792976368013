import { ajaxCall, ajaxCallBlob, formRequestData } from './ajaxService';

const constants = window.getConfig;
const ajaxDownloadFileUrl = `${constants.ajax.baseUrl}/files`;
const ajaxAdminDownloadFileUrl = `${constants.ajax.baseUrl}/admin/files`;
const ajaxDownloadImgUrl = `${constants.ajax.baseUrl}/products/images`;
const ajaxAdminDeleteImgUrl = `${constants.ajax.baseUrl}/admin/products/images/delete`;
const ajaxAdminDownloadFirmwareUrl = `${constants.ajax.baseUrl}/admin/firmwares/files`;
const ajaxAdminDownloadCustomFirmwareUrl = `${constants.ajax.baseUrl}/admin/cfirmwares/files`;

const ajaxDownloadFile = (params) =>
  new Promise((res, rej) => {
    var ajaxUrl = ajaxDownloadFileUrl;

    if (params.role === 'administrator') {
      ajaxUrl = ajaxAdminDownloadFileUrl;
    }

    if (params.fileType) {
      ajaxUrl += '/' + params.fileType;
    }

    if (params.id) {
      ajaxUrl += '/' + params.id;
    }

    ajaxCallBlob(ajaxUrl, formRequestData(constants.ajax.methods.get, true, false)).then(res).catch(rej);
  });

const getProductImageUrl = (productId) => {
  return `${ajaxDownloadImgUrl}/${productId}`;
};

const ajaxDownloadImgFile = (productId, name) =>
  new Promise((res, rej) => {
    ajaxCallBlob(getProductImageUrl(productId), formRequestData(constants.ajax.methods.get, true, false)).then(res).catch(rej);
  }).then((blob) => {
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.style.visibility = 'hidden'; // required in FF, optional for Chrome
    a.target = '_blank'; // required in FF, optional for Chrome
    a.href = url;
    a.download = name;
    a.click();
  })
  .catch((error) => {
    console.log(error.message);
  });

const ajaxDeleteProductImg = (productId) =>
  new Promise((res, rej) => {
    ajaxCall(`${ajaxAdminDeleteImgUrl}/${productId}`, formRequestData(constants.ajax.methods.get, true, false))
      .then(res)
      .catch(rej);
  });

const ajaxDownloadFirmwareFile = (id, name) =>
  new Promise((res, rej) => {
    ajaxCallBlob(`${ajaxAdminDownloadFirmwareUrl}/${id}`, formRequestData(constants.ajax.methods.get, true, false)).then(res).catch(rej);
  }).then((blob) => {
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.style.visibility = 'hidden'; // required in FF, optional for Chrome
    a.target = '_blank'; // required in FF, optional for Chrome
    a.href = url;
    a.download = name;
    a.click();
  })
  .catch((error) => {
    console.log(error.message);
  });

const ajaxDownloadCustomFirmwareFile = (id, name) =>
  new Promise((res, rej) => {
    ajaxCallBlob(`${ajaxAdminDownloadCustomFirmwareUrl}/${id}`, formRequestData(constants.ajax.methods.get, true, false)).then(res).catch(rej);
  }).then((blob) => {
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.style.visibility = 'hidden'; // required in FF, optional for Chrome
    a.target = '_blank'; // required in FF, optional for Chrome
    a.href = url;
    a.download = name;
    a.click();
  })
  .catch((error) => {
    console.log(error.message);
  });

export { ajaxDownloadFile, ajaxDownloadImgFile, getProductImageUrl, ajaxDeleteProductImg, ajaxDownloadFirmwareFile, ajaxDownloadCustomFirmwareFile };
