import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import Grid from '@mui/material/Grid';
import StyledMainGrid from '../components/StyledMainGrid';
import FormControl from '@mui/material/FormControl';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useNavigate, useParams } from 'react-router-dom';
import { ajaxGetAdminDistributors, ajaxSaveAdminDistributor, ajaxDisableAdminDistributor, ajaxEnableAdminDistributor } from '../services/adminService';
import FileUploader from '../components/FileUploader';
import DistributorProductDiscounts from './DistributorProductDiscounts';
import DistributorProductOptionDiscounts from './DistributorProductOptionDiscounts';
import validator from 'validator';
import { Link } from 'react-router-dom';
import {countries} from '../helpers';
import Page from '../components/Page';
import StyledCard from '../components/StyledCard'
import StyledCardFormContent from '../components/StyledCardFormContent';
import {getAllISOCodes} from 'iso-country-currency';
import { AuthContext } from '../context';

export default function Distributor(props) {
  const { inMemoryUser } = useContext(AuthContext);
  const isTest = inMemoryUser.isTest();
  const incomingParams = useParams();
  const allFields = useMemo(
    () => {
      let fields = [
        {
          key: 'internal',
          type: 'checkbox',
          label: 'For Internal Use'
        },
        {
          key: 'company_name',
          type: 'text',
          label: 'Distributor Name'
        },
        {
          key: 'first_name',
          type: 'text',
          label: 'Admin First Name',
        },
        {
          key: 'last_name',
          type: 'text',
          label: 'Admin Last Name',
        },
        {
          key: 'username',
          type: 'text',
          label: 'Username',
          autoComplete: 'username',
          minLength: 6
        },
        {
          key: 'password',
          type: 'password',
          label: 'Password',
          autoComplete: 'new-password',
          minLength: 8
        },
        {
          key: 'confirmPassword',
          type: 'password',
          label: 'Confirm Password',
          autoComplete: 'confirm-password',
          confirm: 'password'
        },
        {
          key: 'country',
          type: 'country',
          label: 'Country'
        },
        {
          key: 'address',
          type: 'text',
          label: 'Address'
        },
        {
          key: 'phone_number',
          type: 'text',
          label: 'Phone Number'
        },
        {
          key: 'email',
          type: 'text',
          label: 'Email',
          autoComplete: 'email',
          gridProps: { xs: 12, sm: 6 }
        },
        {
          key: 'email2',
          type: 'text',
          label: 'Email 2',
          gridProps: { xs: 12, sm: 6 }
        },
        {
          key: 'email3',
          type: 'text',
          label: 'Email 3',
          gridProps: { xs: 12, sm: 6 }
        },
        {
          key: 'email4',
          type: 'text',
          label: 'Email 4',
          gridProps: { xs: 12, sm: 6 }
        },
        {
          key: 'credit_limit',
          type: 'text',
          label: 'Credit Limit',
          gridProps: { xs: 12, sm: 6 }
        },
        {
          key: 'discount',
          type: 'text',
          label: 'Discount %',
          min: 0,
          max: 100,
          gridProps: { xs: 12, sm: 6 }
        },
        {
          key: 'currency',
          type: 'currency',
          label: 'Currency',
          gridProps: { xs: 12, sm: 6 }
        },
        {
          key: 'exchange_rate',
          type: 'text',
          label: 'Currency exchange rate to 1 USD',
          gridProps: { xs: 12, sm: 6 }
        },
        {
          key: 'agreement',
          type: 'file',
          label: 'Upload Distributor Agreement'
        }
      ];

      if ( isTest && props.add ) {
        fields = [
          {
            key: 'create_demo',
            type: 'checkbox',
            label: 'Create Demo Products',
            gridProps: { sx: { mb: '-25px' } }
          },
          ...fields
        ];
      }
      return fields;
    },
    [isTest, props.add]
  );

  const requiredFields = useMemo(() => {
    let retRequiredFields = ['company_name', 'first_name', 'last_name', 'username', 'country', 'address', 'email', 'credit_limit', 'discount', 'phone_number', 'agreement', 'exchange_rate', 'currency'];
    if (props.add) {
      retRequiredFields.push('password');
    } else {
      retRequiredFields = retRequiredFields.filter((e) => e !== 'agreement');
    }
    return retRequiredFields;
  }, [props.add]);
  const emailFields = useMemo(() => ['email', 'email2', 'email3', 'email4'], []);
  const numericFields = useMemo(() => ['credit_limit', 'discount', 'phone_number'], []);
  const greaterThanZeroFields = useMemo(() => ['exchange_rate'], []);
  const [distributor, setDistributor] = useState({ country: 'United States', 'exchange_rate': 1, 'currency': 'USD' });
  const [errors, setErrors] = useState({});
  const [submitOnce, setSubmitOnce] = useState(false);
  const navigate = useNavigate();
  const paramCompanyId = (incomingParams && incomingParams.CompanyId ? incomingParams.CompanyId : -1);

  useEffect(() => {
    if (!props.add) {
      const params = {};
      if (paramCompanyId !== -1) {
        params.CompanyId = paramCompanyId;
      }
      ajaxGetAdminDistributors(params)
        .then((res) => {
          const { data } = res;
          setDistributor(data);
        })
        .catch(() => {
          setDistributor({});
        });
    }
  }, [paramCompanyId, props.add]);

  const validateFields = useCallback(() => {
    const fields = { ...distributor };
    let newErros = {};
    let result = true;
    for (let index in allFields) {
      let key = allFields[index].key;
      let minLength = allFields[index].minLength;
      let confirm = allFields[index].confirm;
      let min = allFields[index].min;
      let max = allFields[index].max;
      let fieldValue = fields[key] !== undefined && fields[key] !== null ? fields[key] + '' : '';
      let fieldConfirmValue = confirm && fields[confirm] ? fields[confirm] + '' : '';
      if (requiredFields.includes(key) && validator.isEmpty(fieldValue, { ignore_whitespace: true })) {
        newErros[key] = 'required field';
        result = false;
      } else if (fieldValue && minLength > 0 && !validator.isLength(fieldValue, { min: minLength, max: undefined })) {
        newErros[key] = `This field must be at least ${minLength} characters in length`;
        result = false;
      } else if (fieldValue && emailFields.includes(key) && !validator.isEmail(fieldValue)) {
        newErros[key] = 'Please enter a valid email address';
        result = false;
      } else if (confirm && fieldValue !== fieldConfirmValue) {
        newErros[key] = 'Please enter the same value again.';
        result = false;
      } else if (fieldValue && !isNaN(min) && !isNaN(max) && (isNaN(fieldValue) || parseFloat(fieldValue) < min || parseFloat(fieldValue) > max)) {
        newErros[key] = `Please enter a value between ${min} and ${max}.`;
        result = false;
      } else if ( fieldValue && greaterThanZeroFields.includes(key) && (isNaN(fieldValue) || parseFloat(fieldValue) <= 0 ) ) {
        newErros[key] = 'Please enter a value greater than 0';
        result = false;
      }
    }
    setErrors(newErros);
    return result;
  }, [distributor, allFields, emailFields, requiredFields, greaterThanZeroFields]);

  useEffect(() => {
    if (!props.add) {
      validateFields();
    } else if (props.add && submitOnce) {
      validateFields();
    }
  }, [validateFields, props.add, submitOnce]);

  const handleSave = () => {
    setSubmitOnce(true);
    if (!validateFields()) return false;

    const params = { ...distributor };
    let url = '/dashboard/distributors';

    ajaxSaveAdminDistributor(params)
      .then((res) => {
        navigate(url);
      })
      .catch(() => {
        navigate(url);
      });
  };

  const handleFieldChange = (event, val) => {
    let value = val !== undefined ? val : event.target.value,
      key = event.target.name;

    if (numericFields.includes(key)) {
      value = value.replace(/\D/g, '');
    }
    setDistributor({ ...distributor, [key]: value });
  };

  const getFieldValue = (key) => {
    return distributor && distributor[key] !== undefined && distributor[key] !== null ? distributor[key] : '';
  };

  const getFieldPlaceholder = (key) => {
    return key === 'password' && !props.add ? 'leave blank to not change the password' : '';
  };

  const getFieldErrorText = (key) => {
    if (errors && errors[key]) return errors[key];
    else return '';
  };

  const checkFieldError = (key) => {
    if (errors && errors[key]) return true;
    else return false;
  };

  const handleUploadFile = (e) => {
    let key = e.target.name;
    let value = e.target.files[0];
    setDistributor({ ...distributor, [key]: value });
  };

  const handleDistributorToggle = (isEnabled, id) => {
    let ajaxMethod = ajaxDisableAdminDistributor;
    if (isEnabled) {
      ajaxMethod = ajaxEnableAdminDistributor;
    }

    ajaxMethod({ id: id })
      .then((res) => {
        const { data } = res;
        setDistributor(data);
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  const buttonStyle = {
    height: '40px',
    width: '150px',
    fontSize: '12px'
  };

  const uniqueCurrency = getAllISOCodes()
                        .reduce((sum, cur) => (sum.filter(s => s.currency === cur.currency).length <= 0 ? [...sum, { currency: cur.currency, symbol: cur.symbol }] : sum), [])
                        .sort((a, b) => {
                          if ( a.currency === 'USD' ) {
                            return -1;
                          }
                          if ( b.currency === 'USD' ) {
                            return 1;
                          }
                          return a.currency.localeCompare(b.currency);
                        })
  return (
    <Page title="Add / Edit Distributor">
      <StyledCard>
        <StyledCardFormContent>
          <StyledMainGrid columnSpacing>
            {allFields.map((entry, index) => (
              <React.Fragment key={entry['key']}>
                {
                  (entry['type'] === 'text' || entry['type'] === 'password') &&
                  <Grid item xs={12} {...entry['gridProps']}>
                    <TextField
                      label={entry['label']}
                      type={entry['type']}
                      name={entry['key']}
                      value={getFieldValue(entry['key'])}
                      onChange={handleFieldChange}
                      error={checkFieldError(entry['key'])}
                      helperText={getFieldErrorText(entry['key'])}
                      placeholder={getFieldPlaceholder(entry['key'])}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>
                }
                {
                  entry['type'] === 'country'&&
                  <Grid item xs={12} {...entry['gridProps']}>
                    <FormControl fullWidth>
                      <InputLabel id={`select-helper-label-${entry['key']}`}>{entry['label']}</InputLabel>
                      <Select
                        labelId={`select-helper-label-${entry['key']}`}
                        label={entry['label']}
                        type={entry['type']}
                        name={entry['key']}
                        value={getFieldValue(entry['key'])}
                        onChange={(e) => handleFieldChange(e)}
                        error={checkFieldError(entry['key'])}
                        helperText={getFieldErrorText(entry['key'])}
                        sx={{ width: '100%' }}
                      >
                        { countries.map( c =>
                          <MenuItem value={c.name}>{c.name}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                }
                {
                  entry['type'] === 'file' &&
                  <Grid item xs={12} {...entry['gridProps']}>
                    <FileUploader
                      error={checkFieldError(entry['key'])}
                      accept=".pdf"
                      name={entry['key']}
                      label={entry['label']}
                      icon={<AttachFileIcon />}
                      handleChange={handleUploadFile}
                    />
                  </Grid>
                }
                {
                  entry['type'] === 'checkbox' &&
                  <Grid item xs={12} {...entry['gridProps']}>
                    <FormControlLabel
                      control={<Checkbox name={entry['key']} value={1}/>}
                      onChange={(e, val) => handleFieldChange(e, val ? 1 : 0)}
                      checked={!!parseInt(getFieldValue(entry['key'], 10))}
                      label={entry['label']}
                    />
                  </Grid>
                }
                {
                  entry['type'] === 'currency'&&
                  <Grid item xs={12} {...entry['gridProps']}>
                    <FormControl fullWidth>
                      <InputLabel id={`select-helper-label-${entry['key']}`}>{entry['label']}</InputLabel>
                      <Select
                        labelId={`select-helper-label-${entry['key']}`}
                        label={entry['label']}
                        type={entry['type']}
                        name={entry['key']}
                        value={getFieldValue(entry['key'])}
                        onChange={(e) => handleFieldChange(e)}
                        error={checkFieldError(entry['key'])}
                        helperText={getFieldErrorText(entry['key'])}
                        sx={{ width: '100%' }}
                        disabled={!props.add}
                      >
                        {uniqueCurrency.map( c =>
                          <MenuItem value={c.currency}>{c.currency} {c.symbol === c.currency ? '' : `(${c.symbol})`}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                }
              </React.Fragment>
            ))}
            <Grid item xs={12}>
              {distributor && distributor['active'] > 0 && (
                <Button onClick={() => handleDistributorToggle(false, distributor['id'])} variant="contained" size="normal" style={buttonStyle} sx={{ mr: '20px', backgroundColor: 'red' }}>
                  Disable Distributor
                </Button>
              )}
              {distributor && distributor['active'] < 1 && (
                <Button onClick={() => handleDistributorToggle(true, distributor['id'])} variant="contained" size="normal" style={buttonStyle} sx={{ mr: '20px' }}>
                  Enable distributor
                </Button>
              )}
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={12}>
              <Button variant="contained" size="normal" style={buttonStyle} sx={{ backgroundColor: 'green', mr: '20px' }} onClick={handleSave}>
                Save Distributor
              </Button>
              <Button variant="outlined" size="normal" style={buttonStyle} component={Link} to="/dashboard/distributors">
                Cancel
              </Button>
            </Grid>
          </StyledMainGrid>
        </StyledCardFormContent>
      </StyledCard>
      {
        !props.add && distributor.company_id &&
        <Box sx={{ mt: '53px' }}>
          <DistributorProductDiscounts companyId={distributor.company_id}/>
        </Box>
      }
      {
        !props.add && distributor.company_id &&
        <Box sx={{ mt: '53px' }}>
          <DistributorProductOptionDiscounts companyId={distributor.company_id}/>
        </Box>
      }
    </Page>
  );
}
