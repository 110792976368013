import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { ajaxGetAdminDistributorsAutocomplete } from '../services/adminService';

export default function AdminDistributorAutocomplete({ setSearchResult, defaultCompanyId, ...props }) {
  const [items, setItems] = React.useState([]);
  const [selected, setSelected] = React.useState(null);

  useEffect(() => {
    ajaxGetAdminDistributorsAutocomplete()
      .then((res) => {
        const { data } = res;
        setItems(data);

        let filterCompany = data.filter(i => i.id === parseInt(defaultCompanyId, 10));
        if ( filterCompany.length > 0 ) {
          setSelected(filterCompany[0]);
          setSearchResult(filterCompany[0].id);
        }
      })
      .catch(() => {
        setItems([]);
      });
  }, []);

  const searchChangeHandle = (event, value) => {
    setSearchResult(value ? value.id : '');
    setSelected(value);
  };

  return (
    <Autocomplete
      {...props}
      disablePortal
      value={selected}
      options={items}
      isOptionEqualToValue={(option, value) => (value && option.id === value.id)}
      getOptionLabel={(option) => option.company_name}
      onChange={searchChangeHandle}
      renderInput={(params) => <TextField {...params} label="Distributor Name" />}
    />
  );
}
