import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import IconButton from '@mui/material/IconButton';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import { ajaxGetAdminDistributors } from '../services/adminService';
import { MoneyFormat } from '../components/MoneyFormat';
import { downloadFile } from '../helpers';
import Page from '../components/Page';
import StyledCard from '../components/StyledCard'
import StyledCardSearch from '../components/StyledCardSearch';
import StyledList from '../components/StyledList';
import StyledCardFormContent from '../components/StyledCardFormContent';

export default function DistributorList() {
  const [items, setItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const navigate = useNavigate();
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));

  const renderItem = (data, idx, secondarySx) => {
    return (
      <ListItem
        key={idx}
        disablePadding
        secondaryAction={
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column-reverse', sm: 'row' }, alignItems: { xs: 'flex-end', sm: 'center' }}}>
            <Box>
              <Tooltip title="Credit Limit">
                <Box component="span" sx={{ fontSize: { xs: '14px' }}}>
                  <MoneyFormat prefix="US$ " value={data.credit_limit} />
                </Box>
              </Tooltip>
            </Box>
            <Box>
              <Tooltip title="Distributor Agreement">
                <IconButton
                  onClick={() =>
                    downloadFile({
                      params: { id: data.company_id, fileType: 'agreement', role: 'administrator' },
                      fileName: data.company_id + '_' + data.id,
                      fileExt: 'pdf'
                    })
                  }
                >
                  <PictureAsPdfIcon />
                </IconButton>
              </Tooltip>
              <IconButton
                component={RouterLink}
                to={'/dashboard/distributors/edit/' + data.company_id}
              >
                <EditOutlinedIcon />
              </IconButton>
            </Box>
          </Box>
        }
      >
        <ListItemButton component={RouterLink} to={'/dashboard/distributors/edit/' + data.company_id}>
          <ListItemText
            primary={
              <Box sx={{ display: 'flex' }}>
                <Box>{data.company_name}</Box>
                {
                  smUp &&
                  <Chip label={data.country} size="small" variant="outlined" sx={{ ml: '10px' }} />
                }
                {
                  !data.active &&
                  <Chip label="Disabled" size="small" color="error" sx={{ ml: '10px' }} />
                }
                {
                  smUp && data.internal === 1 &&
                  <Chip label="Internal" size="small" color="info" sx={{ ml: '10px' }} />
                }
              </Box>
            }
            secondary={
              <Box>
                <Tooltip title={`${data.email}, Tel ${data.phone_number}`}>
                  {
                    smUp ?
                    <Box>
                      {`${data.email}, Tel ${data.phone_number}`}
                    </Box>
                    :
                    <Box>
                      {data.country}
                      {
                        data.internal === 1 &&
                        <Chip label="Internal" size="small" color="info" sx={{ ml: '10px' }} />
                      }
                    </Box>
                  }
                </Tooltip>
                
              </Box>
            }
          />
        </ListItemButton>
      </ListItem>
    );
  };

  const searchChangeHandle = (event) => {
    const new_value = event.target.value.toLowerCase();
    setSearchTerm(new_value);
  };

  useEffect(() => {
    ajaxGetAdminDistributors({})
      .then((res) => {
        const { data } = res;
        setItems(data);
      })
      .catch(() => {
        setItems([]);
      });
  }, []);

  useEffect(() => {
    const results = items.filter((item) => `${(item.company_name || '')}${(item.country || '')}${(item.email || '')}`.toLowerCase().includes(searchTerm));

    setSearchResults(results);
  }, [searchTerm, /*searchFilter,*/ items]);

  return (
    <Page title="Add / Edit Distributor">
      <StyledCard>
        <StyledCardSearch onSearch={searchChangeHandle} />
        <StyledCardFormContent>
          <StyledList
            addRow
            onAdd={() => navigate('/dashboard/distributors/add')}
            dataSource={searchResults}
            renderItem={renderItem}
            sort={{
              sortKey: 'company_name',
              sortOptions: [
                {
                  key: 'company_name',
                  label: 'Distributor Name'
                },
                {
                  key: 'country',
                  label: 'Country'
                },
                {
                  key: 'email',
                  label: 'E-mail'
                },
                {
                  key: 'phone_number',
                  label: 'Phone Number'
                },
                {
                  key: 'credit_limit',
                  label: 'Credit Limit'
                },
                {
                  key: 'active',
                  label: 'Disabled'
                }
              ]
            }}/>
        </StyledCardFormContent>
      </StyledCard>
    </Page>
  );
}
