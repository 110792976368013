import React, { useState, useEffect, useCallback } from 'react';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { useNavigate, useParams } from 'react-router-dom';
import { ajaxGetAdminProducts, ajaxSaveAdminProduct, ajaxDisableAdminProduct, ajaxEnableAdminProduct, ajaxGetAdminProductOptions, ajaxDisableAdminProductOption, ajaxEnableAdminProductOption } from '../../services/adminService';
import { ajaxDeleteProductImg, ajaxDownloadImgFile, getProductImageUrl } from '../../services/fileService';
import ProductOptions from './ProductOptions';
import ProductActions from './ProductActions';
import StyledMainGrid from '../../components/StyledMainGrid';
import FileUploader from '../../components/FileUploader';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Button from '@mui/material/Button';
import ConfirmationButton from '../../components/ConfirmationButton';
import Page from '../../components/Page';
import StyledCard from '../../components/StyledCard'
import StyledCardFormContent from '../../components/StyledCardFormContent';
import validator from 'validator';

export default function Product(props) {
  const incomingParams = useParams();

  const [product, setProduct] = useState({code: '', msrp: 0, descritpion: '', type: 'base unit'});
  const [errors, setErrors] = useState({});
  const [productOptions, setProductOptions] = useState([]);
  const [isProcessImg, setIsProcessImg] = useState(false);
  const [submitOnce, setSubmitOnce] = useState(false);
  const paramProductId = (incomingParams && incomingParams.ProductId ? incomingParams.ProductId : -1);

  const navigate = useNavigate();

  const validateFields = useCallback(() => {
    var newErros = {};
    const fields = { ...product };
    var result = true;
    for (let key in fields) {
      let fieldValue = fields[key] !== undefined && fields[key] !== null ? fields[key] + '' : '';
      if (['code', 'msrp'].indexOf(key) !== -1 && validator.isEmpty(fieldValue, { ignore_whitespace: true })) {
        newErros[key] = 'required field';
        result = false;
      } else if (key === 'msrp' && (isNaN(parseFloat(fieldValue)) || parseFloat(fieldValue) < 0 )) {
        newErros[key] = `Please enter a value greater or equal than 0.`;
        result = false;
      }
    }
    setErrors(newErros);
    return result;
  }, [product]);

  useEffect(() => {
    if (submitOnce) {
      validateFields();
    }
  }, [validateFields, submitOnce]);

  useEffect(() => {
    if (!props.add) {
      const params = {};

      if (paramProductId !== -1) {
        params.ProductId = paramProductId;
      }

      ajaxGetAdminProducts(params)
        .then((res) => {
          const { data } = res;
          setProduct(data);
        })
        .catch(() => {
          setProduct(false);
        });
    }
  }, [paramProductId, props.add]);

  useEffect(() => {
    if (!props.add) {
      const params = {};

      if (paramProductId !== -1) {
        params.ProductId = paramProductId;
      }

      ajaxGetAdminProductOptions(params)
        .then((res) => {
          const { data } = res;
          setProductOptions(data);
        })
        .catch(() => {
          setProductOptions(false);
        });
    }
  }, [paramProductId, props.add]);

  const handleUploadImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      let copyProduct = { ...product };
      copyProduct[event.target.name] = event.target.files[0];

      setProduct(copyProduct);
    }
  };

  const handleProductFieldChange = (event) => {
    const value = event.target.value,
      key = event.target.name;

    let copyProduct = { ...product };
    copyProduct[key] = value;

    setProduct(copyProduct);
  };

  const getFieldValue = (key) => {
    return product ? product[key] : '';
  };

  const handleSaveProduct = () => {
    setSubmitOnce(true);
    if ( !validateFields() ) {
      return;
    }

    const params = { ...product };
    let url = '/dashboard/products';

    ajaxSaveAdminProduct(params)
      .then((res) => {
        //const { data, } = res;
        //setProduct(data);

        navigate(url);
      })
      .catch(() => {
        //setProduct(false);
        navigate(url);
      });
  };

  const handleToggleProductOptionActive = (option, index) => {
    const params = { id: option.id };
    let copyProductOptions = [...productOptions];

    if (option.active > 0) {
      ajaxDisableAdminProductOption(params)
        .then((res) => {
          //   const { data, } = res;

          copyProductOptions[index].active = !copyProductOptions[index].active;
          setProductOptions(copyProductOptions);
        })
        .catch(() => {});
    } else {
      ajaxEnableAdminProductOption(params)
        .then((res) => {
          //    const { data, } = res;

          copyProductOptions[index].active = !copyProductOptions[index].active;
          setProductOptions(copyProductOptions);
        })
        .catch(() => {});
    }
  };

  const handleProductDisable = () => {
    const params = { ProductId: product.id };

    ajaxDisableAdminProduct(params)
      .then((res) => {
        const { data } = res;
        setProduct(data);
      })
      .catch(() => {
        console.log('error while disabling admin product');
      });
  };

  const handleProductEnable = () => {
    const params = { ProductId: product.id };

    ajaxEnableAdminProduct(params)
      .then((res) => {
        const { data } = res;
        setProduct(data);
      })
      .catch(() => {
        console.log('error while enabling admin product');
      });
  };

  const handleProductOptionSave = () => {
    const params = { ProductId: product.id };

    ajaxGetAdminProductOptions(params)
      .then((res) => {
        const { data } = res;
        setProductOptions(data);
      })
      .catch(() => {
        setProductOptions(false);
      });
  };

  const handleDownloadImage = () => {
    setIsProcessImg(true);
    ajaxDownloadImgFile(product.id, product.code).then(() => {
      setIsProcessImg(false);
    }).catch(() => {
      setIsProcessImg(false);
    });
  };

  const handleDeleteImage = () => {
    setIsProcessImg(true);
    ajaxDeleteProductImg(product.id).then((res) => {
      let copyProduct = { ...product };
      copyProduct.image = res.data.image;
      setProduct(copyProduct);
      setIsProcessImg(false);
    }).catch(() => {
      setIsProcessImg(false);
    });
  };

  const getFieldErrorText = (key) => {
    if (errors && errors[key]) return errors[key];
    else return '';
  };

  const checkFieldError = (key) => {
    if (errors && errors[key]) return true;
    else return false;
  };

  const style = {
    button: {
      marginLeft: '10px',
      height: '40px',
      width: '130px',
      fontSize: '12px'
    },
    bgImg: {
      width: '100%',
      objectFit: 'contain'
    },
  };

  return (
    <Page title="Add / Edit Product">
      <StyledCard>
        <StyledCardFormContent>
          <StyledMainGrid>
            <Grid item xs={12}>
              <TextField
                label="Product Code"
                type="text"
                name="code"
                value={getFieldValue('code')}
                onChange={handleProductFieldChange}
                error={checkFieldError('code')}
                helperText={getFieldErrorText('code')}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="product-type-select-helper-label">Type</InputLabel>
                <Select
                  labelId="product-type-select-helper-label"
                  name="type"
                  label="Type"
                  value={getFieldValue('type')}
                  onChange={handleProductFieldChange}
                  sx={{ width: '100%' }}
                >
                  <MenuItem value={'base unit'}>Base Unit</MenuItem>
                  <MenuItem value={'sensor'}>Sensor</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="MSRP"
                type="number"
                name="msrp"
                value={getFieldValue('msrp')}
                onChange={handleProductFieldChange}
                error={checkFieldError('msrp')}
                helperText={getFieldErrorText('msrp')}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="description"
                label="Description"
                type="text"
                multiline
                rows={5}
                value={getFieldValue('description')}
                InputLabelProps={{ shrink: true }}
                onChange={handleProductFieldChange}
                fullWidth
              />
            </Grid>
            <Grid item container xs={12} alignItems="center">
              <Grid item xs={12} sm={6}>
                <Box sx={{ mb: '10px', display: 'flex', flexDirection: { xs: 'column', sm: 'row' }}}>
                  <Box sx={{ mb: '10px' }}>
                    <FileUploader accept="image/*" name="upload_image" label="Product Image" icon={<AttachFileIcon />} handleChange={handleUploadImage} />
                  </Box>
                  {
                    product.image &&
                    <Box sx={{ mb: '10px' }}>
                      <Button variant="contained" size="normal" style={style.button} disabled={isProcessImg} sx={{ mr: '20px' }} onClick={handleDownloadImage}>
                        Download Image
                      </Button>
                      <ConfirmationButton
                        buttonProps={{ variant: 'contained', size: 'normal', style: style.button, sx: { backgroundColor: 'red' } }}
                        handleDialogClickYes={handleDeleteImage}
                        buttonText="Delete Image"
                        dialogText="Are you sure you want to delete the product image?"
                        dialogYesText="Confirm"
                        dialogNoText="Cancel"
                      />
                    </Box>
                  }
                </Box>
              </Grid>
              {
                product.image &&
                <Grid item xs={12} sm={6}>
                  <Box sx={{ width: '50%' }}>
                    <img style={style.bgImg} alt="bg" src={getProductImageUrl(product.id)} />
                  </Box>
                </Grid>
              }
            </Grid>
            {
              product && product.id && productOptions &&
              <Grid item xs={12}>
                <ProductOptions product={product} productOptions={productOptions} handleToggleActive={handleToggleProductOptionActive} handleOptionSave={handleProductOptionSave} />
              </Grid>
            }
            <Grid item xs={12}>
              <ProductActions product={product} handleSaveProduct={handleSaveProduct} handleProductDisable={handleProductDisable} handleProductEnable={handleProductEnable} />
            </Grid>
          </StyledMainGrid>
        </StyledCardFormContent>
      </StyledCard>
    </Page>
  );
}
