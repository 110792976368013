import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import Button from '@mui/material/Button';
import StyledSearchDate from '../../components/StyledSearchDate';
import InvoicePaymentHeader from './InvoicePaymentHeader';
import StyledMainGrid from '../../components/StyledMainGrid';
import { MoneyFormat } from '../../components/MoneyFormat';
import { ajaxGetAdminDistributors, ajaxGetAdminDistributorInvoice } from '../../services/adminService';
import { ajaxGetCompanyInfo } from '../../services/companyService';
import { ajaxGetInvoicePayment, ajaxGetInvoicePaymentFile } from '../../services/paymentService';
import Page from '../../components/Page';
import StyledCard from '../../components/StyledCard'
import StyledCardFormContent from '../../components/StyledCardFormContent';
import StyledList from '../../components/StyledList';
import ReceivePaymentDialog from '../Dashboard/ReceivePaymentDialog';
import InvoicePayDialog from './InvoicePayDialog';
import useAlert from '../../hooks/useAlert'
import moment from 'moment';

export default function Payment(props) {
  const showDistributorPaymentLocalCurrency = !!window.SHOW_DIST_PAY_LOCAL_CUR;
  const [companyInvoiceInfo, setCompanyInvoiceInfo] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [distributor, setDistributor] = useState(null);
  const [companyInfo, setCompanyInfo] = useState(false);
  const [openReceivePayment, setOpenReceivePayment] = useState(false);
  const [openReceiveInvoicePayment, setOpenReceiveInvoicePayment] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const errorAlert = useAlert(false, 'error');
  const incomingParams = useParams();
  const isAdmin = window.IS_ADMIN;
  const paramCompanyId = (incomingParams && incomingParams.CompanyId ? incomingParams.CompanyId : -1);

  const loadData = useCallback(() => {
    let params = {};

    if (paramCompanyId !== -1) {
      params.companyId = paramCompanyId;
    }
    ajaxGetInvoicePayment(params)
      .then((res) => {
        const { data } = res;

        if ( data && data.company && Array.isArray(data.company) ) {
          let companyInvoiceInfo = data.company[0];
          setCompanyInvoiceInfo(companyInvoiceInfo);
        }
        else {
          setCompanyInvoiceInfo(false);
        }
      })
      .catch(() => {
        setCompanyInvoiceInfo(false);
      });

    if ( isAdmin && params.companyId ) {
      ajaxGetAdminDistributors({ CompanyId: params.companyId })
        .then((res) => {
          const { data } = res;
          setDistributor(data);
        })
        .catch(() => {
          setDistributor(null);
        });
    }
    else {
      ajaxGetCompanyInfo()
      .then((res) => {
        const { data } = res;
        setCompanyInfo(data);
      })
      .catch(() => {
        setCompanyInfo(false);
      });
    }
  }, [paramCompanyId, isAdmin])

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    let saleItems = companyInvoiceInfo ? companyInvoiceInfo.saleItems : [];
    let items = Array.isArray(saleItems) ? [...saleItems] : [];
    const results = items.filter((item) => {
      let testStartDate = true;
      let testEndDate = true;

      let dateKey = 'date';
      if (startDate) {
        testStartDate = moment.unix(item[dateKey]).utc().isSameOrAfter( moment(startDate).utc(), 'month' );
      }
      if (endDate) {
        testEndDate = moment.unix(item[dateKey]).utc().isSameOrBefore( moment(endDate).utc(), 'month' );
      }
      return testStartDate && testEndDate;
    });

    setSearchResults(results);
  }, [companyInvoiceInfo, startDate, endDate]);

  const onReceivePaymentClose = (params) => {
    if ( !isAdmin ) {
      return;
    }
    setOpenReceivePayment(false);
    if (params['button'] === 'save') {
      loadData();
    }
  };

  const onReceiveInvoicePaymentClose = (reload) => {
    setOpenReceiveInvoicePayment(false);
    if (reload) {
      loadData();
    }
  };

  const onDownloadFile = (datetime) => { 
    errorAlert.resetAlert();
    setIsDownloading(true);

    let companyId = companyInvoiceInfo.id || 0;
    let companyName = companyInvoiceInfo.company_name || 'NONAME';
    let customerRef = `00000${companyInvoiceInfo.id}`.substr(-5);
    let filename = (
      companyId === 0 ?
      `${companyName}-${moment.unix(datetime).utc().format('MMM-YYYY')}.zip`
      :
      `${companyName}-INV${moment.unix(datetime).utc().format('MM')}${moment.unix(datetime).utc().daysInMonth()}${moment.unix(datetime).utc().format('YYYY')}${customerRef}.pdf`
    );
    // validate filename
    filename = filename.replace(/[\s/\\?%*:|"<>]/g, '');
    (
      isAdmin ?
      ajaxGetAdminDistributorInvoice(
        {
          companyId: companyId,
          datetime: datetime
        },
        filename
      )
      :
      ajaxGetInvoicePaymentFile(
        {
          companyId: companyId,
          datetime: datetime
        },
        filename
      )
    )
    .then(() => {
      setIsDownloading(false);
    })
    .catch((e) => {
      errorAlert.setAlert(e.message);
      setIsDownloading(false);
    });
  };

  const renderItem = (data, idx, secondarySx) => {
    const buttonSx = {
      height: '30px',
      fontSize: '12px',
      marginLeft: '10px',
      minWidth: { xs: '50px', sm: '64px' }
    };
    const isFullyPaid = (data.total > 0 && data.paid_amount >= data.total);
    const isPatialPaid = (data.paid_amount > 0 && data.paid_amount < data.total);
    const altSecondarySx = {
      ...secondarySx,
      fontSize: '13px',
      lineHeight: '15px'
    };
    const altSecondaryColorSx = {
      ...secondarySx,
      ...(isFullyPaid ? {color: 'green'} : {}),
      ...(isPatialPaid ? {color: '#e65100'} : {}),
      fontSize: '13px',
      lineHeight: '15px'
    };
    return (
      <ListItem
        key={idx}
        disablePadding
        secondaryAction={
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column-reverse', sm: 'row' }, alignItems: { xs: 'flex-end', sm: 'center' }}}>
            {
              <Box sx={{ display: 'flex', flexDirection: { xs: 'column-reverse', sm: 'column' }, textAlign: { xs: 'left', sm: 'right' }, mr: { xs: '0px', sm: '10px' } }}>
                <Box sx={altSecondaryColorSx}>
                  <Box sx={{ fontWeight: 'bold' }}>
                    {
                      isFullyPaid &&
                      (
                        showDistributorPaymentLocalCurrency ?
                        <MoneyFormat currency={data.currency} value={data.paid_amount * (data.total_local / (data.total || 1))} />
                        :
                        <MoneyFormat value={data.paid_amount} />
                      )
                    }
                    {
                      isPatialPaid &&
                      (
                        showDistributorPaymentLocalCurrency ?
                        <Box sx={{ display: 'flex' }}>
                          <MoneyFormat currency={data.currency} value={data.paid_amount * (data.total_local / (data.total || 1))} />
                          &nbsp;/&nbsp;
                          <MoneyFormat currency={data.currency} value={data.total_local} />
                        </Box>
                        :
                        <Box sx={{ display: 'flex' }}>
                          <MoneyFormat value={data.paid_amount} />&nbsp;/&nbsp;<MoneyFormat value={data.total} />
                        </Box>
                      )
                    }
                    {
                      !isFullyPaid && !isPatialPaid &&
                      (
                        showDistributorPaymentLocalCurrency ?
                        <MoneyFormat currency={data.currency} value={data.total_local} />
                        :
                        <MoneyFormat value={data.total} />
                      )
                    }
                  </Box>
                </Box>
                <Box sx={altSecondaryColorSx}>
                  { isFullyPaid && 'Fully Paid' }
                  { isPatialPaid && 'Partial Paid' }
                  { !isFullyPaid && !isPatialPaid && data.total > 0 && 'Pending' }
                  { !isFullyPaid && !isPatialPaid && data.total <= 0 && '----' }
                </Box>
              </Box>
            }
            <Box>
              <IconButton
                onClick={() => onDownloadFile(data.date)}
                disabled={isDownloading}
              >
                <RequestQuoteIcon />
              </IconButton>
              {
                isAdmin &&
                <Button
                  variant="contained"
                  size="normal"
                  sx={buttonSx}
                  disabled={data.total <= 0 || isFullyPaid}
                  onClick={() => setOpenReceiveInvoicePayment({ data: {...data, balance: companyInvoiceInfo.balance} })}
                >
                  Pay
                </Button>
              }
            </Box>
          </Box>
        }
      >
        <ListItemButton>
          <ListItemText
            primary={moment.unix(data.date).utc().format('MMMM YYYY')}
            secondary={<Box sx={{...altSecondarySx}}>{data.invoice_no}</Box>}
          />
        </ListItemButton>
      </ListItem>
    );
  };

  return (
    <Page
      title={[
        ...(
          distributor ?
          [distributor.company_name]
          :
          []
        ),
        'View Invoice Payment'
      ]}
      backButton={
        distributor ?
        <IconButton color="primary" component={RouterLink} to={`/dashboard/distributor/${distributor.company_id}`}>
          <ArrowBackIcon fontSize="large" sx={{ mt: '3px' }}/>
        </IconButton>
        :
        undefined
      }
    >
      <StyledCard>
        <StyledCardFormContent sx={{ mb: { sm: '-72px' } }}>
          <StyledMainGrid>
            <Grid xs={12} item>
              <StyledSearchDate startDate={startDate} endDate={endDate} onStartDate={setStartDate} onEndDate={setEndDate} />
            </Grid>
            <Grid xs={12} item>
              <InvoicePaymentHeader items={searchResults} balance={companyInvoiceInfo ? companyInvoiceInfo.balance : 0} currency={companyInfo ? companyInfo.currency : null} exchangeRate={companyInfo ? companyInfo.exchange_rate : 1}/>
            </Grid>
            <Grid xs={12} item sx={{ mb: '-60px' }}>
              {
                isAdmin &&
                <Button
                  variant="contained"
                  size="small"
                  sx={{ mr: '10px' }}
                  onClick={() => setOpenReceivePayment(true)}
                >
                  Receive Payment
                </Button>
              }
              <Button
                variant="contained"
                size="small"
                component={RouterLink}
                to={
                  isAdmin ?
                  '/dashboard/payment/' + encodeURIComponent(paramCompanyId)
                  :
                  '/dashboard/payment'
                }
              >
                View Payment
              </Button>
            </Grid>
          </StyledMainGrid>
        </StyledCardFormContent>
        <StyledCardFormContent>
          <StyledList
            noEmptyRow
            dataSource={searchResults}
            renderItem={renderItem}
            sort={{
              sort: 'desc',
              sortKey: 'date',
              sortOptions: [
                {
                  key: 'date',
                  label: 'Date'
                },
                {
                  key: 'total',
                  label: 'Invoice Amount'
                },
                {
                  key: 'paid_amount',
                  label: 'Paid Amount'
                }
              ]
            }}/>
        </StyledCardFormContent>
      </StyledCard>
      {
        isAdmin &&
        openReceivePayment &&
        <ReceivePaymentDialog
          dialogTitle="Receive Payment"
          dialogText="Enter the amount of money the company has paid"
          dialogNoText="Cancel"
          companyId={paramCompanyId}
          show={openReceivePayment}
          onHide={onReceivePaymentClose}
        />
      }
      {
        isAdmin &&
        !!openReceiveInvoicePayment &&
        <InvoicePayDialog
          companyId={paramCompanyId}
          data={openReceiveInvoicePayment.data}
          show={!!openReceiveInvoicePayment}
          onHide={onReceiveInvoicePaymentClose}
        />
      }
    </Page>
  );
}
