import React from 'react';
import Grid from '@mui/material/Grid';
import StyledMainGrid from '../../components/StyledMainGrid';
import DistributorProductDiscounts from '../DistributorProductDiscounts';
import DistributorProductOptionDiscounts from '../DistributorProductOptionDiscounts';
import Page from '../../components/Page';

export default function Discount() {
  return (
    <Page title="Add / Edit Discount">
      <StyledMainGrid>
        <Grid item xs={12}>
          <DistributorProductDiscounts companyId={0}/>
        </Grid>
        <Grid item xs={12} sx={{ mt: '53px' }}>
          <DistributorProductOptionDiscounts companyId={0}/>
        </Grid>
        <Grid item xs={12} sx={{ pb: '64px' }}/>
      </StyledMainGrid>
    </Page>
  );
}
