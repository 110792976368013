import React, {useMemo} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import BarChartIcon from '@mui/icons-material/BarChart';
import StyledMainGrid from '../../components/StyledMainGrid';
import { MoneyFormat } from '../../components/MoneyFormat';

export default function PaymentHeader({items, sold, currency}) {
  const showDistributorPaymentLocalCurrency = !!window.SHOW_DIST_PAY_LOCAL_CUR;
  const stats = useMemo(() => {
    let amount = 0;
    let amount_local = 0;
    items.forEach((item) => {
      amount += parseFloat(item.amount);
      amount_local += parseFloat(item.amount_local);
    });

    return {
      totalTransactions: items.length,
      totalAmount: amount,
      totalAmountLocal: amount_local
    };
  }, [items]);

  const typographySx = {
    textAlign: 'center',
    paddingTop: '10px'
  };
  const iconContainerSx = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '5px'
  };
  return (
    <StyledMainGrid item container columnSpacing>
      <Grid item xs="4">
        <Typography variant="h6" sx={typographySx}>
          <Box>
            Payment Details
          </Box>
          <Box sx={iconContainerSx}>
            <BarChartIcon  fontSize="large"/>
          </Box>
        </Typography>
      </Grid>
      <Grid item xs="4">
        <Typography variant="h6" sx={typographySx}>
          <Box>
            Total Transactions
          </Box>
          <Box sx={iconContainerSx}>
            {stats.totalTransactions}
          </Box>
        </Typography>
      </Grid>
      <Grid item xs="4">
        <Typography variant="h6" sx={typographySx}>
          <Box>
            Total Amount
          </Box>
          <Box sx={iconContainerSx}>
            {
              showDistributorPaymentLocalCurrency ?
              <MoneyFormat currency={currency} value={stats.totalAmountLocal} />
              :
              <MoneyFormat value={stats.totalAmount} />
            }
          </Box>
        </Typography>
      </Grid>
    </StyledMainGrid>
  );
}
