import React from 'react';
import Box from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

export default function ProductActions({ product, handleSaveProduct, handleProductDisable, handleProductEnable }) {

  const buttonStyle = {
    height: '40px',
    width: '150px',
    fontSize: '12px'
  };

  const showOtherButtons = () => {
    return (
      <React.Fragment>
        {product && product.active > 0 ? (
          <Button variant="contained" size="normal" style={buttonStyle} sx={{ backgroundColor: 'red' }} onClick={handleProductDisable}>
            Disable Product
          </Button>
        ) : (
          <Button variant="contained" size="normal" style={buttonStyle} onClick={handleProductEnable}>
            Enable Product
          </Button>
        )}
      </React.Fragment>
    );
  };

  return (
    <Box sx={{ display: 'flex', marginBottom: '20px', justifyContent: { xs: 'center', sm: 'space-between'}, flexDirection: { xs: 'column-reverse', sm: 'row'} }}>
      <Box>
        <Button variant="contained" size="normal" style={buttonStyle} sx={{ backgroundColor: 'green', mr: '20px' }} onClick={handleSaveProduct}>
          Save Product
        </Button>
        <Button variant="outlined" size="normal" style={buttonStyle} component={Link} to="/dashboard/products">
          Cancel
        </Button>
      </Box>
      <Box sx={{ mb: { xs: '20px', sm: 0 }}}>
        {product && product.id ? showOtherButtons() : ''}
      </Box>
    </Box>
  );
}
