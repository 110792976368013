import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import { MoneyFormat } from '../../components/MoneyFormat';

export default function ProductOptionsFields({ productOptions, soldOptions, optionsData, setOptionsData, updateOptionsData, setUpdateOptionsData, errors, disabled, companyDiscount }) {
  //set optionsData (fields data) if soldOptions exists in the product and this is a new sell of the sold product
  useEffect(() => {
    let fullData = [];
    if (soldOptions) {
      Object.entries(soldOptions).forEach(([key, val]) => {
        let item_name = 'option_' + key;
        fullData[item_name] = val['value'];
      });
    }

    let licenseData = [];
    productOptions.forEach( item => {
      if ( item.free_bundle && item.active ) {
        let item_name = 'option_' + item.id;
        // if sold, don't add
        if ( !fullData.hasOwnProperty(item_name) ) {
          fullData[item_name] = true;
          licenseData[item_name] = true;
        }
      }
    });

    if ( Object.keys(fullData).length > 0 ) {
      setOptionsData(fullData);
    }

    if ( Object.keys(licenseData).length > 0 ) {
      setUpdateOptionsData(licenseData);
    }
  }, [productOptions, soldOptions, setOptionsData, setUpdateOptionsData]);

  const handleChangeOptionField = (event) => {
    let value = event.target.value;

    var data1 = [];
    var newDataOnly = [];

    if (event.target.type === 'text') {
      value = value.replace(/\D/g, '');
      let newValue = value;

      if (soldOptions) {
        //don't let to choose lower number than previous value in sold options
        Object.entries(soldOptions).forEach(([key, val]) => {
          let item_name = 'option_' + key;

          if (item_name === event.target.name) {
            let valueInt = parseInt(value, 10) || 0;
            let soldValueInt = parseInt(val['value'], 10) || 0;
            if (valueInt > soldValueInt) {
              newValue = valueInt - soldValueInt;
            } else {
              newValue = 0;
            }
            return;
          }
        });
      }

      if (newValue > 0) {
        newDataOnly[event.target.name] = newValue;
      }
      else {
        newDataOnly[event.target.name] = 0;
      }

      data1[event.target.name] = value;
    } else if (event.target.type === 'checkbox') {
      data1[event.target.name] = event.target.checked;
      newDataOnly[event.target.name] = data1[event.target.name];
    }

    data1 = { ...optionsData, ...data1 };
    newDataOnly = { ...updateOptionsData, ...newDataOnly };

    setOptionsData(data1);

    // it saves only new data, we need it to generate license later:
    setUpdateOptionsData(newDataOnly);
  };

  const getProductOption = (item) => {
    const item_name = 'option_' + item.id;
    let isDisabled = false;
    let isNotShow = false;

    if (soldOptions && soldOptions[item.id]) {
      if (item.type === 'boolean' || item.type === 'fixed') {
        isDisabled = true;
      }
      else if ( (item.type === 'integer_fixed' || item.type === 'integer') && item.max_quantity === parseInt(soldOptions[item.id].value, 10) ) {
        isDisabled = true;
      }
    }
    if ( !item.active ) {
      isNotShow = true;
    }

    if ( (item.type === 'boolean' ||  item.type === 'fixed') && item.free_bundle ) {
      isDisabled = true;
    }

    if ( disabled ) {
      isDisabled = true;
    }

    if ( isNotShow ) {
      return null;
    }

    const isCheckBox = (item.type === 'boolean' || item.type === 'fixed');
    const data = getData(item_name, item);
    const isError = !!errors[item.id] ;

    const price = window.IS_ADMIN ? item.price : item.price_local;
    const currency = window.IS_ADMIN? null : item.currency;

    let dc = (item.discount !== null ? item.discount : companyDiscount);
    let priceNet = price * ((100 - dc) / 100);
    const hasDiscount = price !== priceNet;
    return (
      <React.Fragment key={item.id}>
        <ListItem
          disablePadding
          secondaryAction={
            <Chip
              label={
                <div>
                  { hasDiscount &&
                    <React.Fragment>
                      <span style={{textDecoration: 'line-through', marginRight: 4, color: '#929292'}}>
                        <MoneyFormat currency={currency} value={price} />
                      </span>
                      <span>
                        <MoneyFormat currency={currency} value={priceNet} />
                      </span>
                    </React.Fragment>
                  }
                  { !hasDiscount &&
                    <span>
                      <MoneyFormat currency={currency} value={price} />
                    </span>
                  }
                </div>
              }
              size="small"
              variant="outlined"
              color="info"
            />
          }
        >
          <ListItemButton dense>
            <ListItemIcon>
              {
                isCheckBox ?
                <Checkbox
                  edge="start"
                  name={item_name}
                  onChange={handleChangeOptionField}
                  checked={data ? data : false}
                  disabled={isDisabled}
                  disableRipple
                  inputProps={{ 'aria-labelledby': item_name }}
                />
                :
                <TextField
                  sx={{
                    width: '45px',
                    ml: '-12px'
                  }}
                  size="small"
                  name={item_name}
                  onChange={handleChangeOptionField}
                  value={data ? data : ''}
                  disabled={isDisabled}
                  error={isError}
                  inputProps={{ 'aria-labelledby': item_name }}
                />
              }
            </ListItemIcon>
            <ListItemText
              id={item_name}
              sx={{ marginRight: '70px' }}
              primary={
                isError ?
                <Box component="span" sx={{ color: '#d32f2f' }}>
                  {item.title}
                </Box>
                :
                item.title
              }
              secondary={
                isError ?
                <Box component="span" sx={{ fontSize: '14px', color: '#d32f2f' }}>
                  {errors[item.id]}
                </Box>
                :
                undefined
              }
            />
          </ListItemButton>
        </ListItem>
        {
          window.debugTest &&
            <ListItem>
              Price: {item.price}, Discount: {item.discount !== null ? item.discount : 'default'}
            </ListItem>
        }
      </React.Fragment>
    );
  };

  const getData = (key, item) => {
    //  let initialValue = type === "integer" ? 1 : false;
    let initialValue = false;

    if ( item.free_bundle ) {
      initialValue = true;
    }

    if (soldOptions && soldOptions[item.id]) {
      initialValue = soldOptions[item.id].value;
    }

    return optionsData[key] !== undefined ? optionsData[key] : initialValue;
  };

  return (
    <List>
      <ListItem
        disablePadding
        secondaryAction={
          <Chip
            label={'MSRP / Distributor Price'}
            size="small"
            variant="outlined"
            color="info"
          />
        }
      >
        <ListItemText primary="Purchase licenses" />
      </ListItem>
      {productOptions.map((item) => getProductOption(item))}
    </List>
  );
}
