import React, { useState, useEffect, useCallback, useMemo } from 'react';
import inMemoryUser from '../../services/inMemoryUser';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import validator from 'validator';
import StyledMainGrid from '../../components/StyledMainGrid';
import { ajaxSaveAdminPwd } from '../../services/adminService';
import { Link } from 'react-router-dom';
import {noty} from '../../helpers';
import Page from '../../components/Page';
import StyledCard from '../../components/StyledCard'
import StyledCardFormContent from '../../components/StyledCardFormContent';
import StyledSectionHeader from '../../components/StyledSectionHeader';

export default function AdminSettings(props) {
  const [settings, setSettings] = useState({});
  const [errors, setErrors] = useState({});
  const [submitOnce, setSubmitOnce] = useState(false);
  const allFields = useMemo(
    () => [
      {
        key: 'password',
        type: 'password',
        label: 'New Password',
        autoComplete: 'new-password',
        minLength: 8
      },
      {
        key: 'confirmPassword',
        type: 'password',
        label: 'Confirm New Password',
        autoComplete: 'confirm-password',
        confirm: 'password'
      }
    ],
    []
  );
  const requiredFields = useMemo(() => [], []);
  const validateFields = useCallback(() => {
    var newErros = {};
    const fields = { ...settings };
    var result = true;
    for (let index in allFields) {
      let key = allFields[index].key;
      let minLength = allFields[index].minLength;
      let confirm = allFields[index].confirm;
      let fieldValue = fields[key] ? fields[key] + '' : '';
      let fieldConfirmValue = confirm && fields[confirm] ? fields[confirm] + '' : '';
      if (requiredFields.includes(key) && validator.isEmpty(fieldValue, { ignore_whitespace: true })) {
        newErros[key] = 'required field';
        result = false;
      } else if (fieldValue && minLength > 0 && !validator.isLength(fieldValue, { min: minLength, max: undefined })) {
        newErros[key] = `This field must be at least ${minLength} characters in length`;
        result = false;
      } else if (confirm && fieldValue !== fieldConfirmValue) {
        newErros[key] = 'Please enter the same value again.';
        result = false;
      }
    }
    setErrors(newErros);
    return result;
  }, [settings, allFields, requiredFields]);

  useEffect(() => {
    if (submitOnce) {
      validateFields();
    }
  }, [validateFields, submitOnce]);

  const buttonStyle = {
    height: '40px',
    width: '150px',
    fontSize: '12px'
  };
  const username = inMemoryUser.getUsername();
  const handleSave = () => {
    setSubmitOnce(true);
    if (!validateFields()) {
      return false;
    }
    const params = { ...settings, username: username };
    if (!params.password) {
      noty('Settings has been saved successfully.', 'success');
    } else {
      ajaxSaveAdminPwd(params)
        .then((res) => {
          if (res.error) {
            noty('Failed to save settings.', 'error');
          } else {
            noty('Settings has been saved successfully.', 'success');
          }
        })
        .catch(() => {
        });
    }
  };

  const handleFieldChange = (event) => {
    let value = event.target.value;
    let key = event.target.name;
    setSettings({ ...settings, [key]: value });
  };

  const getFieldValue = (key) => {
    return settings && settings[key] ? settings[key] : '';
  };

  const getFieldPlaceholder = (key) => {
    return key === 'password' ? 'leave blank to not change the password' : '';
  };

  const getFieldErrorText = (key) => {
    if (errors && errors[key]) return errors[key];
    else return '';
  };

  const checkFieldError = (key) => {
    if (errors && errors[key]) return true;
    else return false;
  };

  return (
    <Page title="Settings">
      <form>
        <StyledCard>
          <StyledCardFormContent>
            <StyledMainGrid>
              <Grid item xs={12}>
                <StyledSectionHeader>
                  Change your password
                </StyledSectionHeader>
              </Grid>
              <Grid item xs={12} />
              {allFields.map((entry, index) => (
                <React.Fragment key={entry['key']}>
                  <Grid item xs={12}>
                    <TextField
                      label={entry['label']}
                      autoComplete={entry['autoComplete']}
                      type={entry['type']}
                      name={entry['key']}
                      value={getFieldValue(entry['key'])}
                      onChange={handleFieldChange}
                      error={checkFieldError(entry['key'])}
                      helperText={getFieldErrorText(entry['key'])}
                      placeholder={getFieldPlaceholder(entry['key'])}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>
                </React.Fragment>
              ))}
              <Grid item xs={12} />
              <Grid item container xs={12}>
                <Button variant="contained" size="normal" style={buttonStyle} sx={{ backgroundColor: 'green', mr: '20px' }} onClick={handleSave}>
                  Save Settings
                </Button>
                <Button variant="outlined" size="normal" style={buttonStyle} component={Link} to="/dashboard">
                  Cancel
                </Button>
              </Grid>
            </StyledMainGrid>
          </StyledCardFormContent>
        </StyledCard>
      </form>
    </Page>
  );
}
