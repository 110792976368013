import React, { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import CardContent from '@mui/material/CardContent';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import SendIcon from '@mui/icons-material/Send';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import { downloadFile, toDateText } from '../../helpers';
import { ajaxGetAdminShipments } from '../../services/adminService';
import StyledList from '../../components/StyledList';
import StyledCard from '../../components/StyledCard'

export default function OutboundShipments() {
  const [shipments, setShipments] = useState([]);

  useEffect(() => {
    ajaxGetAdminShipments()
      .then((res) => {
        const { data } = res;
        setShipments(data);
      })
      .catch(() => {
        setShipments([]);
      });
  }, []);

  const renderItem = (data, idx, secondarySx) => {
    return (
      <ListItem
        key={idx}
        disablePadding
        secondaryAction={
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column-reverse', sm: 'row' }, alignItems: { xs: 'flex-end', sm: 'center' }}}>
            <Box>
              <Tooltip title={`Dispatched to warehouse ${toDateText(data.dispatched_date)}`}>
                <Box component="span" sx={secondarySx}>{toDateText(data.dispatched_date)}</Box>
              </Tooltip>
            </Box>
            <Box>
              <Tooltip title={`Packing List of ${data.awb}`}>
                <IconButton
                  onClick={() =>
                    downloadFile({
                      params: { id: data.id, fileType: 'packing_list', role: 'administrator' },
                      fileName: data.awb + '_packing_list',
                      fileExt: 'pdf'
                    })
                  }
                >
                  <LocalShippingIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={`Shipping Invoice of ${data.awb}`}>
                <IconButton
                  onClick={() =>
                    downloadFile({
                      params: { id: data.id, fileType: 'invoice', role: 'administrator' },
                      fileName: data.awb + '_invoice',
                      fileExt: 'pdf'
                    })
                  }
                >
                  <RequestQuoteIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        }
      >
        <ListItemButton>
          <ListItemText
            primary={`AWB# ${data.awb}`}
            secondary={data.company}
          />
        </ListItemButton>
      </ListItem>
    );
  };

  return (
    <StyledCard
      title="Outbound Shipments"
      titleIcon={SendIcon}
    >
      <CardContent>
        <StyledList dataSource={shipments} renderItem={renderItem} />
      </CardContent>
    </StyledCard>
  );
}
