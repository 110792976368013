import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import StyledMainGrid from '../components/StyledMainGrid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FileUploader from '../components/FileUploader';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useNavigate, useParams } from 'react-router-dom';
import { ajaxGetAdminCustomFirmwares, ajaxSaveAdminCustomFirmware } from '../services/adminService';
import validator from 'validator';
import { Link } from 'react-router-dom';
import {isMacAddress, formatMacAddress} from '../helpers';
import { validate as versionValidate } from 'compare-versions';
import Page from '../components/Page';
import StyledCard from '../components/StyledCard'
import StyledCardFormContent from '../components/StyledCardFormContent';

export default function CustomFirmware(props) {
  const incomingParams = useParams();

  const buttonStyle = {
    height: '40px',
    width: '150px',
    fontSize: '12px'
  };

  const allFields = [
    {
      key: 'mac',
      type: props.add ? 'textarea' : 'text',
      label: 'MAC Address',
      disabled: !props.add,
      hint: props.add ? 'Multiple values separate by comma or newline' : ''
    },
    {
      key: 'version',
      type: 'text',
      label: 'Firmware Version',
      disabled: false
    },
    {
      key: 'description',
      type: 'textarea',
      label: 'Description',
      disabled: false
    }
  ];

  var requiredFields = ['mac', 'version'];
  var macFields = ['mac'];
  var numericFields = [];
  var versionFields = ['version'];

  const [data, setData] = useState({mac: '', version: '', description: '', firmware: null});
  const [errors, setErrors] = useState({});
  const [isValidateUpload, setValidateUpload] = React.useState(true);
  const paramFirmwareId = (incomingParams && incomingParams.FirmwareId ? incomingParams.FirmwareId : -1);

  const navigate = useNavigate();

  useEffect(() => {
    if (!props.add) {
      const params = {};

      if (paramFirmwareId !== -1) {
        params.FirmwareId = paramFirmwareId;
      }

      ajaxGetAdminCustomFirmwares(params)
        .then((res) => {
          const { data } = res;
          if ( data ) {
            data.firmware = null; 
            setData(data);
          }
        })
        .catch(() => {
          setData({});
        });
    }
  }, [paramFirmwareId, props.add]);

  const handleUploadFirmware = (event) => {
    if (event.target.files && event.target.files[0]) {
      let copyData = { ...data };
      copyData[event.target.name] = event.target.files[0];

      setData(copyData);
      setValidateUpload(true);
    } else {
      setValidateUpload(false);
    }
  };

  const handleSave = () => {
    if (!validateFields()) return false;

    const params = { ...data };
    let macArray = data.mac
      .replace(/\r/g, '')
      .split(/[ ,\n]/)
      .filter( m => m !== '' )
      .map( m => formatMacAddress(m) )

    const uniqueArray = [...new Set(macArray)];
    params.mac = uniqueArray;

    let url = '/dashboard/cfirmware';

    ajaxSaveAdminCustomFirmware(params)
      .then(() => {
        navigate(url);
      })
      .catch((e) => {
      });
  };

  const validateFields = () => {
    var newErros = {};
    const fields = { ...data };
    var result = true;

    for (let index in allFields) {
      let key = allFields[index].key;
      let fieldValue = fields[key] ? fields[key] + '' : '';

      if (validator.isEmpty(fieldValue, { ignore_whitespace: true })) {
        if (requiredFields.includes(key)) {
          newErros[key] = 'required field';
          result = false;
        }
      } else {
        if (versionFields.includes(key) && !versionValidate(fieldValue)) {
          newErros[key] = 'Version is invalid (x.x.x)';
          result = false;
        }
        else if (macFields.includes(key)) {
          let macArray = fieldValue
            .replace(/\r/g, '')
            .split(/[ ,\n]/)
            .filter( m => m !== '');
          let res = macArray.map( m => isMacAddress(m) ? 'yes' : 'no' ).filter( m => m === 'no' );
          if ( res.length > 0 ) {
            newErros[key] = 'Mac address is invalid.';
            if ( props.add ) {
              newErros[key] += ' Separate by newline or comma.';
            }
            result = false;
          }
        }
      }
    }

    if ( fields.firmware === null ) {
      setValidateUpload(false);
      result = false;
    }

    if (Object.keys(newErros).length !== 0) {
      setErrors(newErros);
    }

    return result;
  };

  const handleFieldChange = (event) => {
    let value = event.target.value,
      key = event.target.name;

    if (numericFields.includes(key)) {
      value = value.replace(/\D/g, '');
    }

    setData({ ...data, [key]: value });
  };

  const getFieldValue = (key) => {
    return data && data[key] ? data[key] : '';
  };

  const getFieldErrorText = (key) => {
    if (errors && errors[key]) return errors[key];
    else return '';
  };

  const checkFieldError = (key) => {
    if (errors && errors[key]) return true;
    else return false;
  };

  return (
    <Page title={props.add ? 'Add Custom Firmware' : 'Edit Custom Firmware'}>
      <form>
        <StyledCard>
          <StyledCardFormContent>
            <StyledMainGrid>
              {
                allFields.map((entry, index) => (
                  <React.Fragment key={entry['key']}>
                    {
                      entry['type'] === 'text' &&
                      <Grid item xs={12}>
                        <TextField
                          label={entry['label']}
                          type={entry['type']}
                          name={entry['key']}
                          disabled={entry['disabled']}
                          placeholder={entry['hint']}
                          value={getFieldValue(entry['key'])}
                          onChange={handleFieldChange}
                          error={checkFieldError(entry['key'])}
                          helperText={getFieldErrorText(entry['key'])}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>
                    }
                    {
                      entry['type'] === 'textarea' &&
                      <Grid item xs={12}>
                        <TextField
                          multiline
                          rows={5}
                          label={entry['label']}
                          type={entry['type']}
                          name={entry['key']}
                          placeholder={entry['hint']}
                          value={getFieldValue(entry['key'])}
                          onChange={handleFieldChange}
                          error={checkFieldError(entry['key'])}
                          helperText={getFieldErrorText(entry['key'])}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>
                    }
                  </React.Fragment>
                )
              )}
            <Grid item xs={12}>
              <FileUploader accept=".bin" name="firmware" label="Upload Firmware" icon={<AttachFileIcon />} error={!isValidateUpload} handleChange={handleUploadFirmware} />
            </Grid>
            <Grid item xs={12} />
              <Grid item container xs={12}>
                <Button variant="contained" size="normal" style={buttonStyle} sx={{ backgroundColor: 'green', mr: '20px' }} onClick={handleSave}>
                  Save Firmware
                </Button>
                <Button variant="outlined" size="normal" style={buttonStyle} component={Link} to="/dashboard/cfirmware">
                  Cancel
                </Button>
              </Grid>
            </StyledMainGrid>
          </StyledCardFormContent>
        </StyledCard>
      </form>
    </Page>
  );
}
