import inMemoryJwt from './inMemoryJwtService';

const inMemoryUser = () => {
  const isAdmin = () => {
    let tokenPayload = inMemoryJwt.getTokenPayload();

    if (tokenPayload['user_role'] === 'administrator') return true;
    else return false;
  };

  const isDistributor = () => {
    let tokenPayload = inMemoryJwt.getTokenPayload();

    if (tokenPayload['user_role'] === 'distributor') return true;
    else return false;
  };

  const isManager = () => {
    let tokenPayload = inMemoryJwt.getTokenPayload();

    if (tokenPayload['user_role'] === 'manager') return true;
    else return false;
  };

  const getUserRole = () => {
    let tokenPayload = inMemoryJwt.getTokenPayload();

    return tokenPayload['user_role'] ? tokenPayload['user_role'] : false;
  };

  const getUsername = () => {
    let tokenPayload = inMemoryJwt.getTokenPayload();

    return tokenPayload['username'];
  };

  const getCompanyName = () => {
    let tokenPayload = inMemoryJwt.getTokenPayload();

    return tokenPayload['company_name'];
  };

  const isTest = () => {
    let tokenPayload = inMemoryJwt.getTokenPayload();
    return !!tokenPayload['is_test'];
  };

  return {
    isAdmin,
    isDistributor,
    isManager,
    getUsername,
    getCompanyName,
    getUserRole,
    isTest
  };
};

export default inMemoryUser();
