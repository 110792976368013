import React, {useState, useEffect} from 'react';
import {ajaxGetAdminCompanyMonthySalesTotal} from '../../services/adminService';
import StyledCard from '../../components/StyledCard'
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import * as COLORS from '@mui/material/colors';
import { ResponsiveContainer, PieChart, Pie, Cell, Sector } from 'recharts';
import { MoneyFormat } from '../../components/MoneyFormat';
import moment from 'moment';

const srcColors = Object.keys(COLORS).filter(k => COLORS[k].hasOwnProperty(100))
const colors = [
  ...srcColors.map(k => COLORS[k][100]),
  ...srcColors.map(k => COLORS[k][200]),
  ...srcColors.map(k => COLORS[k][300]),
  ...srcColors.map(k => COLORS[k][400]),
  ...srcColors.map(k => COLORS[k][500]),
  ...srcColors.map(k => COLORS[k][600]),
  ...srcColors.map(k => COLORS[k][700]),
  ...srcColors.map(k => COLORS[k][800]),
  ...srcColors.map(k => COLORS[k][900])
].filter(c => !!c);

export default function AdminSale() {
  const [companyList, setCompanyList] = useState([]);
  const [activeIndex, setActiveIndex] = useState(false);
  const noSaleColor = COLORS['grey'][100];

  const loadData = () => {
    ajaxGetAdminCompanyMonthySalesTotal()
      .then((res) => {
        const { data } = res;
        // prepare data
        let sortData = data.map((d, idx) => {
          let {saleItems, ...rest} = d;
          let total = d.saleItems.reduce((sum, cur) => sum + cur.total, 0);
          return {
            ...rest,
            total: total,
            pieValue: total,
            color: colors[idx % colors.length]
          };
        })
        .sort((a, b) => a.total - b.total);

        if ( sortData.filter(c => c.total > 0).length === 0 ) {
          // no sale items yet
          sortData = [{
            total: 0,
            pieValue: 1,
            color: noSaleColor,
            id: -1,
            company_name: "Total"
          }];
        }

        setCompanyList(sortData);
      })
      .catch(() => {
        setCompanyList([]);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const onPieEnter = (e, index) => {
    setActiveIndex(index);
  };

  const onPieLeave = (e, index) => {
    setActiveIndex(false);
  };

  const renderActiveShape = (props) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload } = props;
    const RADIAN = Math.PI / 180;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius) * cos;
    const sy = cy + (outerRadius) * sin;
    const mx = cx + (outerRadius + 20) * cos;
    const my = cy + (outerRadius + 20) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 10;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';
    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius + 10}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <foreignObject
          style={{
            fontSize: '12px',
            width: '70px',
            height: '70px',
            textAlign: 'center',
            color: '#333',
            overflow: 'visible',
            transform: textAnchor === 'end' ? 'translate(-70px, -35px)' : 'translateY(-35px)'
          }}
          x={ex + (cos >= 0 ? 1 : -1) * 2}
          y={ey}
        >
          <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
            <div>{payload.company_name}</div>
            <div style={{ color: 'rgba(0, 0, 0, 0.6)' }}>
              <MoneyFormat prefix="$" value={payload.total} />
            </div>
          </div>
        </foreignObject>
      </g>
    );
  };

  return (
    <StyledCard
      title={`Sale in ${moment().format('MMMM YYYY')}`}
      titleIcon="$"
    >
      <Box sx={{ display: 'flex', justifyContent: 'center', height: '300px', width: '100%' }}>
        <ResponsiveContainer>
          <PieChart>
            <Pie
              data={companyList}
              dataKey="pieValue"
              startAngle={-270}
              endAngle={270}
              outerRadius="70%"
              fill="#29ABE2"
              activeShape={renderActiveShape}
              onMouseMove={onPieEnter}
              onMouseLeave={onPieLeave}
              activeIndex={activeIndex}
            >
              {companyList.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </Box>
      <CardContent sx={{ pt: 0, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <React.Fragment>
          <div>Total</div>
          <div><MoneyFormat prefix="US$ " value={companyList.reduce((sum, cur) => sum + cur.total, 0)} /></div>
        </React.Fragment>
      </CardContent>
    </StyledCard>
  );
}
