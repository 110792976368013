import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import WarningIcon from '@mui/icons-material/Warning';
import CircularProgress from '@mui/material/CircularProgress';
import { ajaxGetInventoryItem, ajaxGetInventory } from '../../services/inventoryService';
import {
  ajaxMakeSales, ajaxMakeSaleOptions,
  ajaxMakeReplacement, ajaxSaveProductRef
} from '../../services/companyService';
import { ajaxGetCompanyInfo } from '../../services/companyService';
import { getProductImageUrl } from '../../services/fileService';
import StyledMainGrid from '../../components/StyledMainGrid';
import ConfirmationButton from '../../components/ConfirmationButton';
import { MoneyFormat } from '../../components/MoneyFormat';
import ProductOptionsFields from './ProductOptionsFields';
import useAlert from '../../hooks/useAlert';
import {noty, toDateTimeText} from '../../helpers';
import Page from '../../components/Page';
import StyledCard from '../../components/StyledCard';
import StyledCardFormContent from '../../components/StyledCardFormContent';
import StyledSectionHeader from '../../components/StyledSectionHeader';

export default function MakeSale(props) {
  const style = {
    spxnplus: {
      height: '90px'
    }
  };
  const defaultReplacementQuestions = useMemo(() => [
    {
      name: 'question1',
      label: 'What troubleshooting steps were performed by you to determine the product to be faulty?',
      value: ''
    },
    {
      name: 'question2',
      label: 'Did you check our Knowledge Base details on our website & forum yet regarding the issues?',
      value: ''
    },
    {
      name: 'question3',
      label: 'Did you check the details in our product manuals on our website support portal?',
      value: ''
    },
    {
      name: 'question4',
      label: 'Did you make sure the AKCP base unit is updated to the latest firmware version on our website support portal and if so what version number?',
      value: ''
    }
  ], []);
  const params = useParams();
  const navigate = useNavigate();
  const manualInputAlert = useAlert(false, 'error');
  const [companyInfo, setCompanyInfo] = useState(false);
  const [productData, setProductData] = useState(false);
  const [productRef, setProductRef] = useState('');
  const [macSerialField, setMacSerialField] = useState('');
  const [optionsData, setOptionsData] = useState({});
  const [updateOptionsData, setUpdateOptionsData] = useState({});
  const [isPageLoaded, setPageIsLoaded] = useState(false);
  const [optionErrors, setOptionErrors] = useState({});
  const [submitOnce, setSubmitOnce] = useState(false);
  const [batchSale, setBatchSale] = useState(false);
  const [batchSaleMode, setBatchSaleMode] = useState('select');
  const [batchProducts, setBatchProducts] = useState([]);
  const [selectedBatchProducts, setSelectedBatchProducts] = useState([]);
  const [textBatchProducts, setTextBatchProducts] = useState('');
  const [batchSearch, setBatchSearch] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [processingMode, setProcessingMode] = useState(false);
  const [replacementDetails, setReplacementDetails] = useState([...defaultReplacementQuestions])
  const [showValidateReplacementFields, setShowValidateReplacementFields] = useState(false);
  const isAdmin = window.IS_ADMIN;

  const isSpxn = productData.product_code === 'SPXN+' || productData.product_code === 'SPXN+P';
  // code   SPXN+  SPXN+P
  // option  22      75 "SPXN+ Unlock Port 1-4"
  // option  23      76 "SPXN+ Unlock Port 5-8"
  // option  24      77 "SPXN+ 10x dry contacts"
  // option  25      78 "SPXN+ 2x relays"
  // option  26      79 "SPXN+ 2x analog inputs (0-5 VDC)"
  const isSpxnUnlockPort4 = optionsData['option_22'] || optionsData['option_75'];
  const isSpxnUnlockPort8 = optionsData['option_23'] || optionsData['option_76'];
  const isSpxnUnlockDry = optionsData['option_24'] || optionsData['option_77'];
  const isSpxnUnlockRelay = optionsData['option_25'] || optionsData['option_78'];
  const isSpxnUnlockVdc = optionsData['option_26'] || optionsData['option_79'];
  const isSpxnUnlockNone = !isSpxnUnlockPort4 && !isSpxnUnlockPort8 && !isSpxnUnlockDry && !isSpxnUnlockRelay && !isSpxnUnlockVdc;

  const showProductFields = useCallback((value) => {
    // reset state when mac address is changed
    setOptionsData({});
    setUpdateOptionsData({});
    setCompanyInfo(false);
    setProductData(false);
    setProductRef('');
    setBatchSale(false);
    setBatchSearch('');
    setReplacementDetails([...defaultReplacementQuestions]);
    setShowValidateReplacementFields(false);
    manualInputAlert.resetAlert();

    let productMacValue = {
      value,
      companyId: params.CompanyId,
      ignoreError: true
    };

    setMacSerialField(value);

    // get company data for discount
    // get inventory item
    if ( isAdmin ) {
      navigate(`/dashboard/inventory/view/${params.CompanyId}/${value}`);
    }
    else {
      navigate(`/dashboard/make-sale/${value}`);
    }

    ajaxGetInventoryItem(productMacValue)
      .then((res) => {
        setProductData(res.data);
        setProductRef(res.data.ref || '');
        return ajaxGetCompanyInfo({ companyId: params.CompanyId }).then((res2) => {
          setCompanyInfo(res2.data);
        })
      })
      .catch(() => {
        setCompanyInfo(false);
        setProductData(false);
        setProductRef('');
      });

  }, [navigate, manualInputAlert, params, isAdmin, defaultReplacementQuestions]);

  useEffect(() => {
    // first time load from URL params
    if (!isPageLoaded) {
      if (params && params['*']) {
        let value = params['*'];
        showProductFields(value);
        setPageIsLoaded(true);
      }
    }
  }, [isPageLoaded, params, showProductFields]);

  const handleChangeProductSerial = (event) => {
    const value = event.target.value;
    if (event.target.name === 'mac_id_or_serial_number') {
      showProductFields(value);
    }
  };

  const saveProductRef = (isSetProcessing) => {
    if ( productData.ref !== productRef ) {
      if ( isSetProcessing ) {
        setIsProcessing(true);
        setProcessingMode('productRef');
      }
      return ajaxSaveProductRef({
        productRef: productRef,
        productIds: [
          productData.id,
          ...selectedBatchProducts.filter(i => i.id !== productData.id).map(item => item.id)
        ]
      }).then(() => {
        if ( isSetProcessing ) {
          setIsProcessing(false);
          setProcessingMode(false);
        }
      }).catch(() => {
        if ( isSetProcessing ) {
          setIsProcessing(false);
          setProcessingMode(false);
        }
      });
    }

    return Promise.resolve();
  }

  const handleMakeReplacement = () => {
    if ( !companyInfo.internal && replacementDetails.filter(detail => !detail.value).length > 0 ) {
      setShowValidateReplacementFields(true);
      return false;
    }

    handleMakeSale(true);
  };

  const handleMakeSale = (isReplacement) => {
    if ( isAdmin ) {
      return;
    }

    var selectedLicenseOptions = []; // Full options

    if (!productData) {
      noty('You have no product to make sale!', 'warning');
      return;
    }

    if (productData.status === 'sold' && Object.keys(updateOptionsData).length <= 0) {
      noty("You haven't add any new options to the sale!", 'warning');
      return;
    }

    if (Array.isArray(productData.options)) {
      productData.options.forEach((item) => {
        const item_name = 'option_' + item.id;
        if (optionsData[item_name]) {
          let selected_option = {
            id: item.id,
            option_code_id: item.option_code_id,
            value: optionsData[item_name]
          };

          selectedLicenseOptions.push(selected_option);
        }
      });
    }

    let fullOptions = JSON.stringify(selectedLicenseOptions);
    let makeSaleData = {
      saleItems: [
        {
          id: productData.id,
          serialKey: productData.serial_key,
          options: fullOptions
        },
        ...selectedBatchProducts.filter(i => i.id !== productData.id).map(item => ({
          id: item.id,
          serialKey: item.serial_key,
          options: fullOptions
        }))
      ]
    };

    setIsProcessing(true);
    if ( isReplacement ) {
      setProcessingMode('replacement');
    }
    else {
      setProcessingMode('sale');
    }

    (
      isReplacement ?
      ajaxMakeReplacement({
        ...makeSaleData,
        additionalMsg: replacementDetails.map((detail, idx) => (`${detail.label}\n- ${detail.value}`)).join('\n\n')
      })
      :
      (
        productData.status === 'sold' ?
        ajaxMakeSaleOptions(makeSaleData)
        :
        ajaxMakeSales(makeSaleData)
      )
    ).then(res => {
      return saveProductRef().then(() => res).catch(() => res);
    }).then(res => {
      let {message, status} = res;
      if (status !== 'ok') {
        showProductFields(productData.serial_key);
        noty(message, 'error');
      } else {
        noty(message, 'success');

        setProductData(false);
        setProductRef('');
        setMacSerialField('');
        setSubmitOnce(false);

        if (productData.status === 'sold' ) {
          navigate('/dashboard/inventory/sold');
        }
        else {
          navigate('/dashboard/inventory');
        }
      }
      setIsProcessing(false);
      setProcessingMode(false);
    }).catch(e => {
      setIsProcessing(false);
      setProcessingMode(false);
      showProductFields(productData.serial_key);
    });
  };

  const getValidateTextBatchProducts = useCallback((value) => {
    let result = {
      valid: true,
      msg: '',
      selectedList: []
    };
    let keyArray = value
        .replace(/\r/g, '')
        .split(/[ ,\n]/)
        .filter( k => k !== '')
        .map( k => k.trim() );

    // check unique keys
    const uniqueArray = [...new Set(keyArray)];
    if ( keyArray.length !== uniqueArray.length || uniqueArray.indexOf(productData.serial_key) !== -1 ) {
      result.msg = 'You have to enter Unique Serial Number, MAC Address';
      result.valid = false;
    }

    let notFoundKeys = [];
    let selectedList = [];
    uniqueArray.forEach(key => {
      let filterProducts = batchProducts.filter(i => i.serial_key === key);
      if ( filterProducts.length > 0 ) {
        selectedList.push(filterProducts[0]);
      }
      else {
        notFoundKeys.push(key);
      }
    });

    if ( notFoundKeys.length > 0 ) {
      result.msg = 'Serial Number, MAC Address not available:\n' + notFoundKeys.join('\n')
      result.valid = false;
    }

    result.selectedList = selectedList;
    return result;
  }, [batchProducts, productData]);

  const handleSellMoreOption = (event) => {
    setBatchSaleMode(event.target.value);
  };

  const handleSellMoreSelection = (items, selected) => {
    let newSelectedBatch = selectedBatchProducts.filter(i => items.filter(e => e.id === i.id).length <= 0);
    if ( selected ) {
      newSelectedBatch.push(...items);
    }
    setSelectedBatchProducts(newSelectedBatch);

    // convert items to text
    setTextBatchProducts(newSelectedBatch.map(i => i.serial_key).join('\n'));
    manualInputAlert.resetAlert();
  };

  const handleSellMoreText = (event) => {
    setTextBatchProducts(event.target.value);

    let result = getValidateTextBatchProducts(event.target.value);
    if ( !result.valid ) {
      manualInputAlert.setAlert(result.msg);
    }
    else {
      manualInputAlert.resetAlert();
    }

    setSelectedBatchProducts([...result.selectedList]);
  };

  const calculateCost = () => {
    // apply discount
    let companyDiscount = 0;
    if ( companyInfo && companyInfo.discount ) {
      companyDiscount = parseFloat(companyInfo.discount) || 0;
    }

    let debugText = '';
    let productDiscount = (productData.discount !== null ? productData.discount : companyDiscount);
    let productPrice = (productData.status === 'sold' ? 0 : productData.msrp );
    let productDiscountPrice = productPrice * (productDiscount / 100);

    // local
    const productLocalPrice = (productData.status === 'sold' ? 0 : productData.msrp_local );
    const productDiscountLocalPrice = productLocalPrice * (productDiscount / 100);

    let total = productPrice;
    let totalDiscount = productDiscountPrice;
    let totalFeatures = [];

    debugText += `Default DC: ${companyDiscount}\n`;
    debugText += `Initial Price: ${productPrice} - ${productDiscountPrice} (${productDiscount}%) = ${productPrice - productDiscountPrice}\n\n`;

    if (productData && Array.isArray(productData.options)) {
      productData.options.forEach((item) => {
        const item_name = 'option_' + item.id;
        let consideringControlData = optionsData;
        if ( productData.status === 'sold' ) {
          consideringControlData = updateOptionsData;
        }

        if (consideringControlData[item_name]) {
          let k = 1;

          if (item.type === 'integer' || item.type === 'integer_fixed') {
            k = parseFloat(consideringControlData[item_name]) || 0;
          }

          totalFeatures.push({ option_code_id: item.option_code_id, msrp: k * parseFloat(item.price), data: item });
        }
      });
    }

    let featurePrice = 0;
    let featureDcPrice = 0;
    totalFeatures.forEach((item) => {
      let dc = (item.data.discount !== null ? item.data.discount : companyDiscount);
      let dcPrice = item.msrp * (dc / 100);
      featurePrice += item.msrp;
      featureDcPrice += dcPrice;
      debugText += `${item.data.title}: ${item.msrp} - ${dcPrice} (${dc}%) = ${item.msrp - dcPrice}\n`;
    });
    total += featurePrice;
    totalDiscount += featureDcPrice;

    debugText += `\n\nOption: ${featurePrice} - ${featureDcPrice} = ${featurePrice - featureDcPrice}`
    debugText += `\n\nTotal: ${total} - ${totalDiscount} = ${total - totalDiscount}`

    return {
      msrp: productData.msrp,
      msrpNet: productPrice - productDiscountPrice,
      msrpLocalNet: productLocalPrice - productDiscountLocalPrice,
      featurePrice: featurePrice,
      discountTotal: totalDiscount,
      totalPrice: total,
      grandTotal: total - totalDiscount,
      debugText: debugText,
      companyDiscount: companyDiscount
    };
  };

  const getConfirmationButtonText = () => {
    let text = '';
    if (productData.status === 'sold') {
      text = 'Once sale is confirmed new features will be added to the sold product and your account credit will be updated.';
    } else {
      if ( isSpxn && isSpxnUnlockNone ) {
        text = 'You have not selected any modules, the SPXN+ will be sold with no active ports. ';
      }
      text += 'Once sale is confirmed the product will be deducted from your inventory and billed to your account.';
    }

    return (
      <div>
        { isSpxn && isSpxnUnlockNone &&
          <div style={{display: 'flex'}}>
            <div><WarningIcon sx={{ fontSize: '28px', color: '#F59C1A' }} /></div>
            <div style={{fontWeight: 'bold'}}>{'Warning'}</div>
          </div>
        }
        <div>{text}</div>
      </div>
    );
  };

  const validateFields = useCallback(() => {
    let newErrors = {};
    let result = true;
    if (productData && Array.isArray(productData.options)) {
      productData.options.forEach((item) => {
        const item_name = 'option_' + item.id;
        if (optionsData[item_name] || ( optionsData[item_name] !== undefined && productData.sold_options && productData.sold_options[item.id] ) ) {
          let min = 0;
          if ( productData.sold_options && productData.sold_options[item.id] ) {
            min = parseInt(productData.sold_options[item.id].value, 10) || 0;
          }
          let max = parseInt(item.max_quantity, 10) || 0;
          let val = parseInt(optionsData[item_name], 10);
          if ((item.type === 'integer' || item.type === 'integer_fixed') && (isNaN(val) || val < min || val > max )) {
            newErrors[item.id] = `Please enter a value between ${min} and ${max}.`;
            result = false;
          }
        }
      });
    }

    if ( batchSaleMode === 'input' ) {
      let textBatchResult = getValidateTextBatchProducts(textBatchProducts);
      if ( !textBatchResult.valid ) {
        result = false;
      }
    }

    setOptionErrors(newErrors);
    return result;
  }, [productData, optionsData, batchSaleMode, textBatchProducts, getValidateTextBatchProducts]);

  const validateButton = () => {
    setSubmitOnce(true);
    return validateFields();
  };

  useEffect(() => {
    if (submitOnce) {
      validateFields();
    }
  }, [validateFields, submitOnce]);

  const onCancel = () => {
    if ( isAdmin ) {
      if (productData.status === 'sold') {
        navigate(`/dashboard/inventory/sold/${params.CompanyId}`);
      }
      else {
        navigate(`/dashboard/inventory/${params.CompanyId}`);
      }
    }
    else {
      if (productData.status === 'sold') {
        navigate('/dashboard/inventory/sold');
      }
      else {
        navigate('/dashboard/inventory');
      }
    }
  };

  useEffect(() => {
    setBatchProducts([]);
    setSelectedBatchProducts([]);
    setTextBatchProducts('');
    if ( batchSale && productData ) {
      const params = {
        productCodeId: productData.product_code_id
      };

      ajaxGetInventory(params)
        .then((res) => {
          let data = [...res.data];
          data.sort((a, b) => {
            if ( a.serial_key === macSerialField ) {
              return -1;
            }
            if ( b.serial_key === macSerialField ) {
              return 1;
            }
            return a.serial_key > b.serial_key;
          });
          setBatchProducts(data);
        });
    }
  }, [batchSale, productData, macSerialField]);

  const calCost = calculateCost();
  const displayBatchProducts = batchProducts.filter(item => item.serial_key.toLowerCase().indexOf(batchSearch.toLowerCase().trim()) !== -1);
  const productHasDiscount = calCost.msrp !== calCost.msrpNet;
  return (
    <Page
      title={
        isAdmin ?
        [
          ...(
            companyInfo ?
            [companyInfo.company_name]
            :
            []
          ),
          productData.status === 'sold' ? 'Sold Inventory' : 'Inventory'
        ]
        :
        'Make Sale'
      }
      backButton={
        isAdmin ?
        <IconButton
          color="primary"
          component={RouterLink}
          to={
            productData.status === 'sold' ?
            `/dashboard/inventory/sold/${params.CompanyId}`
            :
            `/dashboard/inventory/${params.CompanyId}`
          }
        >
          <ArrowBackIcon fontSize="large" sx={{ mt: '3px' }}/>
        </IconButton>
        :
        undefined
      }
    >
      <StyledCard>
        <StyledCardFormContent>
          <StyledMainGrid columnSpacing>
            <Grid item xs={12}>
              <TextField
                name="mac_id_or_serial_number"
                type="text"
                disabled={isProcessing || isAdmin}
                onChange={handleChangeProductSerial}
                label="Serial Number / Mac Address (XX-XX-XX-XX-XX-XX)"
                value={macSerialField}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
            {
              productData &&
              <React.Fragment>
                {
                  productData.product_image &&
                  <Grid item xs={12} sm={4}>
                    <img style={{ width: '100%' }} alt="bg" src={getProductImageUrl(productData.product_code_id)} />
                  </Grid>
                }
                <StyledMainGrid item columnSpacing xs={16} sm={productData.product_image ? 8 : 12}>
                  <Grid item xs={12}>
                    <StyledSectionHeader>
                      <Typography variant="h4">
                        {productData.product_code}
                      </Typography>
                    </StyledSectionHeader>
                  </Grid>
                  <Grid item xs={12}>
                    {productData.product_description}
                  </Grid>
                  {
                    productData.status === 'idle' &&
                    <Grid item xs={12}>
                      <Typography variant="h5">
                        {
                          productHasDiscount ?
                          <React.Fragment>
                            <div>
                              <span style={{color: '#0071bd', marginRight: 8}}>
                                <MoneyFormat
                                  currency={isAdmin ? null : productData.currency}
                                  value={isAdmin ? calCost.msrpNet : calCost.msrpLocalNet}
                                />
                              </span>
                              <span style={{color: '#929292', textDecoration: 'line-through', fontSize: '1.1rem', fontWeight: 'normal' }}>
                                <MoneyFormat
                                  currency={isAdmin ? null : productData.currency}
                                  value={isAdmin ? productData.msrp : productData.msrp_local}
                                />
                              </span>
                            </div>
                            <div style={{fontSize: '14px'}}>
                              Distributor Price / MSRP
                            </div>
                          </React.Fragment>
                          :
                          <div>
                            <span style={{color: '#0071bd'}}>
                              MSRP
                              <MoneyFormat
                                currency={isAdmin ? null : productData.currency}
                                value={isAdmin ? productData.msrp : productData.msrp_local}
                              />
                            </span>
                          </div>
                        }
                      </Typography>
                    </Grid>
                  }
                  {
                    productData.status !== 'idle' &&
                    <Grid item xs={12}>
                      <Typography  component="span" variant="h5" sx={{ color: '#0071bd', mr: '5px' }}>
                        {
                          isAdmin ?
                          <MoneyFormat value={productData.sold_price} />
                          :
                          <MoneyFormat currency={productData.currency} value={productData.sold_price_local} />
                        }
                      </Typography>
                      {(productData.replacement && productData.replacement.indexOf('approve') !== -1) ? 'replacement' : 'sold'} on {toDateTimeText(productData.sold_date)}
                    </Grid>
                  }
                  {
                    productData.options && productData.options.length > 0 &&
                    <React.Fragment>
                      <Grid item xs={12} />
                      <Grid item xs={12}>
                        <StyledSectionHeader>
                          Options
                        </StyledSectionHeader>
                      </Grid>
                      { isSpxn &&
                        <Grid item xs={12}>
                          { isSpxnUnlockNone &&
                            <div style={{display: 'flex'}}>
                              <img style={style.spxnplus} src="/images/spxnplus/spxn_grey.png" alt="SPXN+" />
                            </div>
                          }
                          { !isSpxnUnlockNone &&
                            <div style={{display: 'flex'}}>
                              <img style={style.spxnplus} src="/images/spxnplus/spxn_1_662.png" alt="SPXN+" />
                              { isSpxnUnlockPort4 ?
                                <img style={style.spxnplus} src="/images/spxnplus/spxn_2_220.png" alt="SPXN+" /> :
                                <img style={style.spxnplus} src="/images/spxnplus/spxn_2_220_grey.png" alt="SPXN+" />
                              }
                              { isSpxnUnlockPort8 ?
                                <img style={style.spxnplus} src="/images/spxnplus/spxn_3_250.png" alt="SPXN+" /> :
                                <img style={style.spxnplus} src="/images/spxnplus/spxn_3_250_grey.png" alt="SPXN+" />
                              }
                              { isSpxnUnlockDry ?
                                <img style={style.spxnplus} src="/images/spxnplus/spxn_4_292.png" alt="SPXN+" /> :
                                <img style={style.spxnplus} src="/images/spxnplus/spxn_4_292_grey.png" alt="SPXN+" />
                              }
                              { isSpxnUnlockVdc ?
                                <img style={style.spxnplus} src="/images/spxnplus/spxn_5_122.png" alt="SPXN+" /> :
                                <img style={style.spxnplus} src="/images/spxnplus/spxn_5_122_grey.png" alt="SPXN+" />
                              }
                              { isSpxnUnlockRelay ?
                                <img style={style.spxnplus} src="/images/spxnplus/spxn_6_102.png" alt="SPXN+" /> :
                                <img style={style.spxnplus} src="/images/spxnplus/spxn_6_102_grey.png" alt="SPXN+" />
                              }
                              <img style={style.spxnplus} src="/images/spxnplus/spxn_7_219.png" alt="SPXN+" />
                            </div>
                          }
                        </Grid>
                      }
                      <Grid item xs={12}>
                        <ProductOptionsFields
                          productOptions={productData.options}
                          soldOptions={productData.sold_options}
                          optionsData={optionsData}
                          setOptionsData={setOptionsData}
                          updateOptionsData={updateOptionsData}
                          setUpdateOptionsData={setUpdateOptionsData}
                          errors={optionErrors}
                          disabled={isProcessing || isAdmin}
                          companyDiscount={calCost.companyDiscount}
                        />
                      </Grid>
                    </React.Fragment>
                  }
                  <Grid item xs={12}>
                    <StyledSectionHeader>
                      Reference
                    </StyledSectionHeader>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      multiline
                      rows={2}
                      maxRows={5}
                      type="text"
                      name="ref"
                      value={productRef}
                      onChange={(event) => setProductRef(event.target.value)}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      disabled={isProcessing || isAdmin}
                    />
                    {
                      !isAdmin &&
                      <Button
                        disabled={isProcessing}
                        variant="contained"
                        size="small"
                        sx={{ backgroundColor: 'green', mt: '15px' }}
                        onClick={() => saveProductRef(true)}
                        startIcon={
                          processingMode === 'productRef' ?
                          <CircularProgress color="info" size={20} thickness={5} />
                          :
                          undefined
                        }
                      >
                        Save Reference
                      </Button>
                    }
                  </Grid>
                  {
                    !isAdmin &&
                    <React.Fragment>
                      <Grid item xs={12} />
                      <Grid item xs={12}>
                        <StyledSectionHeader>
                          Summary
                        </StyledSectionHeader>
                      </Grid>
                      {
                        productData.status === 'idle' &&
                        <Grid item container xs={12} justifyContent="space-between">
                          <Box>
                            MSRP
                          </Box>
                          <Box>
                            {
                              isAdmin ?
                              <MoneyFormat value={calCost.msrp} />
                              :
                              <MoneyFormat currency={productData.currency} value={calCost.msrp * productData.exchange_rate} />
                            }
                          </Box>
                        </Grid>
                      }
                      <Grid item container xs={12} justifyContent="space-between">
                        <Box>
                          Additional Options
                        </Box>
                        <Box>
                          {
                            isAdmin ?
                            <MoneyFormat value={calCost.featurePrice} />
                            :
                            <MoneyFormat currency={productData.currency} value={calCost.featurePrice * productData.exchange_rate} />
                          }
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item container xs={12} justifyContent="space-between">
                        <Box>
                          Subtotal
                        </Box>
                        <Box>
                          {
                            isAdmin ?
                            <MoneyFormat value={calCost.totalPrice} />
                            :
                            <MoneyFormat currency={productData.currency} value={calCost.totalPrice * productData.exchange_rate} />
                          }
                        </Box>
                      </Grid>
                      <Grid item container xs={12} justifyContent="space-between">
                        <Box>
                          Discount
                        </Box>
                        <Box sx={{ color: '#d32f2f' }}>
                          {
                            isAdmin ?
                            <MoneyFormat value={calCost.discountTotal} />
                            :
                            <MoneyFormat currencyPrefix="- " currency={productData.currency} value={calCost.discountTotal * productData.exchange_rate} />
                          }
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid
                        item
                        container
                        xs={12}
                        justifyContent="space-between"
                        sx={
                          !batchSale ?
                          { color: '#0071bd', fontWeight: 'bold' }
                          :
                          { fontWeight: 'bold' }
                        }
                      >
                        <Box>
                          Total
                        </Box>
                        <Box>
                          {
                            isAdmin ?
                            <MoneyFormat value={calCost.grandTotal} />
                            :
                            <MoneyFormat currency={productData.currency} value={calCost.grandTotal * productData.exchange_rate} />
                          }
                        </Box>
                      </Grid>
                      {
                        !window.IS_ADMIN && !batchSale &&
                        <React.Fragment>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                          <Grid
                            item
                            container
                            xs={12}
                            justifyContent="space-between"
                            sx={{ color: '#0071bd', fontWeight: 'bold' }}
                          >
                            <Box>
                              Total Sales in USD ({productData.exchange_rate} {productData.currency} = 1 USD)
                            </Box>
                            <Box>
                              <MoneyFormat value={calCost.grandTotal} />
                            </Box>
                          </Grid>
                        </React.Fragment>
                      }
                    </React.Fragment>
                  }
                  {
                    !isAdmin && productData.status === 'idle' && !batchSale &&
                    <Grid item container xs={12} justifyContent="flex-end">
                      <Button
                        disabled={isProcessing}
                        variant="contained"
                        size="small"
                        sx={{ backgroundColor: 'green' }}
                        onClick={() => setBatchSale(true)}
                      >
                        Sell more
                      </Button>
                    </Grid>
                  }
                  {
                    batchSale &&
                    <StyledMainGrid item container xs={12} columnSpacing>
                      <Grid item xs={12} sm={4}>
                        Batch Sale
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <FormControl sx={{ mb: '20px', width: '100%' }} size="small">
                          <Select
                            value={batchSaleMode}
                            onChange={handleSellMoreOption}
                            disabled={isProcessing}
                          >
                            <MenuItem value="select">Select from Serial Number / MAC Address</MenuItem>
                            <MenuItem value="input">Manual input Serial Number / MAC Address</MenuItem>
                          </Select>
                        </FormControl>
                        {
                          batchSaleMode === 'input' ?
                          <React.Fragment>
                            <TextField
                              multiline
                              fullWidth
                              type="text"
                              minRows={8}
                              name="sellMoreText"
                              value={textBatchProducts}
                              onChange={handleSellMoreText}
                              label={'Multiple values separate by comma or newline'}
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                            />
                            {
                              manualInputAlert.alert &&
                              <Box style={{ marginTop: '20px' }}>
                                {manualInputAlert.showAlert()}
                              </Box>
                            }
                          </React.Fragment>
                          :
                          <React.Fragment>
                            <Box style={{ border: '1px solid grey', borderRadius: '4px'}}>
                              <TextField
                                name="batchSearch"
                                value={batchSearch}
                                disabled={isProcessing}
                                onChange={(e) => setBatchSearch(e.target.value)}
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                style={{ padding: '5px 10px 0px 10px' }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <SearchIcon />
                                    </InputAdornment>
                                  ),
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        onClick={() => setBatchSearch('')}
                                        edge="end"
                                        disabled={isProcessing}
                                      >
                                        <CloseIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  )
                                }}
                              />
                              <List sx={{ width: '100%', maxHeight: 300, minHeight: 200, overflowY: 'auto' }}>
                                {
                                  displayBatchProducts.length > 0 ?
                                  displayBatchProducts.map((item, index) => {
                                    let selected = (productData.id === item.id || selectedBatchProducts.filter(i => i.id === item.id).length > 0);
                                    return (
                                      <ListItem
                                        key={index}
                                        disablePadding
                                        disabled={productData.id === item.id || isProcessing}
                                        onClick={
                                          productData.id === item.id || isProcessing ?
                                          () => {}
                                          :
                                          () => handleSellMoreSelection([item], !selected)
                                        }
                                      >
                                        <ListItemButton dense>
                                          <ListItemIcon>
                                            <Checkbox
                                              name={item.id}
                                              checked={selected}
                                              disabled={productData.id === item.id || isProcessing}
                                              disableRipple
                                            />
                                           </ListItemIcon>
                                          <ListItemText primary={item.serial_key} />
                                        </ListItemButton>
                                      </ListItem>
                                    );
                                  })
                                  :
                                  <ListItem
                                    disablePadding
                                    disabled
                                  >
                                    <ListItemButton dense>
                                      <ListItemText primary="Not found" />
                                    </ListItemButton>
                                  </ListItem>
                                }
                              </List>
                            </Box>
                            <Box style={{ display: 'flex', justifyContent: 'space-between'}}>
                              <Box>
                                <Link
                                  component="button"
                                  underline="hover"
                                  variant="body2"
                                  style={{ cursor: 'pointer' }}
                                  onClick={
                                    isProcessing ?
                                    () => {}
                                    :
                                    () => handleSellMoreSelection(displayBatchProducts.filter(i => i.id !== productData.id), true)
                                  }
                                >
                                  Select All
                                </Link>
                              </Box>
                              <Box>
                                <Link
                                  component="button"
                                  underline="hover"
                                  variant="body2"
                                  style={{ cursor: 'pointer' }}
                                  onClick={
                                    isProcessing ?
                                    () => {}
                                    :
                                    () => handleSellMoreSelection(displayBatchProducts.filter(i => i.id !== productData.id), false)
                                  }
                                >
                                  Unselect All
                                </Link>
                              </Box>
                            </Box>
                          </React.Fragment>
                        }
                      </Grid>
                      <Grid item xs={12} />
                      <Grid item container xs={12} justifyContent="space-between">
                        <Box>
                          Quantity
                        </Box>
                        <Box>
                          {selectedBatchProducts.length + 1}
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item container xs={12} justifyContent="space-between" sx={{ color: '#0071bd', fontWeight: 'bold' }}>
                        <Box>
                          Grand Total
                        </Box>
                        <Box>
                          {
                            isAdmin ?
                            <MoneyFormat value={(selectedBatchProducts.length + 1) * calCost.grandTotal} />
                            :
                            <MoneyFormat currency={productData.currency} value={((selectedBatchProducts.length + 1) * calCost.grandTotal) * productData.exchange_rate} />
                          }
                        </Box>
                      </Grid>
                      {
                        !window.IS_ADMIN &&
                        <React.Fragment>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                          <Grid
                            item
                            container
                            xs={12}
                            justifyContent="space-between"
                            sx={{ color: '#0071bd', fontWeight: 'bold' }}
                          >
                            <Box>
                              Total Sales in USD ({productData.exchange_rate} {productData.currency} = 1 USD)
                            </Box>
                            <Box>
                              <MoneyFormat value={(selectedBatchProducts.length + 1) * calCost.grandTotal} />
                            </Box>
                          </Grid>
                        </React.Fragment>
                      }
                    </StyledMainGrid>
                  }
                  {
                    isAdmin &&
                    <React.Fragment>
                      <Grid item xs={12} />
                      <Grid item container xs={12} justifyContent={productData.product_image || productData.status === 'idle' ? 'space-between' : 'center'}>
                        <Button
                          variant="outlined"
                          size="medium"
                          onClick={onCancel}
                        >
                          Back
                        </Button>
                      </Grid>
                    </React.Fragment>
                  }
                  {
                    !isAdmin &&
                    <React.Fragment>
                      <Grid item xs={12} />
                      <Grid item container xs={12} justifyContent={productData.product_image || productData.status === 'idle' ? 'space-between' : 'center'}>
                        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, width: { xs: '100%', sm: 'auto' }, mb: { xs: '40px' }}}>
                          <ConfirmationButton
                            buttonProps={{
                              variant: 'contained',
                              size: 'medium',
                              sx: {
                                mr: { xs: 0, sm: '20px' },
                                mb: { xs: '10px', sm: 0 },
                                pl: '40px',
                                pr: '40px'
                              },
                              disabled: isProcessing || productData.status === 'reserve'
                            }}
                            handleDialogClickYes={handleMakeSale}
                            validate={validateButton}
                            buttonText={productData.status === 'reserve' ? 'Reserved' : 'Confirm Sale'}
                            dialogText={getConfirmationButtonText()}
                            dialogYesText="Confirm"
                            dialogNoText="Cancel"
                            loading={processingMode === 'sale'}
                            additionalButtons={[
                              {
                                buttonText: 'Cancel',
                                handleButton: onCancel,
                                buttonProps: {
                                  variant: 'outlined',
                                  size: 'medium',
                                  disabled: isProcessing
                                }
                              }
                            ]}
                          />
                        </Box>
                        {
                          productData.status === 'idle' &&
                          <Box sx={{ ml: { xs: 0, sm: '10px' }, width: { xs: '100%', sm: 'auto' }, mb: { xs: '10px' }}}>
                            <ConfirmationButton
                              buttonProps={{
                                variant: 'contained',
                                size: 'medium',
                                sx: {
                                  backgroundColor: '#e65100',
                                  width: { xs: '100%', sm: 'auto' },
                                  pl: '40px',
                                  pr: '40px'
                                },
                                disabled: isProcessing
                              }}
                              handleDialogClickYes={handleMakeReplacement}
                              loading={processingMode === 'replacement'}
                              validate={validateButton}
                              buttonText="Confirm Replacement"
                              dialogTitle={companyInfo.internal ? false : 'Information Required For Replacement Approval'}
                              dialogYesText="Confirm"
                              dialogNoText="Cancel"
                              dialogText={
                                companyInfo.internal ?
                                'Once confirmed AKCP will be notified to approve the replacement.'
                                :
                                <Grid container direction="row" rowSpacing="22px">
                                  {
                                    replacementDetails.map((detail, idx) => (
                                      <Grid item xs={12}>
                                        <Box>
                                          <Typography variant="subtitle2">
                                            {detail.label}
                                          </Typography>
                                        </Box>
                                        <Box style={{ marginTop: 15 }}>
                                          <TextField
                                            fullWidth
                                            required
                                            multiline
                                            rows={2}
                                            InputLabelProps={{
                                              shrink: true
                                            }}
                                            label="Answer"
                                            name={detail.name}
                                            value={detail.value}
                                            onChange={(event, val) => {
                                              let newDetails = [...replacementDetails];
                                              newDetails.splice(idx, 1, {...detail, value: event.target.value});
                                              setReplacementDetails(newDetails);
                                            }}
                                            error={showValidateReplacementFields && !detail.value}
                                          />
                                        </Box>
                                      </Grid>
                                    ))
                                  }
                                  <Grid item xs={12}>
                                    <Typography variant="subtitle2">
                                      * Once confirmed AKCP will be notified to approve the replacement.
                                    </Typography>
                                  </Grid>
                                </Grid>
                              }
                            />
                          </Box>
                        }
                      </Grid>
                    </React.Fragment>
                  }
                </StyledMainGrid>
              </React.Fragment>
            }
          </StyledMainGrid>
          {
            window.debugTest &&
            <Box
              style={{
                whiteSpace: 'pre-wrap',
                margin: '10px',
                padding: '10px',
                border: '1px solid #dddddd',
                position: 'fixed',
                right: '0',
                top: '150px',
                width: '30%',
                textAlign: 'right'
              }}>
              {calCost.debugText}
            </Box>
          }
        </StyledCardFormContent>
      </StyledCard>
    </Page>
  );
}
