import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import StyledMainGrid from '../components/StyledMainGrid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { ajaxGetCompanyManagers, ajaxSaveCompanyManager, ajaxDisableCompanyManager, ajaxEnableCompanyManager, ajaxDeleteCompanyManager } from '../services/companyService';
import validator from 'validator';
import { Link } from 'react-router-dom';
import Page from '../components/Page';
import StyledCard from '../components/StyledCard'
import StyledCardFormContent from '../components/StyledCardFormContent';
import ConfirmationButton from '../components/ConfirmationButton';

export default function Manager(props) {
  const incomingParams = useParams();
  const allFields = useMemo(
    () => [
      {
        key: 'first_name',
        type: 'text',
        label: 'First name',
      },
      {
        key: 'last_name',
        type: 'text',
        label: 'Last name',
      },
      {
        key: 'username',
        type: 'text',
        label: 'Username',
        autoComplete: 'username',
        minLength: 6
      },
      {
        key: 'password',
        type: 'password',
        label: 'Password',
        autoComplete: 'new-password',
        minLength: 8
      },
      {
        key: 'confirmPassword',
        type: 'password',
        label: 'Confirm Password',
        autoComplete: 'confirm-password',
        confirm: 'password'
      }
    ],
    []
  );

  const requiredFields = useMemo(() => {
    let retRequiredFields = ['first_name', 'last_name', 'username'];
    if (props.add) {
      retRequiredFields.push('password');
    }
    return retRequiredFields;
  }, [props.add]);

  const [manager, setManager] = useState({ });
  const [errors, setErrors] = useState({});
  const [submitOnce, setSubmitOnce] = useState(false);
  const navigate = useNavigate();
  const paramUserId = (incomingParams && incomingParams.UserId ? incomingParams.UserId : -1);

  useEffect(() => {
    if (!props.add) {
      if (paramUserId !== -1) {
        const params = {UserId: paramUserId};

        ajaxGetCompanyManagers(params)
        .then((res) => {
          const { data } = res;
          setManager(data);
        })
        .catch(() => {
          setManager({});
        });
      }
    }
  }, [paramUserId, props.add]);

  const validateFields = useCallback(() => {
    const fields = { ...manager };
    let newErros = {};
    let result = true;
    for (let index in allFields) {
      let key = allFields[index].key;
      let minLength = allFields[index].minLength;
      let confirm = allFields[index].confirm;
      let min = allFields[index].min;
      let max = allFields[index].max;
      let fieldValue = fields[key] !== undefined && fields[key] !== null ? fields[key] + '' : '';
      let fieldConfirmValue = confirm && fields[confirm] ? fields[confirm] + '' : '';
      if (requiredFields.includes(key) && validator.isEmpty(fieldValue, { ignore_whitespace: true })) {
        newErros[key] = 'required field';
        result = false;
      } else if (fieldValue && minLength > 0 && !validator.isLength(fieldValue, { min: minLength, max: undefined })) {
        newErros[key] = `This field must be at least ${minLength} characters in length`;
        result = false;
      } else if (confirm && fieldValue !== fieldConfirmValue) {
        newErros[key] = 'Please enter the same value again.';
        result = false;
      } else if (fieldValue && !isNaN(min) && !isNaN(max) && (isNaN(fieldValue) || parseFloat(fieldValue) < min || parseFloat(fieldValue) > max)) {
        newErros[key] = `Please enter a value between ${min} and ${max}.`;
        result = false;
      }
    }
    setErrors(newErros);
    return result;
  }, [manager, allFields, requiredFields]);

  useEffect(() => {
    if (!props.add) {
      validateFields();
    } else if (props.add && submitOnce) {
      validateFields();
    }
  }, [validateFields, props.add, submitOnce]);

  const handleSave = () => {
    setSubmitOnce(true);
    if (!validateFields()) return false;

    const params = { ...manager };
    let url = '/dashboard/managers';

    ajaxSaveCompanyManager(params)
      .then((res) => {
        navigate(url);
      })
      .catch(() => {
        navigate(url);
      });
  };

  const handleFieldChange = (event, val) => {
    let value = val !== undefined ? val : event.target.value,
      key = event.target.name;

    setManager({ ...manager, [key]: value });
  };

  const getFieldValue = (key) => {
    return manager && manager[key] !== undefined && manager[key] !== null ? manager[key] : '';
  };

  const getFieldPlaceholder = (key) => {
    return key === 'password' && !props.add ? 'leave blank to not change the password' : '';
  };

  const getFieldErrorText = (key) => {
    if (errors && errors[key]) return errors[key];
    else return '';
  };

  const checkFieldError = (key) => {
    if (errors && errors[key]) return true;
    else return false;
  };

  const handleManagerToggle = (isEnabled, id) => {
    let ajaxMethod = ajaxDisableCompanyManager;
    if (isEnabled) {
      ajaxMethod = ajaxEnableCompanyManager;
    }

    ajaxMethod({ id: id })
      .then((res) => {
        const { data } = res;
        setManager(data);
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  const handleManagerDelete = (id) => {
    const url = '/dashboard/managers';
    ajaxDeleteCompanyManager({ id: id })
      .then(() => {
        navigate(url);
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  const buttonStyle = {
    height: '40px',
    width: '150px',
    fontSize: '12px'
  };

  return (
    <Page title="Add / Edit Manager">
      <StyledCard>
        <StyledCardFormContent>
          <StyledMainGrid columnSpacing>
            {allFields.map((entry, index) => (
              <React.Fragment key={entry['key']}>
                {
                  (entry['type'] === 'text' || entry['type'] === 'password') &&
                  <Grid item xs={12} {...entry['gridProps']}>
                    <TextField
                      label={entry['label']}
                      type={entry['type']}
                      name={entry['key']}
                      value={getFieldValue(entry['key'])}
                      onChange={handleFieldChange}
                      error={checkFieldError(entry['key'])}
                      helperText={getFieldErrorText(entry['key'])}
                      placeholder={getFieldPlaceholder(entry['key'])}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>
                }
              </React.Fragment>
            ))}
            <Grid item xs={12}>
              {manager && manager['active'] > 0 && (
                <Button onClick={() => handleManagerToggle(false, manager['id'])} variant="contained" size="normal" style={buttonStyle} sx={{ mr: '20px', backgroundColor: 'red' }}>
                  Disable manager
                </Button>
              )}
              {manager && manager['active'] < 1 && (
                <Button onClick={() => handleManagerToggle(true, manager['id'])} variant="contained" size="normal" style={buttonStyle} sx={{ mr: '20px' }}>
                  Enable manager
                </Button>
              )}
              {manager && manager['deleted'] === 0 && (
                <ConfirmationButton
                  buttonProps={{
                    variant: 'contained',
                    size: 'medium',
                    sx: { mr: '20px', backgroundColor: 'red' },
                    style: buttonStyle
                  }}
                  handleDialogClickYes={() => handleManagerDelete(manager['id'])}
                  buttonText="Delete manager"
                  dialogText="Are you sure you want to delete this manager?"
                  dialogYesText="Confirm"
                  dialogNoText="Cancel"
                />
              )}
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={12}>
              <Button variant="contained" size="normal" style={buttonStyle} sx={{ backgroundColor: 'green', mr: '20px' }} onClick={handleSave}>
                Save Manager
              </Button>
              <Button variant="outlined" size="normal" style={buttonStyle} component={Link} to="/dashboard/managers">
                Cancel
              </Button>
            </Grid>
          </StyledMainGrid>
        </StyledCardFormContent>
      </StyledCard>
    </Page>
  );
}
