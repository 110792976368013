import React, { useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { MoneyFormat } from '../components/MoneyFormat';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import { ajaxGetAdminOptions } from '../services/adminService';
import Page from '../components/Page';
import StyledCard from '../components/StyledCard'
import StyledList from '../components/StyledList';
import StyledCardSearch from '../components/StyledCardSearch';
import StyledCardFormContent from '../components/StyledCardFormContent';

export default function OptionList() {
  const [items, setItems] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [searchResults, setSearchResults] = React.useState([]);
  const navigate = useNavigate();

  const renderItem = (data, idx, secondarySx) => {
    return (
      <ListItem
        key={idx}
        disablePadding
        secondaryAction={
          <React.Fragment>
            <MoneyFormat prefix="US$ " value={data.msrp} />
            <IconButton
              component={RouterLink}
              to={'/dashboard/options/edit/' + data.id}
            >
              <EditOutlinedIcon />
            </IconButton>
          </React.Fragment>
        }
      >
        <ListItemButton component={RouterLink} to={'/dashboard/options/edit/' + data.id}>
          <ListItemText
            primary={
              <Box sx={{ display: { xs: 'block', sm: 'flex' } }}>
                <Box sx={{ mr: '10px' }}>{data.label}</Box>
                <Chip label={`API Code: ${data.code}`} size="small" variant="outlined" />
              </Box>
            }
          />
        </ListItemButton>
      </ListItem>
    );
  };

  const searchChangeHandle = (event) => {
    const new_value = event.target.value.toLowerCase();
    setSearchTerm(new_value);
  };

  useEffect(() => {
    const params = {};

    ajaxGetAdminOptions(params)
      .then((res) => {
        const { data } = res;
        setItems(data);
      })
      .catch(() => {
        setItems([]);
      });
  }, []);

  useEffect(() => {
    const results = items.filter((item) => `${(item.label || '')}${(item.code || '')}`.toLowerCase().includes(searchTerm));

    setSearchResults(results);
  }, [searchTerm, /*searchFilter,*/ items]);

  return (
    <Page title="Add / Edit Option">
      <StyledCard>
        <StyledCardSearch onSearch={searchChangeHandle} />
        <StyledCardFormContent>
          <StyledList
            addRow
            onAdd={() => navigate('/dashboard/options/add')}
            dataSource={searchResults}
            renderItem={renderItem}
            sort={{
              sortKey: 'code',
              sortOptions: [
                {
                  key: 'code',
                  label: 'API Code'
                },
                {
                  key: 'label',
                  label: 'Label'
                }
              ]
            }}/>
        </StyledCardFormContent>
      </StyledCard>
    </Page>
  );
}
