import React from 'react';
import Grid from '@mui/material/Grid';
import StyledMainGrid from '../../components/StyledMainGrid';
import Invoice from './Invoice';
import AdminSale from './AdminSale';
import SummarySales from './SummarySales';
import OutboundShipments from './OutboundShipments';
import AdminReplacements from './AdminReplacements';
import Page from '../../components/Page';

export default function AdminDashboard() {
  return (
    <Page title="Overview">
      <StyledMainGrid columnSpacing alignItems="flex-start">
        <Grid item container sm={12} md={6} rowSpacing="22px">
          <Grid item xs={12}>
            <SummarySales companyId={-1} />
          </Grid>
          <Grid item xs={12}>
            <AdminSale />
          </Grid>
        </Grid>
        <Grid item container sm={12} md={6} rowSpacing="22px">
          <Grid item xs={12}>
            <Invoice companyId={0} companyName="All" />
          </Grid>
          <Grid item xs={12}>
            <OutboundShipments />
          </Grid>
          <Grid item xs={12}>
            <AdminReplacements />
          </Grid>
        </Grid>
      </StyledMainGrid>
    </Page>
  );
}
